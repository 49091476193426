import {formatDateNo} from 'localization/core/formatting/formatDateNo';
import {MassUpdate, Schemas_MassUpdateDto} from '../types';
import {mapMassUpdateActionDto} from './mapMassUpdateActionDto';
import {mapMassUpdateTypeDto} from './mapMassUpdateTypeDto';

export const mapMassUpdateDto = (dto: Schemas_MassUpdateDto): MassUpdate => {
  return {
    id: dto.id,
    status: dto.status,
    items: dto.items,
    massUpdateField: dto.field.field,
    type: mapMassUpdateTypeDto(dto.field.type),
    ...mapMassUpdateActionDto(dto.action),
    created: formatDateTime(dto.created),
    completed: dto.completed ? formatDateTime(dto.completed) : undefined,
  };
};

const formatDateTime = (timestamp: number): string => {
  return formatDateNo(timestamp, 'short', true);
};
