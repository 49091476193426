import {DataLoadStatus, WorkMetadata, getWork, useGetResource} from 'api';
import {useGetTokens} from 'services/auth';

export const useWorkMetadata = (
  workId: string | undefined,
): DataLoadStatus<WorkMetadata> => {
  const mock = false;
  const getTokens = useGetTokens();

  return useGetResource(() =>
    workId ? getWork(workId, getTokens, mock) : Promise.reject(),
  );
};
