import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {AdvancedSearchItemsState} from '../types';
import {setAdvancedSearchItems} from '../actions';

export const useSetAdvancedSearchItems = () => {
  const dispatch = useDispatch();
  return useCallback(
    (advancedSearchItems: AdvancedSearchItemsState) => {
      dispatch(setAdvancedSearchItems(advancedSearchItems));
    },
    [dispatch],
  );
};
