import {MassUpdateEntityType} from 'types';
import {useLocalization} from 'localization';

export const useMassUpdateTitle = (
  type: MassUpdateEntityType,
  count: number,
) => {
  const {t} = useLocalization();
  return t(`page.massUpdate.${type}.title`, {count});
};
