import {useMemo} from 'react';
import {assert} from 'assert-ts';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import {Concept, DerivedConcept} from 'types';
import {AdornmentResolver, PreviewConfiguration} from 'schema/types';
import {useDefaultAdornementResolver} from 'schema/hooks';
import {workSummaryPreviewConfig} from 'schemas/configurations/preview/workSummaryPreviewConfig';
import {SchemaReference} from '../types';
import {
  agentPreviewConfig,
  collectionPreviewConfig,
  defaultPreviewConfig,
  manifestationPreviewConfig,
  massUpdatePreviewConfig,
  workPreviewConfig,
} from '../configurations/preview';

const mergeConfigs = (
  entityConfig: PreviewConfiguration,
  overrides?: Partial<PreviewConfiguration>,
  adornmentResolver?: AdornmentResolver,
): PreviewConfiguration => {
  const {partValueRenderers, ...rest} = entityConfig;
  const config = overrides
    ? {...merge(cloneDeep(rest), overrides), partValueRenderers}
    : entityConfig;
  return {
    ...config,
    adornmentResolver: config.adornmentResolver ?? adornmentResolver,
  };
};

export const usePreviewConfiguration = (
  schemaReference: SchemaReference,
  overrides?: Partial<PreviewConfiguration>,
): PreviewConfiguration => {
  const adornmentResolver = useDefaultAdornementResolver();
  return useMemo(() => {
    switch (schemaReference) {
      case Concept.expression:
        return mergeConfigs(defaultPreviewConfig, overrides, adornmentResolver);
      case Concept.manifestation:
        return mergeConfigs(
          manifestationPreviewConfig,
          overrides,
          adornmentResolver,
        );
      case Concept.work:
        return mergeConfigs(workPreviewConfig, overrides, adornmentResolver);
      case DerivedConcept.workSummary:
        return mergeConfigs(
          workSummaryPreviewConfig,
          overrides,
          adornmentResolver,
        );
      case Concept.massUpdate:
        return mergeConfigs(
          massUpdatePreviewConfig,
          overrides,
          adornmentResolver,
        );

      default: {
        const {entity, subType} = schemaReference;
        switch (entity) {
          case Concept.agent: {
            switch (subType) {
              case Concept.person:
              case Concept.publisher:
              case Concept.corporation:
              case Concept.event:
                return mergeConfigs(
                  agentPreviewConfig,
                  overrides,
                  adornmentResolver,
                );
              default:
                assert(
                  false,
                  `useDefaultColumns: Unknown subtype: ${subType} of ${entity}`,
                );
            }
            break;
          }
          case Concept.collection: {
            switch (subType) {
              case Concept.series:
              case Concept.educationalSeries:
              case Concept.otherConnection:
              case Concept.publisherSeries:
                return mergeConfigs(
                  collectionPreviewConfig,
                  overrides,
                  adornmentResolver,
                );
              default:
                assert(
                  false,
                  `useDefaultColumns: Unknown subtype: ${subType} of ${entity}`,
                );
            }
            break;
          }
          default: {
            assert.soft(
              false,
              `usePreviewConfiguration: Unknown entity type: ${schemaReference}`,
            );
            return mergeConfigs(
              defaultPreviewConfig,
              overrides,
              adornmentResolver,
            );
          }
        }
      }
    }
  }, [adornmentResolver, schemaReference, overrides]);
};
