/**
 * States:
 * - Closed:
 * - Open:
 *   - Actions:
 *     - enter search string => state Searching
 *     - change selected main/sub-entity => state Searching
 *     - close => state Closed
 *   - Substates
 *     - View entity:
 *       - Entity preview
 *       - Actions:
 *         - link/change link (if valid type) -> set entity link => state Closed
 *         - remove link (if current source) -> remove entity link => same state
 *         - edit (if available) -> open edit entity dialog
 *           save/cancel -> close => same state
 *         (- open in separate tab (if available) => same state)
 *     - Searching:
 *       - Search field empty/non-empty
 *       - Main entity w/sub-entity selected
 *       - Show search results: selected main/sub-entity
 *         - Paginator - prev/next
 *         - Add new entity
 *       - Actions:
 *         - change search string -> update search result
 *         - change selected main/sub-entity -> update search result
 *         - goto prev/next page
 *         - add new entity (if available) => state View entity
 *         - click entity in search result => state Browse result
 *     - Browse result
 *       - Result browser header: back, prev, next
 *       - Entity preview of current item
 *       - Actions:
 *         - link/change link: ditto
 *         - remove link: ditto
 *         - edit: ditto
 *           save/cancel: ditto
 *         - back => state Searching
 *         - prev -> view prev item
 *         - next -> view next item
 *
 */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {assert} from 'assert-ts';
import {EntitySubType, LinkAction, LinkTargetMainEntityType} from 'types';
import {LoadStatus} from 'api/types';
import {PaginationState} from 'components/paginator/types';
import {SetSourceValue} from 'sceneExtensions/types';
import {LinkedValueLink, VerifiedLinkedValueLink} from 'schemaDefinition/types';
import {getMainAndSubEntityType, last} from 'services/utils';
import {
  EntityValue,
  LinkedValueSearchFieldConfig,
  SearchMainEntityType,
  SearchResultType,
  SearchResultValue,
} from '../types';
import {CoreSearchState, EntityIdOnly, EntitySearchState} from './types';
import {usePaginationState} from '../../pagination';
import {getDefaultSubEntity} from '../functions';
import {getEntityValueId} from '../functions/getEntityValueId';
import {EMPTY_RESULT, useEntitySearch} from '../hooks';
import {getCurrentEntity, makeInitialSearchState} from './functions';
import {updateSearchStateOnNewSource} from './functions/updateSearchStateOnNewSource';

const PAGE_SIZE = 20;

export type EntitySearchContextType = {
  /** Linked value of current source field, i.e. the one clicked by user */
  currentSourceValue: LinkedValueLink | undefined;
  /** Configuration of current source field, i.e. valid main/sub-types */
  currentSourceConfig: LinkedValueSearchFieldConfig | undefined;
  /** Set/reset linked value of current source field */
  setSourceValue: SetSourceValue<VerifiedLinkedValueLink, LinkAction>;

  state: EntitySearchState;

  /** Current search query: entered by user or from unverified entity (agent) name */
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  /** Selected type of entity to search for, i.e. work, agent subtypes or collection subtypes  */
  searchEntityType: SearchResultType;
  /** Current pagination state */
  pagination: PaginationState;

  selectedMainEntity?: SearchMainEntityType;
  setSelectedMainEntity: (type: SearchMainEntityType | undefined) => void;
  selectedSubEntity?: EntitySubType;
  setSelectedSubEntity: (type: EntitySubType | undefined) => void;

  /** True when updating search result */
  loading: boolean;
  highlights: string[];
  searchStatus: LoadStatus;
  searchResult: SearchResultValue[];

  /**
   * Set verified link in field or clear field
   */
  // setEntityLink: (value: Entity | undefined) => void;

  /**
   * Set entity to be previewed, i.e. after saving new entity or changes to existing entity.
   */
  setViewEntity: (value: EntityIdOnly) => void;
  /**
   * Either:
   *  state viewEntity: entity from verified link or newly created entity
   *  state browse: currently selected entity in search result
   *  state navigate: last entity navigated to
   *  otherwise: undefined
   */
  currentEntity?: EntityIdOnly;
  currentEntityType?: SearchResultType;
  onNewEntitySaved: (savedEntity: EntityValue) => void;
  /**
   * Index of current entity in search result
   */
  currentIndex: number;
  /**
   * Total count of search result
   */
  totalCount: number;
  /**
   * Go to entity in search result, if it exists
   * Sets state to browse and currentEntityIdx to index of entity in search result
   */
  gotoEntity?: (entity: EntityIdOnly) => void;

  canGotoPrevEntity: boolean;
  /**
   * State browse: goto previous entity, should only be called if canGotoPrevEntity is truye
   */
  gotoPrevEntity: () => void;

  canGotoNextEntity: boolean;
  /**
   * State browse: goto next entity, should only be called if canGotoNextEntity is truye
   */
  gotoNextEntity: () => void;
  /**
   * Go back to search result, setting state to search
   * @returns
   */
  gotoSearchResult: () => void;

  navigateTo: (
    link: VerifiedLinkedValueLink,
    from: string,
    siblingIds?: (string | number)[],
    siblingLabel?: string,
  ) => void;

  /**
   * Number of siblings in current navigation item, that user may navigate between using prev/next buttons
   */
  siblingCount?: number;
  /**
   * Index of current entity (sibling) in list siblings
   */
  siblingIndex?: number;
  siblingLabel?: string;

  canNavigateToPrevSibling: boolean;
  /**
   * When last navigation item contains a list sibling ids, navigate to prev id in list
   */
  navigateToPrevSibling: () => void;

  canNavigateToNextSibling: boolean;
  /**
   * When last navigation item contains a list sibling ids, navigate to next id in list
   */
  navigateToNextSibling: () => void;
  navigateBack: () => void;
  /**
   * Title of previous entity navigated from
   */
  prevEntityTitle?: string;
};

const canNavigateToPrevSibling = (state: CoreSearchState): boolean => {
  if (state.state !== 'navigate') {
    return false;
  }

  const lastItem = last(state.navigationHistory);
  return !!(
    lastItem?.siblingIds &&
    state.viewEntity.id &&
    lastItem.siblingIds.indexOf(state.viewEntity.id) > 0
  );
};

const canNavigateToNextSibling = (state: CoreSearchState): boolean => {
  if (state.state !== 'navigate') {
    return false;
  }

  const lastItem = last(state.navigationHistory);
  return !!(
    lastItem?.siblingIds &&
    state.viewEntity.id &&
    lastItem.siblingIds.indexOf(state.viewEntity.id) <
      lastItem.siblingIds.length - 1
  );
};
export const EntitySearchContext = createContext<
  EntitySearchContextType | undefined
>(undefined);

export const useEntitySearchContextProviderValue = (
  fieldId: string | undefined,
  currentSourceValue: LinkedValueLink | undefined,
  currentSourceConfig: LinkedValueSearchFieldConfig | undefined,
  onSetSourceValue: SetSourceValue<VerifiedLinkedValueLink, LinkAction>,
): EntitySearchContextType => {
  const [coreState, setCoreState] = useState<CoreSearchState>(
    makeInitialSearchState(currentSourceValue, currentSourceConfig),
  );

  const pagination = usePaginationState(20);
  const {
    status: searchStatus,
    page: searchPage,
    data: searchResult = EMPTY_RESULT,
  } = useEntitySearch(
    coreState.searchQuery,
    coreState.selectedMainEntity,
    coreState.selectedSubEntity,
    pagination,
  );

  const handleSetSearchQuery = useCallback((query: string) => {
    // console.log('.setCoreState', 1, `"${query}"`);
    setCoreState(prev => ({
      ...prev,
      state: EntitySearchState.search,
      searchQuery: query,
    }));
  }, []);

  const handleSetSelectedMainEntity = useCallback(
    (type: SearchMainEntityType | undefined) => {
      // console.log('.setCoreState', 2);
      setCoreState(prev => {
        const mainType = type ?? prev.selectedMainEntity;
        return {
          ...prev,
          state: EntitySearchState.search,
          selectedMainEntity: mainType,
          selectedSubEntity: getDefaultSubEntity(
            mainType,
            currentSourceConfig,
            prev.selectedSubEntity,
          ),
          currentEntityIdx: undefined,
        };
      });
    },
    [currentSourceConfig],
  );

  const handleSetSelectedSubEntity = useCallback(
    (type: EntitySubType | undefined) => {
      // console.log('.setCoreState', 3);
      setCoreState(prev => ({
        ...prev,
        state: EntitySearchState.search,
        selectedSubEntity:
          type ??
          getDefaultSubEntity(
            prev.selectedMainEntity,
            currentSourceConfig,
            prev.selectedSubEntity,
          ),
        currentEntityIdx: undefined,
      }));
    },
    [currentSourceConfig],
  );

  const handleSetViewEntity = useCallback((entity: EntityIdOnly) => {
    // console.log('.setCoreState', 4);
    setCoreState(prev => {
      return prev.state === EntitySearchState.search
        ? {
            ...prev,
            state: EntitySearchState.viewEntity,
            viewEntity: entity,
          }
        : {
            ...prev,
            viewEntity: entity,
          };
    });
  }, []);

  const handleNewEntitySaved = useCallback((savedEntity: EntityValue) => {
    // Preview new entity
    setCoreState(prev => {
      const {mainType, subType} = getMainAndSubEntityType(savedEntity);

      const savedEntityId = getEntityValueId(savedEntity);

      return {
        ...prev,
        state: EntitySearchState.viewEntity,
        viewEntity: {
          id: savedEntityId,
        },
        selectedMainEntity: mainType as LinkTargetMainEntityType,
        selectedSubEntity: subType,
      };
    });
  }, []);

  const gotoSearchResult = useCallback(() => {
    // console.log('.setCoreState', 5);
    setCoreState(prev => ({
      ...prev,
      state: EntitySearchState.search,
    }));
  }, []);

  /**
   * Go to entity in search result, if it exists
   * Sets state to browse and currentEntityIdx to index of entity in search result
   */
  const gotoEntity = useCallback(
    (entity: EntityIdOnly) => {
      const index =
        (searchPage - 1) * PAGE_SIZE +
        searchResult.hits.findIndex(a => a.id === entity.id);
      if (
        assert.soft(
          index >= 0,
          'handleGotoEntity: entityId not in searchResult',
        )
      ) {
        // console.log('.setCoreState', 6);
        setCoreState(prev => ({
          ...prev,
          state: EntitySearchState.browse,
          currentEntityIdx: index,
        }));
      }
    },
    [searchPage, searchResult.hits],
  );

  /**
   * State browse: goto previous entity, should only be called if canGotoPrevEntity is true
   */
  const gotoPrevEntity = useCallback(() => {
    if (
      !assert.soft(
        coreState.state === 'browse',
        'gotoPrevEntity: state !== browse',
      )
    ) {
      return;
    }

    const isFirstInPage = (coreState.currentEntityIdx ?? 0) % PAGE_SIZE === 0;
    if (isFirstInPage && (coreState.currentEntityIdx ?? 0) > 0) {
      pagination.onSelectPrevious();
    }

    setCoreState(prev => {
      if (prev.state !== 'browse') {
        return prev;
      }

      const newIdx =
        assert.soft(
          prev.currentEntityIdx,
          'gotoPrevEntity: currentEntityId expected in state browse',
        ) &&
        assert.soft(
          prev.currentEntityIdx > 0,
          'gotoPrevEntity: currentEntityId > 0',
        )
          ? prev.currentEntityIdx - 1
          : prev.currentEntityIdx;

      return {
        ...prev,
        state: EntitySearchState.browse,
        currentEntityIdx: newIdx,
      };
    });
  }, [coreState.currentEntityIdx, coreState.state, pagination]);

  /**
   * State browse: goto next entity, should only be called if canGotoNextEntity is true
   */
  const gotoNextEntity = useCallback(() => {
    if (
      !assert.soft(
        coreState.state === 'browse',
        'gotoNextEntity: state !== browse',
      )
    ) {
      return;
    }

    const isLastInPage =
      (coreState.currentEntityIdx ?? 0) % PAGE_SIZE === PAGE_SIZE - 1;
    if (
      isLastInPage &&
      (coreState.currentEntityIdx ?? 0) < searchResult.total - 1
    ) {
      pagination.onSelectNext();
    }

    setCoreState(prev => {
      if (prev.state !== 'browse') {
        return prev;
      }

      const newIdx =
        assert.soft(
          prev.currentEntityIdx,
          'gotoNextEntity: currentEntityIdx expected in state browse',
        ) &&
        assert.soft(
          prev.currentEntityIdx < searchResult.total - 1,
          'gotoNextEntity: currentEntityId > 0',
        )
          ? prev.currentEntityIdx + 1
          : searchResult.total - 1;

      return {
        ...prev,
        state: EntitySearchState.browse,
        currentEntityIdx: newIdx,
      };
    });
  }, [
    coreState.currentEntityIdx,
    coreState.state,
    pagination,
    searchResult.total,
  ]);

  const navigateTo = useCallback(
    (
      link: VerifiedLinkedValueLink,
      from: string,
      siblingIds?: (string | number)[],
      siblingLabel?: string,
    ) => {
      setCoreState(prev => {
        const {state: prevState, ...prevRest} = prev;

        if (
          !assert.soft(
            [
              EntitySearchState.browse,
              EntitySearchState.viewEntity,
              EntitySearchState.navigate,
            ].includes(prevState),
            'handleNavigateTo: state !== browse/viewEntity/navigate',
          )
        ) {
          return prev;
        }

        const searchEntityType: SearchResultType = (prev.selectedSubEntity ??
          prev.selectedMainEntity ??
          'work') as SearchResultType;

        const {entity, type} = getCurrentEntity(
          prevState,
          prev.viewEntity,
          prev.navigationEntityType,
          prev.currentEntityIdx,
          searchPage,
          PAGE_SIZE,
          searchResult,
          currentSourceValue,
          searchEntityType,
        );
        const newHistory = prev.navigationHistory
          ? [...prev.navigationHistory]
          : [];
        newHistory.push({
          title: from,
          entityId: assert(entity?.id, 'navigateTo: id expected', prev),
          entityType: assert(type, 'navigateTo: type expected', prev),
          state: prevState,
          siblingIds,
          siblingLabel,
        });

        const newState: CoreSearchState = {
          ...prevRest,
          state: EntitySearchState.navigate,
          viewEntity: {id: link.entityId},
          navigationHistory: newHistory,
          navigationEntityType: link.type,
        };

        return newState;
      });
    },
    [currentSourceValue, searchPage, searchResult],
  );

  /**
   * Navigate to prev id in navigation history
   */
  const navigateToPrevSibling = useCallback(() => {
    setCoreState(prev => {
      const {navigationHistory} = prev;

      if (
        !assert.soft(
          canNavigateToPrevSibling(prev),
          'navigateToPrevSibling: cannot navigate to prev',
          prev,
        )
      ) {
        return prev;
      }

      const lastItem = last(navigationHistory);

      if (
        !(
          assert.soft(
            lastItem,
            'navigateToPrevSibling: history item expected',
          ) &&
          assert.soft(
            lastItem.siblingIds,
            'navigateToPrevSibling: entityIdList expected',
          ) &&
          assert.soft(
            lastItem.entityId !== last(lastItem.siblingIds),
            'navigateToPrevSibling: cannot navigate beyond last item',
          )
        )
      ) {
        return prev;
      }

      const currentIdx = lastItem.siblingIds.indexOf(prev.viewEntity?.id ?? '');
      if (
        !assert.soft(currentIdx > 0, 'navigateToPrevSibling: currentIdx > 0')
      ) {
        return prev;
      }

      const prevId = lastItem.siblingIds[currentIdx - 1];

      const newState: CoreSearchState = {
        ...prev,
        viewEntity: {id: prevId},
      };

      return newState;
    });
  }, []);

  /**
   * Navigate to next id in navigation history
   */
  const navigateToNextSibling = useCallback(() => {
    setCoreState(prev => {
      const {navigationHistory} = prev;

      if (
        !assert.soft(
          canNavigateToNextSibling(prev),
          'navigateToNextSibling: cannot navigate to next',
          prev,
        )
      ) {
        return prev;
      }

      const lastItem = last(navigationHistory);

      if (
        !(
          assert.soft(
            lastItem,
            'navigateToNextSibling: history item expected',
          ) &&
          assert.soft(
            lastItem.siblingIds,
            'navigateToNextSibling: entityIdList expected',
          ) &&
          assert.soft(
            lastItem.entityId !== last(lastItem.siblingIds),
            'navigateToNextSibling: cannot navigate beyond last item',
          )
        )
      ) {
        return prev;
      }

      const currentIdx = lastItem.siblingIds.indexOf(prev.viewEntity?.id ?? '');
      if (
        !assert.soft(currentIdx >= 0, 'navigateToNextSibling: currentIdx >= 0')
      ) {
        return prev;
      }

      const nextId = lastItem.siblingIds[currentIdx + 1];

      const newState: CoreSearchState = {
        ...prev,
        viewEntity: {id: nextId},
      };

      return newState;
    });
  }, []);

  const navigateBack = useCallback(() => {
    if (
      !assert.soft(
        ['navigate'].includes(coreState.state),
        'handleNavigateBack: state !== navigate',
      )
    ) {
      return;
    }

    setCoreState(prev => {
      const {state: prevState, ...prevRest} = prev;

      if (
        ![EntitySearchState.navigate].includes(prevState) ||
        !prev.navigationHistory?.length
      ) {
        return prev as CoreSearchState;
      }

      const newHistory = [...prev.navigationHistory];
      const prevItem = assert(
        newHistory.pop(),
        'handleNavigateBack: item expected',
      );

      const newState = {
        ...prevRest,
        state: prevItem.state,
        viewEntity: {id: prevItem?.entityId},
        navigationHistory: newHistory,
        navigationEntityType: prevItem.entityType,
      } as CoreSearchState;

      return newState;
    });
  }, [coreState.state]);

  const fieldIdRef = useRef(fieldId);
  // When different linked field is selected in work/expression/manifestation
  // => update search state:
  useEffect(() => {
    if (fieldIdRef.current === fieldId) {
      return;
    }

    fieldIdRef.current = fieldId;

    setCoreState(prev =>
      updateSearchStateOnNewSource(
        currentSourceValue,
        currentSourceConfig,
        prev,
      ),
    );
  }, [fieldId, currentSourceValue, currentSourceConfig]);

  return useMemo(() => {
    const {
      state,
      searchQuery,
      selectedMainEntity: selectedMainEntityType,
      selectedSubEntity: selectedSubEntityType,
      currentEntityIdx,
      viewEntity,
      navigationEntityType: toEntityType,
    } = coreState;
    const canGotoPrevEntity =
      currentEntityIdx !== undefined && currentEntityIdx > 0;
    const canGotoNextEntity =
      currentEntityIdx !== undefined &&
      currentEntityIdx < searchResult.total - 1;
    const searchEntityType: SearchResultType = (selectedSubEntityType ??
      selectedMainEntityType ??
      'work') as SearchResultType;

    const {entity, type} = getCurrentEntity(
      state,
      viewEntity,
      toEntityType,
      currentEntityIdx,
      searchPage,
      PAGE_SIZE,
      searchResult,
      currentSourceValue,
      searchEntityType,
    );

    const lastNavigationItem = last(coreState.navigationHistory);
    const lastEntityIdList = lastNavigationItem?.siblingIds;

    return {
      currentSourceValue,
      currentSourceConfig,
      setSourceValue: onSetSourceValue,
      state,
      loading: searchStatus === 'Loading',
      searchStatus,
      searchEntityType,
      searchQuery,
      setSearchQuery: handleSetSearchQuery,
      pagination,
      selectedMainEntity: selectedMainEntityType,
      setSelectedMainEntity: handleSetSelectedMainEntity,
      selectedSubEntity: selectedSubEntityType,
      setSelectedSubEntity: handleSetSelectedSubEntity,
      highlights: searchResult.highlights,
      searchResult: searchResult.hits,
      // setEntityLink: handleSetEntityLink,
      setViewEntity: handleSetViewEntity,
      onNewEntitySaved: handleNewEntitySaved,
      currentEntity: entity,
      currentEntityType: type,
      currentIndex: currentEntityIdx ?? 0,
      totalCount: searchResult.total,
      gotoEntity,
      canGotoPrevEntity,
      canGotoNextEntity,
      gotoPrevEntity,
      gotoNextEntity,
      gotoSearchResult,

      navigateTo,
      siblingCount: lastEntityIdList?.length,
      siblingIndex: lastEntityIdList?.indexOf(
        viewEntity?.id as string | number,
      ),
      siblingLabel: lastNavigationItem?.siblingLabel,
      canNavigateToPrevSibling: canNavigateToPrevSibling(coreState),
      navigateToPrevSibling,
      canNavigateToNextSibling: canNavigateToNextSibling(coreState),
      navigateToNextSibling,
      navigateBack,
      prevEntityTitle: coreState.navigationHistory
        ? coreState.navigationHistory[coreState.navigationHistory.length - 1]
            ?.title
        : undefined,
    };
  }, [
    coreState,
    searchResult,
    searchPage,
    currentSourceValue,
    currentSourceConfig,
    onSetSourceValue,
    searchStatus,
    handleSetSearchQuery,
    pagination,
    handleSetSelectedMainEntity,
    handleSetSelectedSubEntity,
    handleSetViewEntity,
    handleNewEntitySaved,
    gotoEntity,
    gotoPrevEntity,
    gotoNextEntity,
    gotoSearchResult,
    navigateTo,
    navigateToPrevSibling,
    navigateToNextSibling,
    navigateBack,
  ]);
};

export const useEntitySearchContext = (): EntitySearchContextType => {
  return assert(
    useContext(EntitySearchContext),
    'EntitySearchContext: context expected',
  );
};
