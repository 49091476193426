import {CollectionSubType} from '../types';
import {Collection, GetTokens} from './types';
import seriesQuery from './data/seriesQuery.json';
import {Schemas} from './dto.generated';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpElastic, httpElasticV2} from './http/search/httpElastic';
import {mapSearchCollectionResult} from './mappers';
import {mapGetCollectionDto} from './mappers/mapCollectionDto';
import {mapToCollectionTypeDto} from './mappers/mapCollectionTypeDto';
import {mapHighlight} from './mappers/mapHighlights';
import {mapSeriesDocumentToSeriesWithRelationsDto} from './mappers/mapSeriesDocumentToSeriesWithRelationsDto';
import {
  SearchCollectionDto,
  SearchResult,
  SearchResultDto,
} from './searchTypes';

const makeSearchPart = (search: string): object => {
  return {
    must: [
      {
        multi_match: {
          query: search,
          operator: 'and',
          fields: ['titles.value^3', '*'],
        },
      },
    ],
  };
};
const makeFilterPart = (
  collectionTypes: SearchCollectionDto['type'][],
): object => {
  if (collectionTypes.length === 0) {
    return {};
  }

  if (collectionTypes.length === 1) {
    return {
      filter: {
        term: {'type.keyword': collectionTypes[0]},
      },
    };
  }

  return {
    filter: {
      bool: {
        should: collectionTypes.map(collectionType => ({
          term: {
            'type.keyword': collectionType,
          },
        })),
      },
    },
  };
};

export const searchSeries2 = (
  query: string,
  seriesType: CollectionSubType,
  page: number,
  size: number,
  getTokens: GetTokens,
  abortSignal?: AbortSignal,
  mock?: boolean,
): Promise<SearchResult<Collection>> => {
  const type = mapToCollectionTypeDto(seriesType);

  /**
   * Placed the JSON in a file to easier debug it in elastic cloud console.
   */
  const elasticQuery = JSON.stringify(seriesQuery)
    .replaceAll('${QUERY_PLACEHOLDER}', query)
    .replaceAll('${SERIES_TYPE_PLACEHOLDER}', type);

  return exceptionToPromiseReject(() =>
    httpElasticV2<SearchResultDto<Schemas.SeriesDocument>>(
      getTokens,
      'ebs-series-alias',
      JSON.parse(elasticQuery),
      page,
      size,
      abortSignal,
      mock,
    ).then(mapCollectionHits),
  );
};

export const mapCollectionHits = (
  dto: SearchResultDto<Schemas.SeriesDocument>,
): SearchResult<Collection> => {
  return {
    // Mapping from ElasticDto to ApiDto. Then using the ApiDto mapping to out Collection dto (same as when calling agent/{id}).
    hits: dto?.hits.hits.map(h =>
      mapGetCollectionDto(mapSeriesDocumentToSeriesWithRelationsDto(h._source)),
    ),
    highlights: dto?.hits.hits.map(h => mapHighlight(h.highlight)).flat(1),
    total: dto.hits.total.value,
  };
};

export const searchSeries = (
  search: string,
  seriesTypes: CollectionSubType[],
  page: number,
  size: number,
  getTokens: GetTokens,
  abortSignal?: AbortSignal,
  mock?: boolean,
): Promise<SearchResult<Collection>> => {
  const apiCollectionTypes = seriesTypes.map(mapToCollectionTypeDto);

  return exceptionToPromiseReject(() =>
    httpElastic<SearchResultDto<SearchCollectionDto>>(
      getTokens,
      '_search',
      'collection',
      {
        bool: {
          ...makeSearchPart(search),
          ...makeFilterPart(apiCollectionTypes),
        },
      },
      page,
      size,
      10,
      ['*'],
      abortSignal,
      mock,
    ).then(mapSearchCollectionResult),
  );
};
