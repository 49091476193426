import {createContext, useContext, useMemo} from 'react';
import {SxProps, useMediaQuery} from '@mui/material';
import {Theme, useTheme} from '@mui/system';
import {assert} from 'assert-ts';
import {useWindowSize} from 'usehooks-ts';
import {ColorPalette, useOuterSx} from 'theme';
import {HeaderHeight, UXUnit} from 'theme/settings';
import {RightPanelConfig, RightPanelFullWidthType} from '../types';
import {useLayoutTheme, useRightPanelFullWidthToggle} from '../hooks';

type SceneSizeContextType = {
  outerSx: SxProps<Theme>;
  sceneContentSize: number;
  sceneContentSx: SxProps<Theme>;
  sceneContentLayout: Theme;
  rightPanelSx: SxProps<Theme>;
  hasRightPanel: boolean;
  rightPanelContainerSx: SxProps<Theme>;
  sceneRightPanelLayout: Theme;
} & RightPanelFullWidthType;

export const SceneSizeContext = createContext<SceneSizeContextType | undefined>(
  undefined,
);

export const useSceneSizeContextProvider = (
  hasRightPanel: boolean,
  rightPanelConfig?: RightPanelConfig,
): SceneSizeContextType => {
  const {fullWidth, toggleFullWidth} = useRightPanelFullWidthToggle(
    rightPanelConfig?.initialFullWidth,
    rightPanelConfig?.canToggleFullWidth,
  );

  const headerHeight = HeaderHeight;

  const theme = useTheme();
  const outerSx = useOuterSx(theme);
  const aboveSm = useMediaQuery(theme.breakpoints.up('sm'));
  const aboveLg = useMediaQuery(theme.breakpoints.up('lg'));
  const sceneContentSize = hasRightPanel
    ? fullWidth
      ? 0
      : aboveSm
        ? aboveLg
          ? 7
          : 5
        : 0
    : 12;

  const {width: windowWidth} = useWindowSize();

  const {rightPanelSx, rightPanelContainerSx, sceneContentSx} = useMemo(() => {
    const rightPanelWidth = (12 - sceneContentSize) * (windowWidth / 12);
    const spacing = 3;

    const sceneContentSx = {
      display: {
        xs: hasRightPanel ? 'none' : 'block',
        sm: 'block',
      },
    };

    const rightPanelContainerSx = {
      p: theme.spacing(2.5),
      width: {
        xs: '100vw',
        sm: rightPanelWidth - UXUnit * spacing,
      },
    };

    return {
      rightPanelContainerSx,
      rightPanelSx: {
        '& .MuiDrawer-paper': {
          top: `${headerHeight}px`,
          height: `calc(100% - ${headerHeight}px)`,
          background:
            rightPanelConfig?.backgroundColour ?? ColorPalette.warmGreen,
        },
      },
      sceneContentSx,
    };
  }, [
    sceneContentSize,
    windowWidth,
    hasRightPanel,
    theme,
    headerHeight,
    rightPanelConfig?.backgroundColour,
  ]);

  const sceneContentLayout = useLayoutTheme(sceneContentSize);
  const sceneRightPanelLayout = useLayoutTheme(12 - sceneContentSize);

  return {
    outerSx,
    sceneContentSize,
    sceneContentSx,
    sceneContentLayout,
    rightPanelSx,
    hasRightPanel,
    rightPanelContainerSx,
    sceneRightPanelLayout,
    fullWidth,
    toggleFullWidth,
  };
};
export const useSceneSizeContext = (): SceneSizeContextType => {
  return assert(
    useContext(SceneSizeContext),
    'SceneSizeContext: context expected',
  );
};
