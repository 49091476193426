import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {NoProps} from 'types';
import {Configuration, isFeatureEnabled} from 'configuration';
import {MassUpdateNewScene} from 'scenes/massUpdateNew';
import {TestScene} from 'scenes/test';
import {
  AdvancedSearchScene,
  AgentEditScene,
  AgentsScene,
  DemoScene,
  DemoSearchScene,
  EanToWorkRedirect,
  Login,
  LoginCallback,
  Logout,
  LogoutCallback,
  MassUpdateDetailsScene,
  MassUpdateListScene,
  OverviewScene,
  PreviewScene,
  ReceiveScene,
  SearchMetadataScene,
  StatisticsScene,
  UpdateMetadataScene,
} from '../scenes';
import App from './App';

type Props = NoProps;

export const AppRoutes: React.FC<Props> = () => {
  return (
    <Routes>
      <Route path="/logout" element={<Logout />} />
      <Route path="/logout/callback" element={<LogoutCallback />} />
      <Route path="/login" element={<Login />} />
      <Route path="/login/callback" element={<LoginCallback />} />
      <Route path="/" element={<App />}>
        <Route index element={<Navigate to={'overview'} />} />
        <Route path="overview" element={<OverviewScene />} />
        {isFeatureEnabled('advancedSearch') ? (
          <Route path="search" element={<AdvancedSearchScene />} />
        ) : null}
        <Route path="statistics" element={<StatisticsScene />} />
        <Route path="metadata" element={<SearchMetadataScene />} />
        <Route path="metadata/:workId" element={<UpdateMetadataScene />} />
        <Route path="metadata/ean/:ean" element={<EanToWorkRedirect />} />
        <Route path="preview/:manifestationId" element={<PreviewScene />} />
        <Route path="agent" element={<AgentsScene />} />
        <Route path="agent/:agentId" element={<AgentEditScene />} />
        <Route path="receive" element={<ReceiveScene />} />
        {isFeatureEnabled('massUpdate') ? (
          <Route path="massUpdate" element={<MassUpdateListScene />} />
        ) : null}
        {isFeatureEnabled('massUpdate') ? (
          <Route
            path="massUpdate/details/:massUpdateId"
            element={<MassUpdateDetailsScene />}
          />
        ) : null}
        {isFeatureEnabled('massUpdate') ? (
          <Route path="massUpdate/new" element={<MassUpdateNewScene />} />
        ) : null}
        {Configuration.env === 'local' || Configuration.env === 'dev' ? (
          <>
            <Route path="demo" element={<DemoScene />} />
            <Route path="demosearch" element={<DemoSearchScene />} />
          </>
        ) : null}
      </Route>
      {Configuration.env === 'local' ? (
        <Route path="test" element={<TestScene />} />
      ) : null}
    </Routes>
  );
};
