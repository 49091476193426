import {useMemo} from 'react';
import {assert} from 'assert-ts';
import {Collection} from 'api/types';
import {isVerifiedLinkedCollectionLink} from 'schemaDefinition';
import {assertCollectionValue} from 'services/utils';
import {Icon, IconButton, Layout, Spacer, Text} from 'components';
import {LinkedType} from '../types';
import {EntityPreviewHeader} from './types';
import {getEntityValueId} from '../functions/getEntityValueId';
import {LinkCatalogPostButtons} from './LinkCatalogPostButtons';

export const CollectionPreviewHeader: EntityPreviewHeader = ({
  entityValue,
  linkingConfiguration,
  canLink,
  onEdit,
}) => {
  const collection = assertCollectionValue(entityValue);
  const entityValueId = getEntityValueId(entityValue);
  const {title, languages, linked, handleEdit} = useMemo(() => {
    const currentValue = linkingConfiguration?.currentValue;
    const handleEdit = (collection: Collection) =>
      assert(onEdit, 'CollectionPreviewHeader: onEdit is required')(collection);

    return {
      title: collection.titles[0]?.value ?? '',
      languages: collection.titles.map(title => title.language),
      linked: (isVerifiedLinkedCollectionLink(currentValue) &&
      currentValue.entityId === entityValueId
        ? 'verified'
        : 'none') as LinkedType,
      handleEdit: () => Promise.resolve(handleEdit(collection)),
    };
  }, [linkingConfiguration?.currentValue, entityValueId, collection, onEdit]);

  return (
    <Layout
      paddingLeft={linked ? 1 : 2}
      paddingRight={1}
      alignItems="center"
      justifyContent="space-between"
      horizontal
      flex={1}>
      <Layout horizontal adjustCenter>
        {linked === 'none' ? null : (
          <>
            <Icon icon={'CheckCircle'} />
            <Spacer width={1} />
          </>
        )}
        <Text variant="h2">{title}</Text>
      </Layout>
      <Layout horizontal adjustCenter>
        <IconButton icon="Edit" onClick={handleEdit} />

        {linkingConfiguration && canLink ? (
          <>
            <Spacer size={1} />
            <LinkCatalogPostButtons
              title={title}
              languages={languages}
              linked={linked}
              linkingConfiguration={linkingConfiguration}
            />
          </>
        ) : null}
      </Layout>
    </Layout>
  );
};
