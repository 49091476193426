import {Part, Schema} from 'schemaDefinition/types';
import {visitSchemaParts} from './visitSchemaParts';

export const findSchemaPart = (
  partName: string,
  schema: Schema,
): Part | undefined => {
  let foundPart: Part | undefined = undefined;

  const visitor = (part: Part) => {
    if (part.name === partName) {
      foundPart = part;
    }
  };

  visitSchemaParts(schema, visitor);

  return foundPart;
};
