import {EntityValue} from '../types';
import {isWorkMetadataValue} from '../../../services/utils';

export const getEntityValueId = (
  entityValue: EntityValue,
): string | undefined => {
  return isWorkMetadataValue(entityValue)
    ? entityValue.work.id
    : entityValue.id;
};
