import {
  ThesaurusNodeDetailsCodeValue,
  ThesaurusNodeDetailsValue,
} from 'api/types';

export const isCodeValue = (
  value: ThesaurusNodeDetailsValue,
): value is ThesaurusNodeDetailsCodeValue => {
  const textValue = value as ThesaurusNodeDetailsCodeValue;
  return textValue.type === 'code';
};
