import React, {useEffect, useRef, useState} from 'react';
import {Suggestion, ToolInput} from 'components';

type Props = {
  value: string;
  placeholder?: string;
  /**
   * Initial/changes to non-nullish values will set focus on input field
   */
  triggerFocus?: unknown;
  onChange: (value: string) => void;
  enableSuggestions?: boolean;
  suggestions?: Suggestion[];
  onSubmit?: () => void;
};

export const SearchInput: React.FC<Props> = ({
  value,
  placeholder,
  triggerFocus: currentSourceConfig,
  onChange,
  enableSuggestions,
  suggestions,
  onSubmit,
}) => {
  const [localSearchText, setLocalSearchText] = useState(value);
  const localSearchTextRef = useRef(localSearchText);

  const delayedSearchTimerRef = useRef<
    ReturnType<typeof setTimeout> | undefined
  >();

  // When search text changes
  // => delayed update
  useEffect(() => {
    if (localSearchText === localSearchTextRef.current) {
      return;
    }

    if (delayedSearchTimerRef.current) {
      clearTimeout(delayedSearchTimerRef.current);
    }

    localSearchTextRef.current = localSearchText;
    delayedSearchTimerRef.current = setTimeout(() => {
      onChange(localSearchTextRef.current);
      delayedSearchTimerRef.current = undefined;
    }, 1000);

    return () => {
      if (delayedSearchTimerRef.current) {
        clearTimeout(delayedSearchTimerRef.current);
      }
    };
  }, [localSearchText, onChange]);

  return (
    <ToolInput
      value={localSearchText}
      placeholder={placeholder}
      triggerFocus={currentSourceConfig}
      enableClear
      onChange={setLocalSearchText}
      enableSuggestions={enableSuggestions}
      suggestions={suggestions}
      onSubmit={onSubmit}
    />
  );
};
