import {PartValueRenderer} from 'schema/types';
import {OtherTitle} from 'api';
import {isNullish} from 'schemaDefinition/functions';
import {useCodelist} from 'services/codeLists';
import {FlexBox, TextMatch} from 'components';

export const OtherTitles: PartValueRenderer = ({value, match}) => {
  const codelist = useCodelist('WORK_TITLE_TYPE');

  return isNullish(value) ||
    !Array.isArray(value) ||
    value.length === 0 ? null : (
    <FlexBox>
      {value.map((v, i) => {
        const otherTitle = v as OtherTitle;
        const typeValue = codelist.codes.find(
          c => c.code === otherTitle.type,
        )?.value;
        return (
          <TextMatch key={i} variant="body1" match={match}>
            {typeValue ? `${typeValue}: ${otherTitle.value}` : otherTitle.value}
          </TextMatch>
        );
      })}
    </FlexBox>
  );
};
