import {useCallback} from 'react';
import {useLocalization} from 'localization';
import {DataLoadStatus, ThesaurusNode, useGetResourceListByIds} from 'api';
import {getGrepNodeDetails} from 'api/thesaurus';
import {useGetTokens} from 'services/auth';

export const useGrepNodeDetailsList = (
  codes: string[],
): DataLoadStatus<ThesaurusNode[]> => {
  const getTokens = useGetTokens();
  const {locale} = useLocalization();
  const getResource = useCallback(
    (codes: string[] | undefined) =>
      codes
        ? Promise.all(
            codes.map(code =>
              getGrepNodeDetails(code, locale ?? 'nb', getTokens),
            ),
          )
        : Promise.reject(),
    [getTokens, locale],
  );

  return useGetResourceListByIds<ThesaurusNode>(codes, getResource);
};
