import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import {Concept} from 'types';
import {LinkedAgentMultiRole} from 'schemaDefinition/types';
import {
  CodeListMap,
  ExpressionV4,
  WorkMetadata,
  WorkSummary,
  WorkV4,
} from 'api';
import {ManifestationItem} from '../types';
import {getMetadataOrder} from './getMetadataOrder';
import {sortThemaCodes} from './sortThemaCodes';

export const mapWorkMetadataToWorkSummary = (
  metadata: WorkMetadata,
  codelists: CodeListMap,
): WorkSummary => {
  const order = getMetadataOrder(
    {...metadata, changeRequests: []},
    [],
    codelists,
  );

  const findManifestaion = (id: string) =>
    metadata.manifestations.find(m => m.id === id);

  const allManifestationItems = order.flatMap(o =>
    o.content.filter(c => c.type === Concept.manifestation),
  ) as ManifestationItem[];
  const manifestationItemWithTitle = allManifestationItems.find(
    item => (findManifestaion(item.id)?.mainTitles?.length ?? 0) > 0,
  );
  const mainTitles = manifestationItemWithTitle
    ? findManifestaion(manifestationItemWithTitle.id)?.mainTitles
    : undefined;

  const agents = mergeAgentsById(metadata.work, metadata.expressions);

  return {
    ...metadata.work,
    agents,
    mainTitles: mainTitles ?? null,
    themaCodes: sortThemaCodes(metadata.work.themes),
    themaLabels: sortThemaCodes(metadata.work.themes),
  };
};

const mergeAgentsById = (
  work: WorkV4,
  expressions: ExpressionV4[],
): LinkedAgentMultiRole[] | undefined => {
  const all = [
    ...(work.agents ?? []),
    ...expressions.map(e => e.agents ?? []).flat(),
  ];
  const groupedById = groupBy(all, a => a.link.entityId);
  const mergedOnId = Object.keys(groupedById).map<LinkedAgentMultiRole>(
    key => ({
      ...groupedById[key][0],
      roles: uniq(groupedById[key].flatMap(summary => summary.roles)),
    }),
  );

  return mergedOnId;
};
