import {createContext, useContext, useMemo} from 'react';
import {assert} from 'assert-ts';
import {Schema} from 'schemaDefinition/types';
import {getPartsFlattended} from 'schemaDefinition/functions';
import {TableConfiguration} from '../types';
import {defaultTableConfig} from '../configuration/defaultTableConfig';

type TableConfigurationContextType = TableConfiguration;

const defaultContext: TableConfigurationContextType = defaultTableConfig;

export const TableConfigurationContext =
  createContext<TableConfigurationContextType>(defaultContext);

export const useTableConfigurationContextProviderValue = (
  schema: Schema,
  configuration?: Partial<TableConfigurationContextType>,
): TableConfigurationContextType => {
  return useMemo(() => {
    const columns =
      configuration?.defaultColumns ?? defaultContext.defaultColumns ?? [];

    getPartsFlattended(schema.parts).forEach(p => {
      const exist = columns.find(c => c.name === p.name);
      if (!exist) {
        columns.push({
          name: p.name as string,
          width: 150,
          visible: true,
        });
      }
    });

    return {
      ...defaultContext,
      ...configuration,
      defaultColumns: columns,
    };
  }, [configuration, schema.parts]);
};

export const useTableConfigurationContext =
  (): TableConfigurationContextType => {
    return assert(
      useContext(TableConfigurationContext),
      'useTableConfigurationContext: context expected',
    );
  };
