import {Concept} from 'types';
import {valueReducerFactory} from 'services/utils/redux/valueReducerFactory';
import {MassUpdateItemsState} from '../types';

const [
  MASS_UPDATE_ITEMS_KEY,
  massUpdateItemsReducer,
  setMassUpdateItems,
  clearMassUpdateItems,
  useMassUpdateItems,
] = valueReducerFactory(`massUpdateItems`, {
  type: Concept.work,
  itemIds: [
    //  TODO: Remove these
    //'142e795d-b0e3-4751-9231-82dab382b069',
    //'06fc5ee6-a9d4-4308-8274-f933e90a4473',
  ],
} as MassUpdateItemsState);

export type MassUpdateItemsAction =
  | ReturnType<typeof setMassUpdateItems>
  | ReturnType<typeof clearMassUpdateItems>;

export {
  MASS_UPDATE_ITEMS_KEY,
  massUpdateItemsReducer,
  setMassUpdateItems,
  clearMassUpdateItems,
  useMassUpdateItems,
};
