import {useCallback, useMemo, useState} from 'react';
import {assert} from 'assert-ts';
import {MassUpdateEntityType} from 'types';
import {MassUpdateCreate} from 'api/types';
import {Schema} from 'schemaDefinition/types';
import {useCodelistsLazy} from 'services/codeLists';
import {useSchemaCodelistIds} from 'schema';
import {validate} from 'schema/form/functions/validators/validate';
import {useMassUpdateCreateSchema} from 'schemas/schemas/hooks';
import {STEP_IDS, StepId} from '../types';

/**
 * Edit new mass update
 *   - Steps:
 *     - Step 1: Choose field and operation type (add, remove, replace), edit value(s) to add, remove or replace
 *     - Step 2: Review and submit
 */

export type NewMassUpdateWizardProps = {
  massUpdateType: MassUpdateEntityType;
  itemIds: string[];
  onCancel: () => void;
  onSubmit: (
    massUpdateState: MassUpdateCreate,
    itemIds: string[],
  ) => Promise<void>;
};

type NewMassUpdateWizardState = {
  stepIds: StepId[];
  currentStep: StepId;
  massUpdateType: MassUpdateEntityType;
  massUpdateState: Partial<MassUpdateCreate>;
  massUpdateSchema: Schema;
  onMassUpdateStateEdited: (value: Partial<MassUpdateCreate>) => void;
  showValidationErrors: boolean;
  gotoStep: (stepId: StepId) => void;
  onSave: () => Promise<void>;
};

export const useNewMassUpdateWizardState = ({
  massUpdateType,
  itemIds,
  onSubmit,
}: NewMassUpdateWizardProps): NewMassUpdateWizardState => {
  const [currentStep, setCurrentStep] = useState<StepId>('STEP_1_EnterValues');

  const [newMassUpdateState, setNewMassUpdateState] = useState<
    Partial<MassUpdateCreate>
  >({
    type: massUpdateType,
  });
  const [showValidationErrors, setShowValidationErrors] = useState(false);

  const massUpdateDataSchema = useMassUpdateCreateSchema(newMassUpdateState);

  const codelistIds = useSchemaCodelistIds(massUpdateDataSchema);
  const codelistMap = useCodelistsLazy(codelistIds);

  const handleMassUpdateStateEdited = useCallback(
    (value: Partial<MassUpdateCreate>) => {
      setNewMassUpdateState(prev =>
        // Reset values if field to update changes
        value.massUpdateField !== prev.massUpdateField
          ? {
              ...prev,
              ...value,
              currentValue: undefined,
              newValue: undefined,
            }
          : {
              ...prev,
              ...value,
            },
      );
    },
    [],
  );

  const handleGotoStep = useCallback(
    (stepId: StepId) => {
      if (stepId === currentStep) {
        return;
      }

      switch (stepId) {
        case 'STEP_1_EnterValues':
          setCurrentStep('STEP_1_EnterValues');
          break;
        case 'STEP_2_Confirm':
          setCurrentStep('STEP_2_Confirm');
          break;
      }
    },
    [currentStep],
  );

  const handleSubmitMassUpdate = useCallback((): Promise<void> => {
    const validation = validate(
      newMassUpdateState,
      {},
      massUpdateDataSchema,
      assert(codelistMap, 'useNewMassUpdateWizard: codelistMap is required'),
    );

    if (validation.valid !== 'valid') {
      setShowValidationErrors(true);
      return Promise.resolve();
    }

    return onSubmit(newMassUpdateState as MassUpdateCreate, itemIds);
  }, [
    newMassUpdateState,
    massUpdateDataSchema,
    codelistMap,
    onSubmit,
    itemIds,
  ]);

  return useMemo(() => {
    const state: NewMassUpdateWizardState = {
      stepIds: STEP_IDS,
      currentStep,
      massUpdateType: massUpdateType,
      massUpdateState: newMassUpdateState,
      massUpdateSchema: massUpdateDataSchema,
      onMassUpdateStateEdited: handleMassUpdateStateEdited,
      showValidationErrors,
      gotoStep: handleGotoStep,
      onSave: handleSubmitMassUpdate,
    };

    return state;
  }, [
    currentStep,
    massUpdateType,
    newMassUpdateState,
    massUpdateDataSchema,
    handleMassUpdateStateEdited,
    showValidationErrors,
    handleGotoStep,
    handleSubmitMassUpdate,
  ]);
};
