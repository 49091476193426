import {DataLoadStatus, WorkMetadata, getWorks, useGetResource} from 'api';
import {useGetTokens} from 'services/auth';

export const useWorks = (
  workIds: Array<string>,
): DataLoadStatus<WorkMetadata[]> => {
  const mock = false;
  const getTokens = useGetTokens();

  return useGetResource(() =>
    workIds.length ? getWorks(workIds, getTokens, mock) : Promise.reject(),
  );
};
