import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {WorkItemsState} from '../types';
import {setWorkItems} from '../actions';

export const useSetWorkItems = () => {
  const dispatch = useDispatch();
  return useCallback(
    (workItems: WorkItemsState) => {
      dispatch(setWorkItems(workItems));
    },
    [dispatch],
  );
};
