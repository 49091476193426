import {assert} from 'assert-ts';
import {WorkMetadata} from 'api/types';
import {isWorkMetadataValue} from './isWorkMetadataValue';

export const assertWorkMetadataValue = (value: unknown): WorkMetadata => {
  assert(
    isWorkMetadataValue(value),
    'assertWorkMetadataValue: invalid work metadata',
    {value},
  );

  return value as WorkMetadata;
};
