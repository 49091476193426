import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import assert from 'assert-ts';
import {Concept, MassUpdateEntityType} from 'types';
import {Data} from 'schemaDefinition/types';
import {setMassUpdateItems} from '../index';

/**
 * @todo VERY WIP. Hacked solution for work within a manifestation response! Require workId column to be set, else all are ignored.
 */
export const useCreateMassUpdate = (
  type: MassUpdateEntityType,
  searchResult: Data[],
) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (type !== Concept.work) {
    assert('Not implemented, only work');
  }

  return useCallback(() => {
    const missingWorkIds: string[] = [];
    const workIds: string[] = searchResult
      .map(r => {
        if (Array.isArray(r.workId) && r.workId[0]) {
          return r.workId[0];
        } else {
          missingWorkIds.push(r.id as string);
        }
      })
      .filter(f => typeof f === 'string') as string[];

    if (missingWorkIds.length) {
      console.log('missingWorkIds', missingWorkIds);
    }

    if (workIds.length) {
      dispatch(
        setMassUpdateItems({
          type: type,
          itemIds: workIds,
        }),
      );

      navigate(`/massUpdate/new`);
    }
  }, [dispatch, navigate, searchResult, type]);
};
