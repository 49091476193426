import {assert} from 'assert-ts';
import {Data, Part} from 'schemaDefinition/types';
import {ValidationArgs, ValidationResult} from './types';
import {getValue} from './getValue';
import {validResult} from './validResult';
import {validateCodeList} from './validateCodeList';
import {validateDate} from './validateDate';
import {validateInt} from './validateInt';
import {validateLinkedAgent} from './validateLinkedAgent';
import {validateLinkedLiterary} from './validateLinkedLiterary';
import {validateNameVariant} from './validateNameVariant';
import {validateText} from './validateText';
import {validateThesaurus} from './validateThesaurus';
import {validateYear} from './validateYear';
import {validateYearOrDate} from './validateYearOrDate';

/**
 * validates data[part.name] according to "value" part
 */
export const validateValuePart = (
  part: Part,
  args: ValidationArgs<Data>,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  const {value, valuePath} = getValue(args.value, part, args.valuePath);

  const result = aggregateResult;

  const partArgs: ValidationArgs = {
    ...args,
    value,
    valuePath,
  };

  switch (part.type) {
    case 'text':
    case 'textarea': {
      return validateText(part, partArgs, result);
    }
    case 'html':
      return aggregateResult;
    case 'int': {
      return validateInt(part, partArgs, result);
    }
    case 'bool': {
      return result;
    }
    case 'year': {
      return validateYear(part, partArgs, result);
    }
    case 'date': {
      return validateDate(part, partArgs, result);
    }
    case 'yearOrDate': {
      return validateYearOrDate(part, partArgs, result);
    }
    case 'codelist': {
      return validateCodeList(part, partArgs, result);
    }
    case 'linkedAgent': {
      return validateLinkedAgent(part, partArgs, result);
    }
    case 'linkedLiterary': {
      return validateLinkedLiterary(
        part,
        partArgs,

        result,
      );
    }
    case 'nameVariant': {
      return validateNameVariant(
        part,
        partArgs,

        result,
      );
    }
    case 'thesaurus': {
      return validateThesaurus(
        part,
        partArgs,

        result,
      );
    }

    default: {
      assert(false, 'Validate: Not implemented yet: ' + part.type);
      return result;
    }
  }
};
