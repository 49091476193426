import {useMemo} from 'react';
import {massUpdateStaticSchema} from '../massUpdateSchemas';
import {
  MassUpdateValuesProps,
  useMassUpdateValuesSchema,
} from './useMassUpdateValuesSchema';

export const useMassUpdateSchema = (props: MassUpdateValuesProps) => {
  const valuesSchema = useMassUpdateValuesSchema(props);
  return useMemo(
    () => ({
      name: 'massUpdate',
      parts: [...massUpdateStaticSchema.parts, ...valuesSchema.parts],
    }),
    [valuesSchema],
  );
};
