import React, {useEffect, useRef, useState} from 'react';
import {Box, Collapse, FormControlLabel, Switch} from '@mui/material';
import {Concept} from 'types';
import {TableSearchResultValue} from 'feature/linkedValueSearch/types';
import {HTTP_STATUS_ABORTED, HttpError, WorkHit} from 'api';
import {useGetTokens} from 'services/auth';
import {SearchInput} from 'components';
import {Table} from 'schema';
import {useSchema} from 'schemas';
import {useSchemaReference} from 'schemas/hooks/useSchemaReference';
import {usePaginationState} from 'feature';
import {trimSearchQuery} from 'feature/linkedValueSearch/functions';
import {useTablePresentation} from 'feature/linkedValueSearch/hooks';
import {searchWorkConfig} from '../function/searchConfig';

type Props = {
  name: string;
  config: object;
};

export const ExampleSearch: React.FC<Props> = ({name, config}) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const handleChange = () => {
    setCollapsed(prev => !prev);
  };
  const searchEntityType = Concept.work;
  const subtype = undefined;

  const pagination = usePaginationState(20);

  const [result, setResult] = useState<WorkHit[]>();
  const searchQueryRef = useRef<string | undefined>();
  const pageRef = useRef<number | undefined>();
  const sizeRef = useRef<number | undefined>();
  const abortControllerRef = useRef<AbortController | undefined>();

  const {page, size, resetPage} = pagination;

  const getTokens = useGetTokens();

  const schemaRef = useSchemaReference(searchEntityType);
  const schema = useSchema(schemaRef);

  const {tableSchema, tableConfiguration, tableSearchResult} =
    useTablePresentation(searchEntityType, result ?? [], schema);

  useEffect(() => {
    if (
      searchQuery === searchQueryRef.current &&
      page === pageRef.current &&
      size === sizeRef.current
    ) {
      return;
    }

    // Abort any ongoing call
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = undefined;
    }

    if (!searchQuery) {
      setResult(undefined);
      return;
    }

    abortControllerRef.current = new AbortController();
    searchQueryRef.current = searchQuery;
    pageRef.current = page;
    sizeRef.current = size;

    const trimmedQuery = trimSearchQuery(searchQuery);
    searchWorkConfig(
      config,
      trimmedQuery,
      page,
      size,
      getTokens,
      abortControllerRef.current.signal,
    )
      .then(data => {
        setResult(data.hits);
        resetPage(data.total, 1);
      })
      .catch(error => {
        if (
          error instanceof HttpError &&
          error.status === HTTP_STATUS_ABORTED
        ) {
          // Caused by abort above, leave state as is - to be updated by next call
          // console.log('useAgentSearch: search aborted');
          return;
        }

        console.error('DemoSearch: search failed', error);
        setResult(undefined);
      });
  }, [subtype, getTokens, searchQuery, page, size, resetPage, config]);

  return (
    <Box sx={{ml: 1, mr: 1}}>
      <h2>{name}</h2>
      <FormControlLabel
        control={<Switch checked={collapsed} onChange={handleChange} />}
        label="Vis config"
      />
      <Collapse in={collapsed}>
        <Box sx={{backgroundColor: 'white'}}>
          <pre>
            {JSON.stringify(
              {
                query: config,
              },
              null,
              2,
            )}
          </pre>
        </Box>
      </Collapse>

      <Box sx={{mt: 1, mb: 1}}>
        <SearchInput
          value={searchQuery}
          onChange={value => setSearchQuery(value)}
        />
      </Box>

      <Table<TableSearchResultValue>
        schema={tableSchema}
        pagination={pagination}
        data={tableSearchResult}
        configuration={tableConfiguration}
        header={name}
      />
    </Box>
  );
};
