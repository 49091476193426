import React, {Fragment, useMemo} from 'react';
import {ThesaurusNode} from 'api/types';
import {Card, FlexBox, Spacer, Text} from 'components';
import {
  groupNodeDetailsItems,
  isCodeValue,
  isDateValue,
  isTextValue,
} from '../functions';
import {useGrepNodeDetails} from '../hooks';
import {GrepDetailsCodeValue} from './GrepDetailsCodeValue';
import {GrepDetailsDateValue} from './GrepDetailsDateValue';
import {GrepDetailsTextValue} from './GrepDetailsTextValue';
import {ThesaurusNodeDetailsRelated} from './ThesaurusNodeDetailsRelated';

type Props = {
  node: ThesaurusNode;
};

export const GrepDetailsBody: React.FC<Props> = ({node}) => {
  const extended = useGrepNodeDetails(node.code);

  const groupedDetails = useMemo(
    () => groupNodeDetailsItems(extended.data?.details),
    [extended.data?.details],
  );

  if (extended.status === 'NotLoaded' || extended.status === 'Loading') {
    return null;
  }

  if (extended.status === 'Failed') {
    return (
      <FlexBox>
        <Text variant="body2" kind="error">
          {'Failed to load details'}
        </Text>
      </FlexBox>
    );
  }

  return groupedDetails ? (
    <FlexBox>
      {groupedDetails.map((details, idx) => {
        const keys = Object.keys(details ?? {});
        return (
          <Fragment key={idx}>
            <Card>
              <FlexBox>
                {keys.map((key, itemIdx) => {
                  const itemValue = details[key];
                  return (
                    <FlexBox key={key}>
                      {isTextValue(itemValue) ? (
                        <GrepDetailsTextValue itemKey={key} value={itemValue} />
                      ) : isDateValue(itemValue) ? (
                        <GrepDetailsDateValue itemKey={key} value={itemValue} />
                      ) : isCodeValue(itemValue) ? (
                        <GrepDetailsCodeValue itemKey={key} value={itemValue} />
                      ) : (
                        <ThesaurusNodeDetailsRelated
                          label={key}
                          related={itemValue}
                        />
                      )}
                      {itemIdx < keys.length - 1 ? <Spacer size={1} /> : null}
                    </FlexBox>
                  );
                })}
              </FlexBox>
            </Card>
            {idx < groupedDetails.length - 1 ? <Spacer size={2} /> : null}
          </Fragment>
        );
      })}
    </FlexBox>
  ) : null;
};
