import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {RightPanelFullWidthType} from '../types';

export const useRightPanelFullWidthToggle = (
  initialFullWidth?: boolean,
  canToggle?: boolean,
): RightPanelFullWidthType => {
  const initialFullWidthRef = useRef<boolean | undefined>(initialFullWidth);
  const [fullWidth, setFullWidth] = useState<boolean | undefined>(
    initialFullWidth,
  );

  const toggleFullWidth = useCallback(() => {
    setFullWidth(prevState => !prevState);
  }, []);

  useEffect(() => {
    if (initialFullWidth !== initialFullWidthRef.current) {
      initialFullWidthRef.current = initialFullWidth;
      setFullWidth(initialFullWidth);
    }
  }, [initialFullWidth]);

  return useMemo(
    () => ({
      fullWidth,
      toggleFullWidth: canToggle ? toggleFullWidth : undefined,
    }),
    [canToggle, fullWidth, toggleFullWidth],
  );
};
