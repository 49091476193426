import {
  ThesaurusNodeDetailsDateValue,
  ThesaurusNodeDetailsValue,
} from 'api/types';

export const isDateValue = (
  value: ThesaurusNodeDetailsValue,
): value is ThesaurusNodeDetailsDateValue => {
  const textValue = value as ThesaurusNodeDetailsDateValue;
  return textValue.type === 'date';
};
