import {useCallback, useEffect, useMemo, useState} from 'react';
import clone from 'lodash/clone';
import {Schema} from 'schemaDefinition/types';
import {ColumnWithLabel, useGetResource} from 'api';
import {getAvailableColumns} from 'api/advancedsearch';
import {useGetTokens} from 'services/auth';
import {sortColumnConfigs} from 'schema/table/functions';
import {useColumnConfigState} from 'schema/table/hooks/useColumnConfigState';
import {CurrentColumnsType, advancedSearchSchemaName} from '../types';
import {getDefaultGlobalColumns} from '../functions/getDefaultGlobalColumns';
import {mapDataColumnsToSchema} from '../mappers/mapDataColumnsToSchema';
import {useDataTranslations} from './useDataTranslations';
import {useInitialColumns} from './useInitialColumns';

export const useCurrentColumns = (): CurrentColumnsType => {
  const getTokens = useGetTokens();
  const initialState = useInitialColumns(
    advancedSearchSchemaName,
    getDefaultGlobalColumns(),
  );
  const [currentColumns, setCurrentColumns] = useState<string[]>(initialState);

  const {getLabel} = useDataTranslations();
  const [schema, setSchema] = useState<Schema>({
    name: advancedSearchSchemaName,
    parts: [],
  });

  const handleGetAvailableColumns = useCallback(
    () => getAvailableColumns(getTokens),
    [getTokens],
  );

  const {status: statusAllColumns, data: allColumns} = useGetResource(
    handleGetAvailableColumns,
  );

  // Update schema, once (when allColumns is undefined).
  useEffect(() => {
    if (allColumns !== undefined) {
      setSchema(
        mapDataColumnsToSchema(
          advancedSearchSchemaName,
          allColumns,
          currentColumns,
        ),
      );
    }
  }, [allColumns, currentColumns]);

  const {columnConfigs, setColumnConfigs} = useColumnConfigState(
    advancedSearchSchemaName,
  );

  const handleSetCurrentColumns = useCallback(
    (newColumns: string[]) => {
      const newColumnConfigs = clone(columnConfigs ?? []);
      newColumnConfigs.forEach(c => (c.visible = false));

      newColumns.forEach(d => {
        const columnIndex = newColumnConfigs.findIndex(n => n.name === d);
        if (columnIndex !== -1) {
          newColumnConfigs[columnIndex].visible = true;
        } else {
          newColumnConfigs.push({
            name: d,
            visible: true,
          });
        }
      });

      setCurrentColumns(newColumns);
      setColumnConfigs(sortColumnConfigs(newColumnConfigs, newColumns));
    },
    [columnConfigs, setColumnConfigs],
  );

  const handleAddCurrentColumn = useCallback(
    (data: string) => {
      setCurrentColumns(prevState => {
        if ((allColumns ?? []).find(c => c.name === data)) {
          return [...prevState, data];
        }

        return prevState;
      });
    },
    [allColumns],
  );

  const handleRemoveCurrentColumn = useCallback((data: string) => {
    setCurrentColumns(prevState => prevState.filter(p => p !== data));
  }, []);

  const allColumnsWithLabel: ColumnWithLabel[] = useMemo(() => {
    return (
      allColumns
        ?.map(d => {
          return {
            ...d,
            label: getLabel(d.name),
          };
        })
        .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0)) ??
      []
    );
  }, [allColumns, getLabel]);

  // Load existing config into state on load.
  useEffect(() => {
    setCurrentColumns(
      (columnConfigs ?? [])?.filter(c => c.visible).map(c => c.name),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => {
    return {
      schema,
      allColumns: allColumnsWithLabel,
      loadingColumns: statusAllColumns === 'Loading',
      currentColumns,
      addCurrentColumn: handleAddCurrentColumn,
      removeCurrentColumn: handleRemoveCurrentColumn,
      setCurrentColumns: handleSetCurrentColumns,
    };
  }, [
    allColumnsWithLabel,
    currentColumns,
    handleAddCurrentColumn,
    handleRemoveCurrentColumn,
    handleSetCurrentColumns,
    schema,
    statusAllColumns,
  ]);
};
