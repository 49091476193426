import React, {useMemo} from 'react';
import {Stack} from '@mui/material';
import {assert} from 'assert-ts';
import {ContainerConfiguration} from 'schema/types';
import {Data, Schema} from 'schemaDefinition/types';
import {isData} from 'schemaDefinition/functions';
import {
  PreviewSchemaLocalScopeContext,
  PreviewSchemaScopeContextType,
  usePreviewSchemaGlobalScopeContext,
} from 'schema/contexts';
import {BasePartProps} from './types';
import {GroupLabel, Layout} from '../../../components';
import {usePreviewConfigurationContext} from '../contexts';
import {useContainerFromConfig} from '../hooks';
import {useReducedPreviewSchema} from '../hooks/useReducedPreviewSchema';
import {renderSchemaFragment} from './renderSchemaFragment';

type PreviewSchemaProps = Omit<
  BasePartProps<undefined>,
  'part' | 'layoutDirection' | 'scope' | 'globalScope'
> & {
  schema: Schema;
  containerConfiguration?: ContainerConfiguration;
};

/**
 * Sets local scope and renders preview for given schema x value.
 * Use to render both global schema and part schemas on lower level.
 * @returns
 */
export const PreviewSchema: React.FC<PreviewSchemaProps> = ({
  schema,
  value,
  valuePath,
  containerConfiguration,
}) => {
  const scope = useMemo((): PreviewSchemaScopeContextType => {
    assert(
      isData(value),
      'PreviewSchema: value must of type Data, i.e. object',
      {schema, value, valuePath},
    );

    return {
      schema,
      values: value,
    };
  }, [schema, value, valuePath]);
  const globalScope = usePreviewSchemaGlobalScopeContext();

  const config = usePreviewConfigurationContext();
  const Container = useContainerFromConfig(containerConfiguration);

  const {layout} = config;

  const groupedParts = useReducedPreviewSchema(
    value as Data,
    schema,
    valuePath,
    scope.values,
    globalScope.values,
    config,
  );

  return groupedParts && groupedParts.length > 0 ? (
    <PreviewSchemaLocalScopeContext.Provider value={scope}>
      <Stack sx={{p: 0}} spacing={2}>
        {groupedParts.map((group, idx) => {
          const direction = group.role
            ? layout.groups?.[group.role]?.direction ?? 'horizontal'
            : 'horizontal';
          return (
            <Layout>
              {group.title && <GroupLabel sx={{pl: 2}} label={group.title} />}
              <Container key={idx}>
                {renderSchemaFragment(
                  scope.values,
                  valuePath,
                  group.parts,
                  direction,
                  config,
                )}
              </Container>
            </Layout>
          );
        })}
      </Stack>
    </PreviewSchemaLocalScopeContext.Provider>
  ) : null;
};
