import {TypedSchema} from 'schemaDefinition/types';
import {MassUpdate, MassUpdateCreate} from 'api';

export const massUpdateCreateStaticSchema: TypedSchema<MassUpdateCreate> = {
  name: 'fieldAndOperationSchema',
  parts: [
    {
      type: 'codelist',
      name: 'massUpdateField',
      codelistId: 'massUpdateWorkField',
      required: true,
      cardinality: 'single',
    },
    {
      type: 'codelist',
      name: 'massUpdateAction',
      codelistId: 'massUpdateAction',
      required: true,
      cardinality: 'single',
    },
  ],
};

export const massUpdateStaticSchema: TypedSchema<MassUpdate> = {
  name: 'massUpdateStaticSchema',
  parts: [
    {
      type: 'codelist',
      name: 'status',
      codelistId: 'massUpdateStatus',
    },
    {
      type: 'text',
      name: 'created',
    },
    {
      type: 'text',
      name: 'completed',
    },
    ...massUpdateCreateStaticSchema.parts,
  ],
};
