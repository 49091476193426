import {ExpressionV4, ManifestationV4, WorkV4} from 'api/types';
import {AnyValidation, Condition} from 'schemaDefinition/types';

/**
 * Same property on WorkV4, ExpressionV4 and ManifestationV4, with
 * shared condtions and validations.
 */
type EntityWithAgents = Pick<WorkV4 | ExpressionV4 | ManifestationV4, 'agents'>;

type EntityCondition = Condition<
  EntityWithAgents,
  EntityWithAgents,
  EntityWithAgents['agents']
>;

type EntityValidation = AnyValidation<
  EntityWithAgents,
  EntityWithAgents,
  EntityWithAgents['agents']
>;

export const isUnnamedAgentLink: EntityCondition = {
  op: 'eq',
  arg1: {$ref: '.link.linkStatus'},
  arg2: 'unnamed',
};

export const validateLinkedAgentId: EntityValidation = {
  level: 'warning',
  op: 'or',
  arg: [{arg: {$ref: '.link.entityId'}}, isUnnamedAgentLink],
  messageKey: 'agent.not.verified',
};

export const validateExternalLinkedAgentId: EntityValidation = {
  level: 'warning',
  op: 'or',
  arg: [{arg: {$ref: '.link.nationalId'}}, isUnnamedAgentLink],
  messageKey: 'agent.not.external.verified',
};

export const validateLinkedImprintId: EntityValidation = {
  level: 'warning',
  arg: {$ref: '.link.entityId'},
  messageKey: 'imprint.not.verified',
};
