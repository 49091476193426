import assert from 'assert-ts';
import {Schemas} from '../dto.generated';

export const mapAgentDocumentToAgentDto = (
  dto: Schemas.AgentDocument,
):
  | Schemas.AgentPersonDto
  | Schemas.AgentCorporationDto
  | Schemas.AgentEventDto
  | Schemas.AgentPublisherDto => {
  switch (dto.agentType) {
    case 'PERSON': {
      const agent: Schemas.AgentPersonDto = {
        ...dto.agentPersonData,
        ...dto,
        agentType: 'PERSON',
        internalAgent: undefined,
        internalComments: undefined,
        externalComments: dto.notes,
        gender: dto.agentPersonData?.gender ?? 'u',
        occupationalField: dto.agentPersonData?.occupationalFields,
        pseudonym: dto.agentPersonData?.isPseudonym,
        nameVariants: (dto.agentNames ?? []).map(n => ({
          ...n,
          nameOrder: assert(
            n.nameOrder,
            'mapAgentSearchToAgentDto: Expected nameOrder',
            n,
          ),
        })),
        works: [],
        expressions: [],
        workRelations: [],
        agentPersonRelations: [],
      };

      return agent;
    }
    case 'CORPORATION': {
      const agent: Schemas.AgentCorporationDto = {
        ...dto.agentCorporationData,
        ...dto,
        agentType: 'CORPORATION',
        internalAgent: undefined,
        internalComments: undefined,
        externalComments: dto.notes,
        nameVariants: (dto.agentNames ?? []).map(n => ({
          ...n,
          nameOrder: assert(
            n.nameOrder,
            'mapAgentSearchToAgentDto: Expected nameOrder',
            n,
          ),
        })),
        works: [],
        expressions: [],
        workRelations: [],
        agentCorporationRelations: [],
      };

      return agent;
    }
    case 'EVENT': {
      const agent: Schemas.AgentEventDto = {
        ...dto.agentEventData,
        ...dto,
        agentType: 'EVENT',
        internalAgent: undefined,
        internalComments: undefined,
        externalComments: dto.notes,
        nameVariants: (dto.agentNames ?? []).map(n => ({
          ...n,
          nameOrder: assert(
            n.nameOrder,
            'mapAgentSearchToAgentDto: Expected nameOrder',
            n,
          ),
        })),
        works: [],
        expressions: [],
        workRelations: [],
      };

      return agent;
    }
    case 'PUBLISHER': {
      const agent: Schemas.AgentPublisherDto = {
        ...dto.agentPublisherData,
        ...dto,
        agentType: 'PUBLISHER',
        internalAgent: undefined,
        internalComments: undefined,
        externalComments: dto.notes,
        nameVariants: (dto.agentNames ?? []).map(n => ({
          ...n,
          nameOrder: assert(
            n.nameOrder,
            'mapAgentSearchToAgentDto: Expected nameOrder',
            n,
          ),
        })),
        works: [],
        expressions: [],
        workRelations: [],
      };

      return agent;
    }
  }

  assert(false, 'mapAgentSearchToAgentDto: Unknown agentType', dto);
};
