import {Concept} from 'types';
import {CodeList, CodeListId, CodeListRef} from 'api/types';
import {expressionFormat, onix_74, onix_150} from './data';

const collectionTypeCodeList: Omit<CodeList, 'language'> = {
  id: 'collection_type',
  codes: [
    {
      code: Concept.series,
      value: 'Serie',
    },
    {
      code: Concept.educationalSeries,
      value: 'Læreverk',
    },
    {
      code: Concept.otherConnection,
      value: 'Annen tilknytning',
    },
    {
      code: Concept.publisherSeries,
      value: 'Forlagsserie',
    },
  ],
};

const massUpdateWorkFieldCodeList: Omit<CodeList, 'language'> = {
  id: 'massUpdateWorkField',
  codes: [
    {code: 'grep', value: 'GREP'},
    {code: 'genreAndForm', value: 'Sjanger og form'},
    {code: 'subjects', value: 'Emneord'},
    {code: 'themes', value: 'Thema'},
  ],
};

const massUpdateOperationCodeList: Omit<CodeList, 'language'> = {
  id: 'massUpdateAction',
  codes: [
    {code: 'ADD', value: 'Legg til verdi'},
    {code: 'REMOVE', value: 'Fjern verdi'},
    {code: 'REPLACE', value: 'Erstatt verdi'},
  ],
};

const massUpdateStatusCodeList: Omit<CodeList, 'language'> = {
  id: 'massUpdateStatus',
  codes: [
    {code: 'NEW', value: 'Ny'},
    {code: 'UNPROCESSED', value: 'Ikke behandlet'},
    {code: 'INPROGRESS', value: 'Under behandling'},
    {code: 'COMPLETED', value: 'Fullført'},
  ],
};

const massUpdateTypeCodeList: Omit<CodeList, 'language'> = {
  id: 'massUpdateType',
  codes: [
    {code: 'work', value: 'Verk'},
    {code: 'manifestation', value: 'Manifestasjon'},
  ],
};

// TODO: Where should schema data types be defined
const codeLists: Omit<CodeList, 'language'>[] = [
  {
    id: 'LANGUAGE',
    codes: onix_74,
  },
  {
    id: 'COLOUR',
    codes: [
      {code: 'BLK', value: 'Svart'},
      {code: 'BLU', value: 'Blå'},
      {code: 'BRN', value: 'Brun'},
      {code: 'BRN', value: 'Mørk brun'},
      {code: 'BUR', value: 'Burgunder'},
      {code: 'CRE', value: 'Beige'},
      {code: 'GLD', value: 'Gull'},
      {code: 'GRN', value: 'Grønn'},
      {code: 'GRY', value: 'Grå'},
      {code: 'ORG', value: 'Oransje'},
      {code: 'PNK', value: 'Rosa'},
      {code: 'PUR', value: 'Lilla'},
      {code: 'RED', value: 'Rød'},
      {code: 'TAN', value: 'Lys brun'},
      {code: 'TEA', value: 'Turkis'},
      {code: 'WHI', value: 'Hvit'},
      {code: 'YEL', value: 'Gul'},
      {code: 'SLV', value: 'Sølv'},
    ],
  },
  {
    id: 'DEWEY_SOURCE',
    codes: [
      {
        code: CodeListRef.DEWEY_SOURCE['23/nor'],
        value: '23/nor',
      },
    ],
  },
  {
    id: 'EDITION_TYPE',
    codes: [
      {code: 'ABR', value: 'Forenklet utgave'},
      {code: 'ADP', value: 'Bearbeidet utgave'},
      {code: 'ENL', value: 'Utvidet utgave'},
      {code: 'DGO', value: 'Digital original'},
      {code: 'FAC', value: 'Faksimileutgave'},
      {code: 'MDT', value: 'Filmutgave'},
      {code: 'UBR', value: 'Fullstendig utgave'},
      {code: 'ILL', value: 'Illustrert praktutgave'},
      {code: 'CRI', value: 'Kritisk utgave'},
      {code: 'NED', value: 'Ny utgave'},
      {code: 'REV', value: 'Revidert utgave'},
    ],
  },
  {
    id: 'EXPRESSION_FORMAT',
    codes: expressionFormat,
  },

  {
    id: 'EXPRESSION_CONTENT',
    codes: [
      {code: '24', value: 'Animasjon / interaktivitet'},
      {code: '19', value: 'Figurer / diagrammer'},
      {code: '12', value: 'Kart'},
      {code: '22', value: 'Lyd'},
      {code: '14', value: 'Nettbasert innhold'},
      {code: '11', value: 'Noter'},
      {code: '05', value: 'Spill'},
      {code: '30', value: 'Video'},
    ],
  },

  {id: 'PRODUCT_FORM', codes: onix_150},
  {
    id: 'product_form_simple_values',
    codes: [
      {
        code: CodeListRef.PRODUCT_FORM[
          'Annet digitalt format (på fysisk bærer)' // DZ
        ],
        value: 'Minnekort',
      },
      {
        code: CodeListRef.PRODUCT_FORM[
          'Digitalt format, nedlastbart og nettbasert (online)' // EB
        ],
        value: 'Digital ressurs',
      },
      {
        code: CodeListRef.PRODUCT_FORM[
          'Digitalt format, nedlastbart (download)' // ED
        ],
        value: 'E-bok',
      },
      {
        code: CodeListRef.PRODUCT_FORM[
          'Produkt bestående av flere enkeltprodukter' // SA
        ],
        value: 'Pakke',
      },
      {
        code: CodeListRef.PRODUCT_FORM[
          'Lydfil' // SA
        ],
        value: 'Lydfil',
      },
    ],
  },
  {
    id: 'PRODUCT_FORM_DETAIL',
    codes: [
      {
        code: CodeListRef.PRODUCT_FORM_DETAIL['MP3-format'],
        value: 'MP3-format',
      },
    ],
  },
  {
    id: 'GENDER',
    codes: [
      {
        code: 'FEMALE',
        value: 'Kvinne',
      },
      {
        code: 'MALE',
        value: 'Mann',
      },
      {
        code: 'UNSPECIFIED',
        value: 'Ukjent',
      },
    ],
  },
  {
    id: 'PRODUCT_GROUP',
    codes: [
      {
        code: CodeListRef.PRODUCT_GROUP.Astronomi,
        value: 'Astronomi',
      },
      {
        code: CodeListRef.PRODUCT_GROUP['Grunnskole, diverse'],
        value: 'Grunnskole, diverse',
      },
      {
        code: CodeListRef.PRODUCT_GROUP['Grunnskole, diverse ebøker'],
        value: 'Grunnskole, diverse ebøker',
      },
      {
        code: CodeListRef.PRODUCT_GROUP['Grunnskole, diverse lydbøker'],
        value: 'Grunnskole, diverse lydbøker',
      },
      {
        code: CodeListRef.PRODUCT_GROUP.Humor,
        value: 'Humor',
      },
      {
        code: CodeListRef.PRODUCT_GROUP['2 + Billedbøker'],
        value: '2 + Billedbøker',
      },
    ],
  },
  {
    id: 'BOOK_GROUP',
    codes: [
      {
        code: CodeListRef.BOOK_GROUP['Annet. Sammensatte produkter'],
        value: 'Annet. Sammensatte produkter',
      },
    ],
  },
  {
    id: 'COUNTRY',
    codes: [
      {
        code: 'NO',
        value: 'Norge',
      },
    ],
  },
  {
    id: 'WORK_ROLE_TYPE',
    codes: [
      {
        code: 'A01',
        value: 'Forfatter',
        deactivated: false,
      },
      {
        code: 'A02',
        value: 'Med',
        deactivated: true,
      },
      {
        code: 'A03',
        value: 'Manus av',
        deactivated: false,
      },
    ],
  },
  {
    id: 'EXPRESSION_ROLE_TYPE',
    codes: [
      {
        code: CodeListRef.EXPRESSION_ROLE_TYPE['Komponist'],
        value: 'Av (komponist)',
      },
      {
        code: CodeListRef.EXPRESSION_ROLE_TYPE['Lest av'],
        value: 'Lest av',
      },
      {
        code: CodeListRef.EXPRESSION_ROLE_TYPE['Oversatt av'],
        value: 'Oversatt av',
      },
      {
        code: CodeListRef.EXPRESSION_ROLE_TYPE['Illustrert av'],
        value: 'Illustrert av',
      },
    ],
  },
  collectionTypeCodeList,
  massUpdateWorkFieldCodeList,
  massUpdateOperationCodeList,
  massUpdateStatusCodeList,
  massUpdateTypeCodeList,
  {
    id: 'work_collection_type',
    codes: collectionTypeCodeList.codes.filter(
      c => c.code !== Concept.publisherSeries,
    ),
  },
  {
    id: 'EXPRESSION_FORMAT',
    codes: [
      {
        code: CodeListRef.EXPRESSION_FORMAT.AUDIO,
        value: 'Audio',
      },
      {
        code: CodeListRef.EXPRESSION_FORMAT.TEXT,
        value: 'Text',
      },
    ],
  },
  {
    id: 'manifestation_role_type',
    codes: [
      {
        code: 'publisher',
        value: 'Utgiver',
      },
    ],
  },
  {
    id: 'availability_status',
    codes: [
      {
        code: 'AVAILABLE',
        value: 'Tilgjengelig',
      },
      {
        code: 'PRE_ORDER',
        value: 'Bestillingsklar',
      },
      {
        code: 'UNAVAILABLE',
        value: 'Utsolgt',
      },
    ],
  },
  /*
  {
    id: 'EDUCATION_LEVEL',
    codes: [
      {code: '1. trinn', value: '1. trinn'},
      {code: '2. trinn', value: '2. trinn'},
      {code: '3. trinn', value: '3. trinn'},
      {code: '4. trinn', value: '4. trinn'},
      {code: '5. trinn', value: '5. trinn'},
      {code: '6. trinn', value: '6. trinn'},
      {code: '7. trinn', value: '7. trinn'},
      {code: '8. trinn', value: '8. trinn'},
      {code: '9. trinn', value: '9. trinn'},
      {code: '10. trinn', value: '10. trinn'},
      {code: 'VG1', value: 'VG1'},
      {code: 'VG2', value: 'VG2'},
      {code: 'VG3', value: 'VG3'},
    ],
  },
  {
    id: 'EDUCATION_TARGET_GROUP',
    codes: [
      {code: 'Barnehager', value: 'Barnehager'},
      {code: 'Fremmedspråklige elever', value: 'Fremmedspråklige elever'},
      {code: 'Fremmedspråklige voksne', value: 'Fremmedspråklige voksne'},
      {code: 'Spesialundervisning', value: 'Spesialundervisning'},
      {code: 'Spor 1', value: 'Spor 1'},
      {code: 'Spor 2', value: 'Spor 2'},
      {code: 'Spor 3', value: 'Spor 3'},
      {code: 'Voksne', value: 'Voksne'},
    ],
  },
  {
    id: 'TEACHING_MATERIALS',
    codes: [
      {code: 'Alternative grunnbøker', value: 'Alternative grunnbøker'},
      {code: 'Arbeidsbøker', value: 'Arbeidsbøker'},
      {code: 'Elevressurser', value: 'Elevressurser'},
      {code: 'Fasiter', value: 'Fasiter'},
      {code: 'Grammatikk', value: 'Grammatikk'},
      {code: 'Grunnbøker', value: 'Grunnbøker'},
      {code: 'Lærerressurser', value: 'Lærerressurser'},
      {code: 'Lesetrening', value: 'Lesetrening'},
      {code: 'Lettlest', value: 'Lettlest'},
      {code: 'Lydressurser', value: 'Lydressurser'},
      {code: 'Nettsteder', value: 'Nettsteder'},
      {code: 'Tavleressurser', value: 'Tavleressurser'},
      {code: 'Tilleggslitteratur', value: 'Tilleggslitteratur'},
    ],
  },

   */
  {
    id: 'task_type',
    codes: [
      {
        code: 'new',
        value: 'Ny post',
      },
      {
        code: 'PRE',
        value: 'Prekat',
      },
      {
        code: 'CONTROL',
        value: 'Kontroll',
      },
      {
        code: 'CHANGE',
        value: 'Endring',
      },
      {
        code: 'ML',
        value: 'ML',
      },
    ],
  },
  {
    id: 'media_sub_type',
    codes: [
      {
        code: 'CONTENT',
        value: 'Innhold',
      },
      {
        code: 'COVER',
        value: 'Omslag',
      },
      {
        code: 'ATTACHMENT',
        value: 'Vedlegg',
      },
    ],
  },
  {
    id: 'UNNAMED_PERSON',
    codes: [
      {code: '01', value: 'Ukjent	'},
      {code: '02', value: 'Anonym'},
      {code: '03', value: 'et al'},
      {code: '04', value: 'Forskjellige forfattere'},
      {code: '05', value: 'Syntetisk stemme – mannlig'},
      {code: '06', value: 'Syntetisk stemme – kvinnelig'},
      {code: '07', value: 'Syntetisk stemme – uspesifisert'},
      {code: '08', value: 'Syntetisk stemme – basert på en virkelig person'},
      {code: '09', value: 'KI (kunstig intelligens)'},
    ],
  },
  {
    id: 'WORK_TITLE_TYPE',
    codes: [
      {
        code: '36',
        value: 'Deltittel',
      },
      {
        code: '37',
        value: 'Tittelnummer',
      },
    ],
  },
  {
    id: 'WORK_TYPE',
    codes: [
      {
        code: CodeListRef.WORK_TYPE['Bok'],
        value: 'Bok',
      },
      {
        code: CodeListRef.WORK_TYPE['Vare'],
        value: 'Vare',
      },
    ],
  },
];

const getDummyCodeList = (id: CodeListId): Omit<CodeList, 'language'> => ({
  id,
  codes: [
    {
      code: 'code1',
      value: `${id}-1`,
    },
    {
      code: 'code1',
      value: `${id}-2`,
    },
    {
      code: 'code1',
      value: `${id}-3`,
    },
  ],
});

export const getMockCodeListSync = (
  id: CodeListId,
  language: string,
): CodeList => {
  const codelist = codeLists.find(cl => cl.id === id) ?? getDummyCodeList(id);
  return {
    ...codelist,
    language,
  };
};

export const getMockCodeList = (
  id: CodeListId,
  language: string,
): Promise<CodeList> => {
  return Promise.resolve(getMockCodeListSync(id, language));
};
