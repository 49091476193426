import {Locale} from 'localization/types';
import {isFeatureEnabled} from 'configuration';
import {
  GetTokens,
  Thesaurus,
  ThesaurusId,
  ThesaurusNode,
  ThesaurusNodeDto,
} from './types';
import {Schemas} from './dto.generated';
import {httpGet} from './http/ebba';
import {mapGrepExtendedConceptDto} from './mappers';
import {mapThesaurusDto} from './mappers/mapThesaurusDto';
import {getMockThesaurus} from './mockThesauruses/getMockThesaurus';

const mockThesauruses: ThesaurusId[] = isFeatureEnabled(
  'loadThesaurusesFromApi',
)
  ? []
  : ['grep', 'thema', 'bokbasen', 'genreandform'];

export const getThesaurus = (
  thesaurusId: ThesaurusId,
  language: Locale,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<Thesaurus> => {
  return (
    mockThesauruses.includes(thesaurusId)
      ? getMockThesaurus(thesaurusId, language)
      : httpGet<ThesaurusNodeDto[]>({
          subdir: `thesaurus/${thesaurusId}/export`,
          getTokens,
          mock,
        })
  ).then(dto => mapThesaurusDto(dto, thesaurusId, language));
};

export const getGrepNodeDetails = (
  code: string,
  _language: Locale,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<ThesaurusNode> => {
  return httpGet<Schemas.GrepExtendedConcept>({
    subdir: `thesaurus/grep/${code}`,
    getTokens,
    mock,
  }).then(r => mapGrepExtendedConceptDto(code, r));
};
