import {valueReducerFactory} from 'services/utils/redux/valueReducerFactory';
import {WorkItemsState} from '../types';

const [
  WORK_ITEMS_KEY,
  workItemsReducer,
  setWorkItems,
  clearWorkItems,
  useWorkItems,
] = valueReducerFactory(`workItems`, [] as WorkItemsState);

export type WorkItemsAction =
  | ReturnType<typeof setWorkItems>
  | ReturnType<typeof clearWorkItems>;

export {
  WORK_ITEMS_KEY,
  workItemsReducer,
  setWorkItems,
  clearWorkItems,
  useWorkItems,
};
