import {AgentSubType} from 'types';
import {Agent, AgentTypeDto, GetTokens} from 'api/types';
import agentQuery from './data/agentQuery.json';
import {Schemas} from './dto.generated';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpElastic, httpElasticV2} from './http/search/httpElastic';
import {mapAgentDto, mapSearchAgentResult} from './mappers';
import {mapAgentDocumentToAgentDto} from './mappers/mapAgentDocumentToAgentDto';
import {mapToAgentTypeDto} from './mappers/mapAgentTypeDto';
import {mapHighlight} from './mappers/mapHighlights';
import {SearchAgentDto, SearchResult, SearchResultDto} from './searchTypes';

export const searchAgents2 = (
  query: string,
  agentType: AgentSubType,
  page: number,
  size: number,
  getTokens: GetTokens,
  abortSignal?: AbortSignal,
  mock?: boolean,
): Promise<SearchResult<Agent>> => {
  /**
   * Placed the JSON in a file to easier debug it in elastic cloud console.
   */
  const elasticQuery = JSON.stringify(agentQuery)
    .replaceAll('${QUERY_PLACEHOLDER}', query)
    .replaceAll('${AGENT_TYPE_PLACEHOLDER}', agentType.toUpperCase());

  return exceptionToPromiseReject(() =>
    httpElasticV2<SearchResultDto<Schemas.AgentDocument>>(
      getTokens,
      'ebs-agent-alias',
      JSON.parse(elasticQuery),
      page,
      size,
      abortSignal,
      mock,
    ).then(mapAgentHits),
  );
};

export const mapAgentHits = (
  dto: SearchResultDto<Schemas.WorkDocument>,
): SearchResult<Agent> => {
  return {
    // Mapping from ElasticDto to ApiDto. Then using the ApiDto mapping to out Agent dto (same as when calling agent/{id}).
    hits: dto?.hits.hits.map(h =>
      mapAgentDto(mapAgentDocumentToAgentDto(h._source)),
    ),
    highlights: dto?.hits.hits.map(h => mapHighlight(h.highlight)).flat(1),
    total: dto.hits.total.value,
  };
};

/**
 * @deprecated
 */
export const searchAgents = (
  search: string,
  agentTypes: AgentSubType[],
  page: number,
  size: number,
  getTokens: GetTokens,
  abortSignal?: AbortSignal,
  mock?: boolean,
): Promise<SearchResult<Agent>> => {
  const apiAgentTypes = agentTypes.map(mapToAgentTypeDto);

  return exceptionToPromiseReject(() =>
    httpElastic<SearchResultDto<SearchAgentDto>>(
      getTokens,
      '_search',
      'agent',
      {
        bool: {
          ...makeSearchPart(search),
          ...makeFilterPart(apiAgentTypes),
        },
      },
      page,
      size,
      0.1,
      undefined,
      abortSignal,
      mock,
    ).then(mapSearchAgentResult),
  );
};

const makeSearchPart = (search: string): object => {
  return {
    must: [
      ...search.split(' ').map(term => ({
        query_string: {
          query: '*' + term + '*',
        },
      })),
    ],
    should: [
      ...search.split(' ').map(term => ({
        query_string: {
          query: term,
        },
      })),
    ],
  };
};
const makeFilterPart = (agentTypes: AgentTypeDto[]): object => {
  if (agentTypes.length === 0) {
    return {};
  }

  if (agentTypes.length === 1) {
    return {
      filter: {
        term: {'agentType.keyword': agentTypes[0]},
      },
    };
  }

  return {
    filter: {
      bool: {
        should: agentTypes.map(agentType => ({
          term: {
            'agentType.keyword': agentType,
          },
        })),
      },
    },
  };
};
