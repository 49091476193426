import React, {useCallback, useMemo} from 'react';
import {assert} from 'assert-ts';
import {Concept} from 'types';
import {DataLoadStatus, MassUpdate, WorkV4} from 'api/types';
import {useLocalization} from 'localization';
import {useWorks} from 'services/data/metadata/hooks/useWorks';
import {GetWorkItemLink, MassUpdatePreviewCore} from './MassUpdatePreviewCore';

type Props = {
  massUpdate: MassUpdate;
};

export const MassUpdateWorksPreview: React.FC<Props> = ({massUpdate}) => {
  assert(
    massUpdate.type === Concept.work,
    'MassUpdateWorksPreview: type work is expected',
  );

  const {t} = useLocalization();

  const failedIds = useMemo(
    () => massUpdate.items.failed.map(item => item.id),
    [massUpdate.items.failed],
  );
  const unprocessed = useWorkItems(massUpdate.items.unprocessed);
  const succeeded = useWorkItems(massUpdate.items.succeeded);
  const failed = useWorkItems(failedIds);

  const getNavigationLink = useCallback<GetWorkItemLink<WorkV4>>(
    (item, status) => {
      return {
        link: `/metadata/${item.id}`,
        tooltip: t(`page.massUpdate.details.work.link.${status}.tooltip`, {
          title: item.preferredTitle?.[0] ?? '',
        }),
      };
    },
    [t],
  );

  return (
    <MassUpdatePreviewCore
      massUpdate={massUpdate}
      unprocessed={unprocessed}
      succeeded={succeeded}
      failed={failed}
      getWorkItemLink={getNavigationLink}
    />
  );
};

const useWorkItems = (ids: string[]): DataLoadStatus<WorkV4[]> => {
  const works = useWorks(ids);
  return useMemo(() => {
    const data = (works.data ?? []).map(item => item.work);
    return {...works, data};
  }, [works]);
};
