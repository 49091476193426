import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {MassUpdateCreate} from 'api/types';
import {useLocalization} from 'localization';
import {useSaveNewMassUpdate} from 'services/data';
import {useMassUpdateItems} from 'services/massUpdateItems';
import {FlexBox, Scene, useSnacks} from 'components';
import {useSceneExtensionsContext} from 'sceneExtensions';
import {MassUpdateTitle, NewMassUpdateWizard} from './components';
import {useMassUpdateTitle} from './hooks';

export const MassUpdateNewSceneCore: React.FC = () => {
  const {t} = useLocalization();
  const navigate = useNavigate();
  const massUpdateItems = useMassUpdateItems();

  const title = useMassUpdateTitle(
    massUpdateItems.type,
    massUpdateItems.itemIds.length,
  );
  const {currentExtension} = useSceneExtensionsContext();

  const saveMassUpdate = useSaveNewMassUpdate();
  const {successSnack, errorSnack} = useSnacks();

  const handleCancel = useCallback(() => {
    navigate('/massUpdate');
  }, [navigate]);

  const handleSubmit = useCallback(
    (massUpdate: Partial<MassUpdateCreate>, ids: string[]) => {
      return saveMassUpdate(massUpdate as MassUpdateCreate, ids)
        .then(massUpdateId => {
          successSnack(t('page.massUpdate.new.submit.success'));
          // Allow snacks to show before navigating
          setTimeout(() => {
            navigate(`/massUpdate/details/${massUpdateId}`);
          }, 500);
        })
        .catch(error => {
          errorSnack(t('page.massUpdate.new.submit.failed', error));
        });
    },
    [errorSnack, navigate, saveMassUpdate, successSnack, t],
  );

  return (
    <Scene
      browserTitle={t('appbar.massUpdate')}
      rightPanelConfig={currentExtension?.panelConfig}>
      <Scene.Content loadStatus={'Loaded'}>
        <MassUpdateTitle title={title} type={massUpdateItems.type} />

        <FlexBox sx={{maxWidth: 1200}}>
          <NewMassUpdateWizard
            massUpdateType={massUpdateItems.type}
            itemIds={massUpdateItems.itemIds}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
          />
        </FlexBox>
      </Scene.Content>
      {currentExtension ? (
        <Scene.RightPanel>
          {currentExtension.renderRightPanel()}
        </Scene.RightPanel>
      ) : null}
    </Scene>
  );
};
