import React, {ReactNode, useMemo} from 'react';
import {Box} from '@mui/material';
import Grid from '@mui/material/Grid';
import {GridProps} from '@mui/material/Grid/Grid';
import {TextProps} from 'components/text/types';
import {ColorPalette} from 'theme';
import {FlexBox, IconButton, Text, Tooltip, TooltipProps} from 'components';
import {useSceneSizeContext} from './context/SceneSizeContext';

type Props = GridProps & {
  title?: string;
  tooltipTitle?: TooltipProps['title'];
  textProps?: TextProps;
  children?: ReactNode;
};

export const SceneTitle: React.FC<Props> = ({
  title,
  tooltipTitle,
  children,
  textProps,
  ...gridProps
}) => {
  const {fullWidth, toggleFullWidth} = useSceneSizeContext();
  const titleComponent = useMemo(() => {
    return title ? (
      <Box
        component={Grid}
        container
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}>
        <Grid
          item
          {...gridProps}
          sx={{
            overflow: 'hidden',
          }}>
          <FlexBox left center horizontal>
            {toggleFullWidth ? (
              <IconButton
                icon={fullWidth ? 'ChevronRight' : 'ChevronLeft'}
                onClick={toggleFullWidth}
              />
            ) : null}
            <Text
              data-cy={'scene-title'}
              sx={
                textProps?.sx
                  ? {color: ColorPalette.textPrimary, ...textProps.sx}
                  : {color: ColorPalette.textPrimary}
              }
              component={textProps?.component}
              variant={textProps?.variant ?? 'h1'}
              noWrap>
              {title}
            </Text>
            {tooltipTitle ? (
              <FlexBox>
                <Tooltip title={tooltipTitle} />
              </FlexBox>
            ) : null}
          </FlexBox>
        </Grid>
        {children}
      </Box>
    ) : null;
  }, [
    title,
    gridProps,
    toggleFullWidth,
    fullWidth,
    textProps?.sx,
    textProps?.component,
    textProps?.variant,
    tooltipTitle,
    children,
  ]);

  return (
    <Box
      component={Grid}
      container
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}>
      <Grid item xs={12}>
        {titleComponent}
      </Grid>
    </Box>
  );
};
