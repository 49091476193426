import React, {useCallback} from 'react';
import {Step, StepButton, StepLabel, Stepper} from '@mui/material';
import {Layout} from 'components/layout';
import {StepProps} from './Step';

export type WizardStepperProps = {
  showStepNumbers?: boolean;
  activeStep: number;
  steps: Omit<StepProps, 'content'>[];
  validateStep?: (stepIndex: number) => boolean;
  onStepSelected?: (stepIndex: number) => void;
};

export const WizardStepper: React.FC<WizardStepperProps> = ({
  activeStep,
  steps,
  validateStep,
  onStepSelected,
}) => {
  const isStepDisabled = useCallback(
    (stepIndex: number) => {
      if (validateStep) {
        // First step is always enabled
        if (stepIndex === 0) {
          return false;
        }

        // Step is disabled if any previous step is invalid
        for (let i = stepIndex - 1; i >= 0; i--) {
          if (!validateStep(i)) {
            return true;
          }
        }
      }

      // Step is enabled by default
      return false;
    },
    [validateStep],
  );

  return (
    <Layout adjustLeft>
      <Stepper nonLinear activeStep={activeStep} connector={null}>
        {steps.map(({label, ...stepProps}, index) => (
          <Step
            key={label}
            {...stepProps}
            active={index === activeStep}
            data-cy={`step-${index + 1}`}>
            <StepButton
              disabled={isStepDisabled(index)}
              disableRipple
              onClick={
                onStepSelected ? () => onStepSelected(index) : undefined
              }>
              <StepLabel>{`${index + 1}. ${label}`}</StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Layout>
  );
};
