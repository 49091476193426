import {assert} from 'assert-ts';
import {Media} from '../types';
import {Schemas} from '../dto.generated';

export const mapMediaForEans = (dto: Schemas.MediaForEansDto): Media[] => {
  const media =
    dto.mediaForEans?.[0].medias?.filter(m => !m.compressed).map(mapMediaDto) ||
    [];
  const compressed =
    dto.mediaForEans?.[0].medias?.filter(m => m.compressed).map(mapMediaDto) ||
    [];

  const latest = filterOnlyLatest(media)
    .sort(sortMedia)
    .sort(sortMediaAttachment);

  const latestCompressed = filterOnlyLatest(compressed)
    .sort(sortMedia)
    .sort(sortMediaAttachment);

  return [...latest, ...latestCompressed];
};

const sortMedia = (a: Media, b: Media): number => {
  const order = ['CONTENT', 'COVER', 'ATTACHMENT', '', undefined];

  return order.indexOf(a.subType) > order.indexOf(b.subType) ? 1 : -1;
};

const sortMediaAttachment = (a: Media, b: Media): number => {
  if (a.subType === b.subType && a.subType === 'ATTACHMENT') {
    if (a.fileNr === b.fileNr) {
      return 0;
    }

    return a.fileNr < b.fileNr ? 1 : -1;
  }

  return 0;
};

export const filterOnlyLatest = (media: Media[]): Media[] => {
  media.sort((a: Media, b: Media) => {
    if (a.version > b.version) {
      return -1;
    }
    if (a.version < b.version) {
      return 1;
    }

    return 0;
  });

  return media.filter((value, index, self) => {
    if (value.subType === 'ATTACHMENT') {
      // Attachment has to check fileNr aswell
      const testAttachment = self.findIndex(
        m =>
          m.type === value.type &&
          m.subType === value.subType &&
          m.fileNr === value.fileNr,
      );

      if (index === testAttachment) {
        return true;
      }
    }

    const testOther = self.findIndex(
      m => m.type === value.type && m.subType === value.subType,
    );

    return index === testOther;
  });
};

export const mapMediaDto = (dto: Schemas.MediaDto): Media => {
  const extension = assert.soft(
    dto.fileExtension,
    'mapMediaDto: fileExtension expected',
    dto,
  )
    ? dto.fileExtension
    : 'PDF';

  return {
    id: assert(dto.mediaId, 'mapMediaDto: mediaId expected', dto),
    version: dto.version ?? 1,
    type: assert(dto.mediaType, 'mapMediaDto: mediaType expected', dto),
    subType: assert(
      dto.mediaSubType,
      'mapMediaDto: mediaSubType expected',
      dto,
    ),
    extension,
    fileNr: parseInt(dto.filenumber ?? '0', 10),
    ...dto,
  };
};
