import React, {useMemo} from 'react';
import {SxProps} from '@mui/material';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {
  ExpressionV4,
  ManifestationStatus as ManifestationStatusType,
  ManifestationV4,
  WorkMetadata,
} from 'api';
import {getLowestStatus} from 'services/data/metadata/reducer/metadataEdit/functions';
import {FlexBox, Spacer, Text} from 'components';
import {useExpressionTitle} from './ExpressionCard/hooks/useExpressionTitle';
import {ManifestationStatus} from './ManifestationStatus';
import {ManifestationTitle} from './ManifestationTitle';

type Props = {
  manifestation: ManifestationV4;
  currentExpression: ExpressionV4;
  targetWork: WorkMetadata;
};

const containerSx = {
  width: '60vh',
  minWidth: '600px',
  maxWidth: '800px',
} as const;

const labelSx: SxProps = {
  flex: 1,
  fontWeight: 'normal',
  color: ColorPalette.warmGreen,
} as const;

const infoSx = {
  flex: 4,
};

export const MoveToExistingWorkInfo: React.FC<Props> = ({
  manifestation,
  currentExpression,
  targetWork,
}) => {
  const {t} = useLocalization();
  const expressionTitle = useExpressionTitle(currentExpression, undefined);
  const targetWorkTitle = targetWork.work.preferredTitle?.[0] ?? '';
  const targetWorkStatus = useMemo(() => {
    return targetWork.manifestations.reduce<ManifestationStatusType>(
      (acc, m) => getLowestStatus(acc, m.status) ?? acc,
      'COMPLETED',
    );
  }, [targetWork.manifestations]);

  return (
    <FlexBox sx={containerSx}>
      <FlexBox horizontal center>
        <Text sx={labelSx} variant="h4">
          {t('entity.expression.title')}
        </Text>
        <FlexBox sx={infoSx} horizontal>
          <Spacer size={0.75} />
          <Text variant="h4" sx={{fontWeight: 'normal'}}>
            {expressionTitle}
          </Text>
        </FlexBox>
      </FlexBox>
      <Spacer size={1} />
      <FlexBox horizontal center>
        <Text sx={labelSx} variant="h4">
          {t('entity.manifestation.title')}
        </Text>
        <FlexBox sx={infoSx} horizontal>
          <ManifestationTitle
            manifestation={manifestation}
            fontWeight="normal"
          />
          <Spacer size={1} />
          <ManifestationStatus status={manifestation.status} />
        </FlexBox>
      </FlexBox>
      <Spacer size={2} />
      <FlexBox horizontal center>
        <Text sx={labelSx} variant="h4">
          {t('search.work.move.toWork.label')}
        </Text>
        <FlexBox sx={infoSx} horizontal left center>
          <Spacer size={0.75} />
          <Text variant="h4" sx={{fontWeight: 'normal'}}>
            {targetWorkTitle}
          </Text>
          <Spacer size={1} />
          <ManifestationStatus status={targetWorkStatus} />
        </FlexBox>
      </FlexBox>
      <Spacer size={2} />
      <Text sx={labelSx} variant="h4">
        {t('search.work.move.footer')}
      </Text>
    </FlexBox>
  );
};
