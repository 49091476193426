import {AnyValidation, ValidationModifier} from 'schemaDefinition/types';
import {asAnyValidation} from './asAnyValidation';
import {isNullish} from './isNullish';

/**
 * Modify validations by adding or replacing them with the given validation modifiers.
 */
export const modifyValidations = (
  validation: AnyValidation | AnyValidation[] | undefined,
  validationModifier: ValidationModifier | ValidationModifier[] | undefined,
): AnyValidation | AnyValidation[] | undefined => {
  if (validationModifier === undefined) {
    return validation;
  }

  const original = (
    Array.isArray(validation)
      ? validation
      : isNullish(validation)
        ? []
        : [validation]
  ) as AnyValidation[];

  const modifiers = (
    Array.isArray(validationModifier)
      ? validationModifier
      : [validationModifier]
  ) as ValidationModifier[];

  const filteredOriginal = original.filter(
    v => !v.id || modifiers.every(vm => vm.id !== v.id),
  );

  const merged = [
    ...filteredOriginal,
    ...modifiers.map(vm => asAnyValidation(vm)!),
  ];

  return merged;
};
