import React, {useMemo} from 'react';
import {assert} from 'assert-ts';
import {Concept} from 'types';
import {Data} from 'schemaDefinition/types';
import {WorkMetadata} from 'api';
import {CodeListsGate} from 'services/codeLists';
import {useWorkExports} from 'services/data/metadata/hooks';
import {FlexBox, Spacer} from 'components';
import {Preview, useSchemaCodelistIds} from 'schema';
import {entityTypeToSchemaReference} from 'schemas/functions/entityTypeToSchemaReference';
import {useEntitySchema, usePreviewConfiguration} from 'schemas/hooks';
import {EntityPreviewContent} from './types';
import {ExpressionPreview} from './ExpressionPreview';

export const WorkPreviewContent: EntityPreviewContent = ({
  entityType,
  entityId,
  entityValue,
  configuration,
}) => {
  const schema = useEntitySchema(Concept.work);
  const codeLists = useSchemaCodelistIds(schema);
  const workExports = useWorkExports((entityValue as WorkMetadata).work);
  const configurationWithRelatedScope = useMemo(
    () => ({
      ...assert(configuration, 'WorkPreviewContent: configuration expected'),
      relatedScope: {work: workExports} as Data,
    }),
    [configuration, workExports],
  );
  const mainPreviewConfig = usePreviewConfiguration(
    entityTypeToSchemaReference(Concept.work),
    configurationWithRelatedScope,
  );

  return (
    <CodeListsGate codeLists={codeLists}>
      <FlexBox>
        <Preview
          schema={assert(schema, 'EntityPreview: schema is not defined')}
          data={(entityValue as WorkMetadata)?.work}
          configuration={mainPreviewConfig}
        />
        <Spacer height={2} />
        {((entityValue as WorkMetadata)?.expressions ?? []).map(e => (
          <ExpressionPreview
            key={e.id}
            expression={e}
            manifestations={(entityValue as WorkMetadata).manifestations.filter(
              m => m.expressionId === e.id,
            )}
            expandedManifestationId={
              entityType === 'manifestation' ? entityId : undefined
            }
            configuration={configurationWithRelatedScope}
          />
        ))}
      </FlexBox>
    </CodeListsGate>
  );
};
