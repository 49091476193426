import React, {useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import assert from 'assert-ts';
import {DataLoadStatus, MassUpdate, MassUpdateItemStatus} from 'api/types';
import {Data} from 'schemaDefinition/types';
import {WorkItemLink} from 'services/workItems/types';
import {useSetAdvancedSearchItems} from 'services/advancedSearchItems';
import {useSetWorkItems} from 'services/workItems';
import {Layout, Spacer} from 'components';
import {Preview, Table} from 'schema';
import {useMassUpdateSchema} from 'schemas/schemas/hooks';
import {useMassUpdateCreateSchema} from 'schemas/schemas/hooks/useMassUpdateCreateSchema';
import {useSchema} from '../../schemas';
import {MassUpdateTableHeader} from './MassUpdateTableHeader';

export type GetWorkItemLink<TItem> = (
  item: TItem,
  status: MassUpdateItemStatus,
) => WorkItemLink;

type Props<TItem extends Data = Data> = {
  massUpdate: MassUpdate;
  unprocessed: DataLoadStatus<TItem[]>;
  succeeded: DataLoadStatus<TItem[]>;
  failed: DataLoadStatus<TItem[]>;
  getWorkItemLink?: GetWorkItemLink<TItem>;
};

export function MassUpdatePreviewCore<TItem extends Data = Data>({
  massUpdate,
  unprocessed,
  succeeded,
  failed,
  getWorkItemLink,
}: Props<TItem>): React.ReactElement {
  const navigate = useNavigate();
  const massUpdateCreateSchema = useMassUpdateCreateSchema(massUpdate);
  const massUpdateSchema = useMassUpdateSchema(massUpdate);
  const unprocessedProps = useTableProps(massUpdate, unprocessed);
  const succeededProps = useTableProps(massUpdate, succeeded);
  const failedProps = useTableProps(massUpdate, failed);
  const failedIds = useMemo(
    () => massUpdate.items.failed.map(item => item.id),
    [massUpdate.items.failed],
  );

  const setSearchItems = useSetAdvancedSearchItems();
  const setWorkItems = useSetWorkItems();

  const handleOpenInSearch = useCallback(
    (itemIds: string[]) => {
      setSearchItems({type: massUpdate.type, itemIds});
      navigate(`/search?workIds=${itemIds.join(',')}`);
    },
    [massUpdate.type, navigate, setSearchItems],
  );

  const handleClickItem = useCallback(
    (item: TItem, list: TItem[], status: MassUpdateItemStatus) => {
      const index = list.findIndex(i => i.id === item.id);
      if (index === -1) {
        assert(false, 'Item not found in list', {item});
        return;
      }

      if (getWorkItemLink) {
        const workLinks = list.map(i => getWorkItemLink(i, status));
        const itemLink = workLinks.splice(index, 1);
        setWorkItems(workLinks);
        navigate(itemLink[0].link);
      }
    },
    [getWorkItemLink, navigate, setWorkItems],
  );

  const isNew = massUpdate.status === 'NEW';
  const showOpenInSearch = massUpdate.status === 'COMPLETED';

  return (
    <Layout>
      <Preview
        schema={isNew ? massUpdateCreateSchema : massUpdateSchema}
        data={massUpdate}
      />
      <Spacer height={2} />
      {massUpdate.items.failed.length > 0 ? (
        <>
          <Table
            {...failedProps}
            header={
              <MassUpdateTableHeader
                type={massUpdate.type}
                itemStatus={'FAILED'}
                itemIds={failedIds}
                showOpenInSearch={showOpenInSearch}
                onSearch={handleOpenInSearch}
              />
            }
            onRowClick={
              isNew
                ? undefined
                : item =>
                    handleClickItem(item, failedProps.data ?? [], 'FAILED')
            }
          />
          <Spacer height={2} />
        </>
      ) : null}

      {massUpdate.items.unprocessed.length > 0 ? (
        <>
          <Table
            {...unprocessedProps}
            header={
              <MassUpdateTableHeader
                type={massUpdate.type}
                itemStatus={isNew ? 'NEW' : 'UNPROCESSED'}
                itemIds={massUpdate.items.unprocessed}
                showOpenInSearch={showOpenInSearch}
                onSearch={handleOpenInSearch}
              />
            }
            onRowClick={
              isNew
                ? undefined
                : item =>
                    handleClickItem(
                      item,
                      unprocessed.data ?? [],
                      isNew ? 'NEW' : 'UNPROCESSED',
                    )
            }
          />
          <Spacer height={2} />
        </>
      ) : null}
      {massUpdate.items.failed.length > 0 ? (
        <>
          <Table
            {...succeededProps}
            header={
              <MassUpdateTableHeader
                type={massUpdate.type}
                itemStatus={'SUCCEEDED'}
                itemIds={massUpdate.items.succeeded}
                showOpenInSearch={showOpenInSearch}
                onSearch={handleOpenInSearch}
              />
            }
            onRowClick={
              isNew
                ? undefined
                : item =>
                    handleClickItem(
                      item,
                      succeededProps.data ?? [],
                      'SUCCEEDED',
                    )
            }
          />
          <Spacer height={2} />
        </>
      ) : null}
    </Layout>
  );
}

const useTableProps = <TItem extends Data = Data>(
  massUpdate: MassUpdate,
  works: DataLoadStatus<TItem[]>,
) => {
  const tableSchema = useSchema(massUpdate.type);
  return useMemo(() => {
    return {
      schema: tableSchema,
      loading: works.status === 'Loading',
      data: works.data ?? [],
    };
  }, [tableSchema, works]);
};
