import {useEffect, useMemo} from 'react';
import {AgentBase} from 'api/types';
import {Data} from 'schemaDefinition/types';
import {CodeListsGate} from 'services/codeLists';
import {
  ActionButton,
  CheckboxField,
  Layout,
  Spacer,
  Text,
  useAlertDialog,
} from 'components';
import {useUniqueFieldId} from 'components/fields/hooks/useUniqueFieldId';
import {Wizard} from 'components/wizard';
import {DataFormV1} from 'schema/form';
import {EditAgentWizardProps} from '../types';
import {useLocalization} from '../../../localization';
import {useAgentFormConfiguration} from '../hooks/useAgentFormConfiguration';
import {useEditAgentWizardState} from '../hooks/useEditAgentWizardState';
import {useWizardLabels} from '../hooks/useWizardLabels';
import {SearchAgentResult} from './SearchAgentResult';
import {SearchInput} from './SearchInput';

const empty: Data = {};

export const EditAgentWizard: React.FC<EditAgentWizardProps> = props => {
  const {onCancel} = props;
  const {
    step1Id,
    step2Id,
    currentStep,
    agentType,
    agentSchema,
    codelistIds,
    mode,
    hasNationalId,
    upsertBARE,
    disabledUpsertBARE,
    setUpsertBARE,
    agentQuery,
    search,
    isAutoSearching,
    selection,
    emptyResultAlternative,
    setEmptyResultAlternative,
    initialEditAgent,
    hasChanges,
    showValidationErrors,
    setAgentQuery,
    gotoStep,
    agentEdited,
    onSave,
    onDelete,
  } = useEditAgentWizardState(props, 'full');

  const readonlyData = useMemo<Partial<AgentBase>>(() => {
    return {works: props.workSummaries};
  }, [props.workSummaries]);

  const {t} = useLocalization();
  const {steps, upsertLabel} = useWizardLabels(agentType, mode, hasNationalId);

  const agentFormConfiguration = useAgentFormConfiguration();

  useEffect(() => {
    props.onModeChange && props.onModeChange(mode);
  }, [mode, props]);

  const step1Content = useMemo(
    () => (
      <Layout>
        <Text variant="body2">{steps[0].guide}</Text>
        <Spacer size={1} />
        {/* Search input and search button */}
        <SearchInput
          agentType={agentType}
          value={agentQuery}
          onSetValue={setAgentQuery}
          onSearch={search.doSearch}
        />
        <Spacer size={1} />
        {/* Search result */}
        <SearchAgentResult
          agentType={agentType}
          mode={mode}
          isSearching={isAutoSearching}
          showSearchErrors={search.showSearchErrors}
          searchResult={search.searchResult}
          {...selection}
          emptyResultAlternative={emptyResultAlternative}
          setEmptyResultAlternative={setEmptyResultAlternative}
        />
      </Layout>
    ),
    [
      agentQuery,
      agentType,
      emptyResultAlternative,
      isAutoSearching,
      mode,
      search,
      selection,
      setAgentQuery,
      setEmptyResultAlternative,
      steps,
    ],
  );

  const step2Content = useMemo(
    () => (
      <Layout>
        <DataFormV1
          id={step2Id}
          schema={agentSchema}
          data={initialEditAgent}
          lazyReadonlyData={readonlyData}
          configuration={agentFormConfiguration}
          mode="edit"
          relatedData={empty}
          showErrors={showValidationErrors}
          onDataChanged={agentEdited}
        />
      </Layout>
    ),
    [
      agentEdited,
      agentFormConfiguration,
      agentSchema,
      initialEditAgent,
      readonlyData,
      showValidationErrors,
      step2Id,
    ],
  );

  const checkBoxFieldId = useUniqueFieldId('upsertBARE');

  const confirmDeleteProps = useMemo(
    () => ({
      title: t('page.agents.delete.title'),
      body: t('page.agents.delete.body'),
      cancelTitle: t('general.cancel'),
      okTitle: t('general.ok'),
      onConfirm: onDelete,
      onCancel: () => undefined,
    }),
    [onDelete, t],
  );
  const {AlertDialog: ConfirmDeleteDialog, pleaseConfirm} =
    useAlertDialog(confirmDeleteProps);

  const step2ExtraItem = useMemo(
    () => () => (
      <>
        <Spacer size={4} />
        <ActionButton
          title={'Slett'}
          onClick={() => pleaseConfirm()}
          icon={'Delete'}
          disabled={initialEditAgent.id === undefined}
        />
        <Spacer size={4} />
        <CheckboxField
          fieldId={checkBoxFieldId}
          name="upsertBARE"
          boxLabel={upsertLabel}
          value={upsertBARE}
          variant="button"
          readonly={disabledUpsertBARE}
          onChange={setUpsertBARE}
        />
      </>
    ),
    [
      initialEditAgent.id,
      checkBoxFieldId,
      upsertLabel,
      upsertBARE,
      disabledUpsertBARE,
      setUpsertBARE,
      pleaseConfirm,
    ],
  );

  return (
    <CodeListsGate codeLists={codelistIds}>
      <Wizard
        initialStepId={currentStep}
        title={''}
        showSaveCancel={hasChanges}
        showPrev={true}
        onCancel={onCancel}
        onSave={onSave}
        onGotoStep={gotoStep}
        steps={[
          {
            id: step1Id,
            label: steps[0].label,
            content: step1Content,
          },
          {
            id: step2Id,
            label: steps[1].label,
            content: step2Content,
            extraitem: {
              itemType: 'custom',
              render: step2ExtraItem,
            },
          },
        ]}
      />
      <ConfirmDeleteDialog />
    </CodeListsGate>
  );
};
