import {useCallback} from 'react';
import {useLocalization} from 'localization';
import {DataLoadStatus, ThesaurusNode, useGetResourceById} from 'api';
import {getGrepNodeDetails} from 'api/thesaurus';
import {useGetTokens} from 'services/auth';

export const useGrepNodeDetails = (
  code: string,
): DataLoadStatus<ThesaurusNode> => {
  const getTokens = useGetTokens();
  const {locale} = useLocalization();
  const getResource = useCallback(
    (code: string | undefined) =>
      code
        ? getGrepNodeDetails(code, locale ?? 'nb', getTokens)
        : Promise.reject(),
    [getTokens, locale],
  );
  return useGetResourceById(code, getResource);
};
