/* eslint-disable @typescript-eslint/no-unused-vars */
import {useMemo} from 'react';
import {ColumnWithLabel} from 'api';
import {getFilteredColumns} from '../functions';

/**
 * Filters allColumns based on filter and currentColumns.
 * - no filter/less than 2 characters: returns allColumns
 * - filter: returns allColumns that contains filter, case insensitive,
 *   and also all currentColumns. Sorts columns by:
 *   - columns starting with filter
 *   - columns containing filter
 *   - currentColumns
 * @param filter
 * @param allColumns
 * @param currentColumns
 */
export const useFilteredColumns = (
  filter: string | undefined,
  allColumns: ColumnWithLabel[],
  currentColumns: string[],
): ColumnWithLabel[] => {
  return useMemo(() => {
    if (!filter || filter.length < 2) {
      return allColumns;
    }

    return getFilteredColumns(filter, allColumns, currentColumns);
  }, [filter, allColumns, currentColumns]);
};
