import {
  GetTokens,
  MassUpdate,
  MassUpdateCreate,
  MassUpdateSummary,
  Schemas_MassUpdateCreateDto,
  Schemas_MassUpdateDto,
  Schemas_MassUpdateSummaryDto,
} from './types';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpGet, httpPost} from './http/ebba';
import {mapToMassUpdateCreateDto} from './mappers';
import {mapMassUpdateDto} from './mappers/mapMassUpdateDto';
import {mapMassUpdateSummaryDto} from './mappers/mapMassUpdateSummaryDto';

export const getMassUpdate = (
  id: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<MassUpdate> => {
  return exceptionToPromiseReject(() =>
    // Use MassUpdateDto from Schemas when API is ready.
    httpGet<Schemas_MassUpdateDto>({
      subdir: 'massUpdate/{id}',
      subdirParams: {id},
      mock: !!mock,
      getTokens,
    }).then(mapMassUpdateDto),
  );
};

export const getMassUpdates = (
  getTokens: GetTokens,
  mock?: boolean,
): Promise<MassUpdateSummary[]> => {
  return exceptionToPromiseReject(() =>
    // Use MassUpdateDto from Schemas when API is ready.
    httpGet<Schemas_MassUpdateSummaryDto[]>({
      subdir: 'massUpdate',
      mock: !!mock,
      getTokens,
    }).then(list => list.map(mapMassUpdateSummaryDto)),
  );
};

export const postMassUpdate = (
  newMassUpdate: MassUpdateCreate,
  ids: string[],
  getTokens: GetTokens,
  mock?: boolean,
): Promise<string> => {
  return exceptionToPromiseReject(() =>
    // Use MassUpdateDto from Schemas when API is ready.
    httpPost<string, Schemas_MassUpdateCreateDto>({
      subdir: 'massUpdate',
      body: mapToMassUpdateCreateDto(newMassUpdate, ids),
      mock: !!mock,

      getTokens,
    }),
  );
};
