import React from 'react';
import {useMediaQuery, useTheme} from '@mui/material';
import {MassUpdateEntityType} from 'types';
import {FlexBox, Text} from 'components';

type Props = {
  title: string;
  type?: MassUpdateEntityType;
};

export const MassUpdateTitle: React.FC<Props> = ({title}) => {
  const theme = useTheme();

  const belowMedium = useMediaQuery(theme.breakpoints.down('sm'));

  return belowMedium ? (
    <Text variant="h1">{title}</Text>
  ) : (
    <FlexBox paddingBottom={1} sx={{position: 'relative'}}>
      <FlexBox
        horizontal
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}>
        <Text variant="h1">{title}</Text>
      </FlexBox>
    </FlexBox>
  );
};
