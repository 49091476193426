import {valueReducerFactory} from 'services/utils/redux/valueReducerFactory';
import {AdvancedSearchItemsState} from '../types';

const [
  ADVANCED_SEARCH_ITEMS_KEY,
  advancedSearchItemsReducer,
  setAdvancedSearchItems,
  clearAdvancedSearchItems,
  useAdvancedSearchItems,
] = valueReducerFactory(`advancedSearchItems`, {
  itemIds: [],
} as AdvancedSearchItemsState);

export type AdvancedSearchItemsAction =
  | ReturnType<typeof setAdvancedSearchItems>
  | ReturnType<typeof clearAdvancedSearchItems>;

export {
  ADVANCED_SEARCH_ITEMS_KEY,
  advancedSearchItemsReducer,
  setAdvancedSearchItems,
  clearAdvancedSearchItems,
  useAdvancedSearchItems,
};
