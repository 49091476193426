import {useCallback, useState} from 'react';
import {CurrentColumnsType, ViewMode, ViewModeType} from '../types';

export const useViewMode = (
  initialMode: ViewMode = 'manifestation',
  currentColumns: Pick<
    CurrentColumnsType,
    'currentColumns' | 'addCurrentColumn'
  >,
): ViewModeType => {
  const [viewMode, setViewMode] = useState<ViewMode>(initialMode);
  const handleViewModeChange = useCallback(
    (viewMode: ViewMode) => {
      const {currentColumns: columns, addCurrentColumn} = currentColumns;

      if (viewMode === 'work') {
        if (!columns.includes('workId')) {
          addCurrentColumn('workId');
        }
      }

      if (viewMode === 'manifestation') {
        if (!columns.includes('ean')) {
          addCurrentColumn('ean');
        }
      }

      setViewMode(viewMode);
    },
    [currentColumns],
  );

  return {
    viewMode,
    setViewMode: handleViewModeChange,
  };
};
