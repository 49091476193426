import {LinkEntityType} from 'types';
import {LocalCodeListId} from 'api/types';
import {Code, CodeListId} from './values';

export type LinkRole =
  | 'partOf'
  | 'contains'
  | 'mentions'
  | 'mentionedIn'
  | 'basedOn'
  | 'hasInspired'
  | 'relatedTo'
  | 'hasPseudonym'
  | 'pseudonymFor';

export type RoleEntityCode = `${LinkRole}.${LinkEntityType}`;

export type LinkProperty = {
  name: string;
  type: 'text' | 'int';
};

/**
 * A link is two-way between two entities, and has a role for each entity.
 * When editing one entity, the other entity is the target of the link, and
 * all possible links are derived from all link types where typeA or typeB
 * is the type of the entity being edited.
 */
export type LinkType = {
  typeA: LinkEntityType;
  roleA: LinkRole;
  typeB: LinkEntityType;
  roleB: LinkRole;
  linkProperty?: LinkProperty;
  /** Set to one way (A -> B) if edit only in one direction is supported in API */
  oneWayEdit?: boolean;
};

export type LinkedRoleCode = Code & {
  targetEntity: LinkEntityType;
  role: LinkRole;
  linkProperty?: LinkProperty;
};

export type SpecificLinkedRoleCodeListId =
  | 'realPerson.linkedRole'
  | 'pseudonym.linkedRole';

export const SpecificLinkedRoleCodeListIds: SpecificLinkedRoleCodeListId[] = [
  'realPerson.linkedRole',
  'pseudonym.linkedRole',
];

export type GeneralLinkedRoleCodeListId = `${LinkEntityType}.linkedRole`;

export type LinkedRoleCodeListId =
  | SpecificLinkedRoleCodeListId
  | GeneralLinkedRoleCodeListId;

export type LinkedRoleCodeList = {
  id: LinkedRoleCodeListId;
  codes: LinkedRoleCode[];
};

/**
 * Use this map to ensure all codelist ids are included in AllCodeListIds
 * global/local is used to determine which codelists to load from the API
 * local codeslists are derived from other data
 */

export const AllCodeListIdsScopeMap: Record<
  CodeListId | LocalCodeListId | LinkedRoleCodeListId,
  'global' | 'local'
> = {
  EXPRESSION_ROLE_TYPE: 'global',
  WORK_ROLE_TYPE: 'global',
  WORK_AND_EXPRESSION_ROLE_TYPE: 'global',
  EDITION_TYPE: 'global',
  LANGUAGE: 'global',
  EXPRESSION_CONTENT: 'global',
  COUNTRY: 'global',
  COLOUR: 'global',
  PRODUCT_FORM: 'global',
  PRODUCT_FORM_DETAIL: 'global',
  GENDER: 'global',
  PUBLISHER_STATUS: 'global',
  DISTRIBUTOR_STATUS: 'global',
  EXPRESSION_FORMAT: 'global',
  TITLE_TYPE: 'global',
  BOOK_GROUP: 'global',
  PRODUCT_GROUP: 'global',
  LITERATURE_TYPE: 'global',
  INTELLECTUAL_LEVEL: 'global',
  DEWEY_SOURCE: 'global',
  BIBLE_CONTENT: 'global',
  BIBLE_VERSION: 'global',
  manifestation_role_type: 'global',
  collection_type: 'global',
  work_collection_type: 'global',
  product_form_simple_values: 'global',
  availability_status: 'global',
  task_type: 'global',
  media_sub_type: 'global',
  EDUCATION_LEVEL: 'global',
  EDUCATION_TARGET_GROUP: 'global',
  TEACHING_MATERIALS: 'global',
  FILEFORMAT_VERSION: 'global',
  'work.linkedRole': 'global',
  'expression.linkedRole': 'global',
  'manifestation.linkedRole': 'global',
  'educationalSeries.linkedRole': 'global',
  'series.linkedRole': 'global',
  'otherConnection.linkedRole': 'global',
  'publisherSeries.linkedRole': 'global',
  'agent.linkedRole': 'global',
  'person.linkedRole': 'global',
  'corporation.linkedRole': 'global',
  'event.linkedRole': 'global',
  'publisher.linkedRole': 'global',
  'realPerson.linkedRole': 'global',
  'pseudonym.linkedRole': 'global',
  expression_manifestation: 'local',
  WORK_TITLE_TYPE: 'global',
  UNNAMED_PERSON: 'global',
  WORK_TYPE: 'global',
  DRM: 'global',
  EBOOK_ACCESSIBILITY: 'global',
  AGENT_ROLE: 'global',
  AUTH_METHODS: 'global',
  CATALOGING_STATUS: 'global',
  CULTURE_FUND: 'global',
  DEVICE_SUPPORT: 'global',
  DISTRIBUTOR: 'global',
  MARKETING_STATUS: 'global',
  OPERATING_SYSTEM: 'global',
  OTHER_SYSTEM_REQUIREMENTS: 'global',
  SALES_OUTLET_IDENTIFIER_TYPE: 'global',
  SALES_RESTRICTION_TYPE: 'global',
  SPECIAL_CHARACTERS: 'global',
  LICENSE_TYPE: 'global',
  // Mass update
  massUpdateWorkField: 'global',
  massUpdateAction: 'global',
  massUpdateStatus: 'global',
  massUpdateType: 'global',
};

export const AllGlobalCodeListIds = Object.keys(AllCodeListIdsScopeMap).filter(
  key => AllCodeListIdsScopeMap[key as CodeListId] === 'global',
) as (CodeListId | LinkedRoleCodeListId)[];

export const AllLocalCodeListIds = Object.keys(AllCodeListIdsScopeMap).filter(
  key => AllCodeListIdsScopeMap[key as CodeListId] === 'local',
) as LocalCodeListId[];
