import assert from 'assert-ts';
import {Concept, MassUpdateEntityType} from 'types';
import {Schemas_MassUpdateDto} from '../types';

export const mapMassUpdateTypeDto = (
  dto: Schemas_MassUpdateDto['field']['type'],
): MassUpdateEntityType => {
  if (dto === 'work') {
    return Concept.work;
  }
  if (dto === 'manifestation') {
    return Concept.manifestation;
  }

  assert(false, 'mapMassUpdateTypeDto: Expected type', dto);
};
