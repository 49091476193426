import React, {useCallback} from 'react';
import {CurrentFilterType} from 'api/types';
import {TextField} from 'components';

type Props = {
  filter: CurrentFilterType;
  onChange: (filter: CurrentFilterType) => void;
  onSubmit?: () => void;
  customDelimiters?: string[];
  disabled?: boolean;
};
export const FilterTextValue: React.FC<Props> = ({
  filter,
  onChange,
  onSubmit,
  customDelimiters,
  disabled,
}) => {
  const handleSubmit = useCallback(
    (e: React.KeyboardEvent) => {
      if (onSubmit && e.code === 'Enter') {
        onSubmit();
      }
    },
    [onSubmit],
  );

  const addCustomDelimiter = useCallback(
    (originalValue: string) => {
      let value = originalValue;
      if (customDelimiters) {
        customDelimiters.forEach(delimiter => {
          value = value.replaceAll(delimiter, ';');
        });
      }

      return value;
    },

    [customDelimiters],
  );

  return (
    <TextField
      type={'text'}
      name={filter.name}
      value={filter.values?.join(';')}
      onChange={e =>
        onChange({
          ...filter,
          values: addCustomDelimiter(e.target.value).split(';'),
        })
      }
      readonly={disabled}
      onKeyUp={handleSubmit}
    />
  );
};
