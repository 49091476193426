import {
  ThesaurusNodeDetailsTextValue,
  ThesaurusNodeDetailsValue,
} from 'api/types';

export const isTextValue = (
  value: ThesaurusNodeDetailsValue,
): value is ThesaurusNodeDetailsTextValue => {
  const textValue = value as ThesaurusNodeDetailsTextValue;
  return textValue.type === 'text' || textValue.type === 'textarea';
};
