import {Feature} from './types';

type Env = 'local' | 'dev' | 'stage' | 'prod';

declare global {
  interface Window {
    envs: {
      ENVIRONMENT: Env;
      DOMAIN: string;
      BASE_URI: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      cognitoDomain: string;
      cognitoUserpoolClientId: string;
      cognitoResponseType: string;
      cognitoScope: string;
      cognitoIdentityProvider: string;
      cognitoUserPoolRegion: string;
      cognitoUserPoolName: string;
      cognitoUserPoolId: string;
      searchUri: string;
      searchIndexCatalog: string;
      searchIndexAgent: string;
      searchIndexCollection: string;
      BB_SEARCH_URL: string;
      BB_HISTORY_URL: string;
      bstEbookDownloadUrl: string;
      INTERVAL_LOCK: number;
      INTERVAL_HEALTHCHECK: number;
    };
  }
}

/**
 * Failure rate pr API subdir and method. If no entry, mock call will be successful
 */
const FailureRateConfiguration = {
  seed: 1,
  work: {
    // get: 2,
    put: 2,
  },
  expression: {
    put: 1,
  },
  manifestation: {
    put: 2,
    post: 2,
  },
  changerequest: {
    work: {
      // get: 1,
    },
  },
  note: {
    // get: 1,
    post: 2,
    put: 2,
    delete: 2,
  },
  agent: {
    search: {
      person: {
        post: 1,
      },
    },
  },
};

// 'off' => 'local' => 'dev' => 'prod'
export type FeatureToggles = {[key in Feature]: 'off' | Env};

export const featureToggles: FeatureToggles = {
  loadThesaurusesFromApi: 'prod',
  shortcutTooltip: 'prod',
  receiveRoute: 'prod',
  advancedSearch: 'prod',
  unnamedAgent: 'prod',
  statementOfResponsibility: 'prod',
  publishedYearNotPrintedInBook: 'prod',
  unnumberedPages: 'prod',
  agentSearchV2: 'prod',
  collectionSearchV2: 'prod',
  massUpdate: 'dev',
};

export const Configuration = {
  env: window.envs.ENVIRONMENT,
  featureToggles,
  app: {
    domain: window.envs.DOMAIN as string,
    bstEbookDownloadUrl: window.envs.bstEbookDownloadUrl as string,
  },
  api: {
    baseUri: window.envs.BASE_URI as string,
    mock: {
      enabled: false,
      failure: FailureRateConfiguration,
    },
  },
  searchApi: {
    elastic: {
      uri: window.envs.searchUri as string,
      indexes: {
        catalog: window.envs.searchIndexCatalog as string,
        agent: window.envs.searchIndexAgent as string,
        collection: window.envs.searchIndexCollection as string,
      },
    },
    mock: {
      enabled: false,
      failure: FailureRateConfiguration,
    },
  },
  sentry: {
    /** Default true */
    enabled: window.envs.SENTRY_ENABLED === false ? false : true,
    dsn: window.envs.SENTRY_DSN as string,
  },
  user: {
    cognitoUserpoolClientId: window.envs.cognitoUserpoolClientId as string,
    cognitoResponseType: window.envs.cognitoResponseType as string,
    cognitoDomain: window.envs.cognitoDomain as string,
    cognitoLoginCallback: `${window.envs.DOMAIN}/login/callback` as string,
    cognitoLogoutCallback: `${window.envs.DOMAIN}/logout/callback` as string,
    cognitoScope: window.envs.cognitoScope as string,
    cognitoIdentityProvider: window.envs.cognitoIdentityProvider as string,
    cognitoUserPoolRegion: window.envs.cognitoUserPoolRegion as string,
    cognitoUserPoolName: window.envs.cognitoUserPoolName as string,
    cognitoUserPoolId: window.envs.cognitoUserPoolId as string,
  },
  bb_app: {
    search: window.envs.BB_SEARCH_URL,
    history: window.envs.BB_HISTORY_URL,
  },
  interval: {
    lock: window.envs.INTERVAL_LOCK,
    healthcheck: window.envs.INTERVAL_HEALTHCHECK,
  },
};
