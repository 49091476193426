import React from 'react';
import {useNavigate} from 'react-router-dom';
import {MassUpdate} from 'api/types';
import {useLocalization} from 'localization';
import {FlexBox, Scene, Spacer} from 'components';
import {MassUpdatePreview} from 'widgets';
import {useMassUpdateTitle} from './hooks';

type Props = {
  massUpdate: MassUpdate;
};

export const MassUpdateDetailsSceneCore: React.FC<Props> = ({massUpdate}) => {
  const {t} = useLocalization();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();

  const itemCount =
    massUpdate.items.unprocessed.length +
    massUpdate.items.succeeded.length +
    massUpdate.items.failed.length;
  const title = useMassUpdateTitle(massUpdate.type, itemCount);

  // const handleCancel = useCallback(() => {
  //   navigate('/massUpdate');
  // }, [navigate]);

  return (
    <Scene browserTitle={t('appbar.massUpdate')}>
      <Scene.Header title={title} />
      <Scene.Content loadStatus={'Loaded'}>
        <FlexBox sx={{maxWidth: 1200}}>
          {/* TODO: Should be handled by Scene layout */}
          <Spacer height={3} />
          <MassUpdatePreview massUpdate={massUpdate} />
        </FlexBox>
      </Scene.Content>
    </Scene>
  );
};
