import React, {PropsWithChildren, useCallback, useEffect, useMemo} from 'react';
import {ContainerChild} from 'components/types';
import {useBrowserTabTitle} from 'services/head/hooks/useBrowserTabTitle';
import {RightPanelConfig} from './types';
import {SceneContainer} from './SceneContainer';
import {SceneContentName} from './SceneContent';
import {SceneHeaderName} from './SceneHeader';
import {SceneRightPanelName} from './SceneRightPanel';
import {SceneSubMenuName} from './SceneSubMenu';
import {
  SceneSizeContext,
  useSceneSizeContextProvider,
} from './context/SceneSizeContext';

type Props = {
  browserTitle?: string;
  rightPanelConfig?: RightPanelConfig;
};

export const Scene: React.FC<PropsWithChildren<Props>> = ({
  browserTitle,
  rightPanelConfig,
  children,
}) => {
  const {setBrowserTabTitle} = useBrowserTabTitle();

  useEffect(() => {
    if (browserTitle) {
      setBrowserTabTitle(browserTitle);
    }
  }, [setBrowserTabTitle, browserTitle]);

  const components = useMemo(
    () => React.Children.toArray(children) as unknown as ContainerChild[],
    [children],
  );

  const getComponentWithName = useCallback(
    (name: string): React.ReactNode | null => {
      const comps = components.filter(
        c => c && c.type && c.type.displayName === name,
      );
      return comps.length > 0 ? comps[0] : null;
    },
    [components],
  );

  const Header = getComponentWithName(SceneHeaderName);
  const SubMenu = getComponentWithName(SceneSubMenuName);
  const Content = getComponentWithName(SceneContentName);
  const RightPanel = getComponentWithName(SceneRightPanelName);

  const hasRightPanel = RightPanel !== null;

  const sceneSizeContextProvder = useSceneSizeContextProvider(
    hasRightPanel,
    rightPanelConfig,
  );

  return (
    <SceneSizeContext.Provider value={sceneSizeContextProvder}>
      <SceneContainer
        header={Header}
        subMenu={SubMenu}
        content={Content}
        rightPanel={RightPanel}
      />
    </SceneSizeContext.Provider>
  );
};
