import React from 'react';
import {Box} from '@mui/material';
import {MassUpdate, MassUpdateItemStatus} from 'api/types';
import {useLocalization} from 'localization';
import {IconButton, Text} from 'components';

type Props = Pick<MassUpdate, 'type'> & {
  itemStatus: MassUpdateItemStatus;
  itemIds: string[];
  showOpenInSearch: boolean;
  onSearch: (itemIds: string[]) => void;
};

export const MassUpdateTableHeader: React.FC<Props> = ({
  type,
  itemStatus,
  itemIds,
  showOpenInSearch,
  onSearch,
}) => {
  const {t} = useLocalization();
  return (
    <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
      <Box>
        <Text variant={'body1'}>
          {t(`page.massUpdate.details.${type}.${itemStatus}.title`, {
            count: itemIds.length,
          })}
        </Text>
      </Box>
      <Box>
        {showOpenInSearch ? (
          <IconButton
            icon={'Search'}
            size={'small'}
            onClick={() => onSearch(itemIds)}
            postText={<Text variant={'body1'}>{'Åpne i søk'}</Text>}
          />
        ) : null}
      </Box>
    </Box>
  );
};
