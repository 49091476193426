import {
  Reducer,
  applyMiddleware,
  combineReducers,
  legacy_createStore,
} from 'redux';
import {PersistConfig, persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {thunk} from 'redux-thunk';
import {
  ADVANCED_SEARCH_ITEMS_KEY,
  advancedSearchItemsReducer,
} from 'services/advancedSearchItems';
import {authReducer} from 'services/auth';
import {codeListsReducer} from 'services/codeLists';
import {
  metadataEditReducer,
  metadataReducer,
} from 'services/data/metadata/reducer';
import {headReducer} from 'services/head';
import {
  MASS_UPDATE_ITEMS_KEY,
  massUpdateItemsReducer,
} from 'services/massUpdateItems';
import {RECEIVED_ITEMS_KEY, receivedItemsReducer} from 'services/receiveItems';
import {settingsReducer} from 'services/settings';
import {thesaurusesReducer} from 'services/thesaurus';
import {usersReducer} from 'services/users';
import {WORK_ITEMS_KEY, workItemsReducer} from 'services/workItems';
import {AppActions, AppState, AppStateKey} from './types';

export const configureStore = () => {
  // Configure persistence
  const whitelist: Array<AppStateKey> = [
    'settings',
    'users',
    MASS_UPDATE_ITEMS_KEY,
    WORK_ITEMS_KEY,
  ];

  type AppStateNever = Record<AppStateKey, never>;

  const persistConfig: PersistConfig<Partial<AppStateNever>> = {
    key: 'redux-store',
    storage,
    whitelist,
  };

  // Set up reducers
  // - ensure consistency in naming and typing
  const reducers: {
    [key in AppStateKey]: (
      state: AppState[key] | undefined,
      action: AppActions[key],
    ) => AppState[key];
  } = {
    auth: authReducer,
    settings: settingsReducer,
    head: headReducer,
    users: usersReducer,
    codeLists: codeListsReducer,
    thesauruses: thesaurusesReducer,
    metadata: metadataReducer,
    metadataEdit: metadataEditReducer,
    [RECEIVED_ITEMS_KEY]: receivedItemsReducer,
    [MASS_UPDATE_ITEMS_KEY]: massUpdateItemsReducer,
    [ADVANCED_SEARCH_ITEMS_KEY]: advancedSearchItemsReducer,
    [WORK_ITEMS_KEY]: workItemsReducer,
  };

  const rootReducer = combineReducers(reducers) as unknown as Reducer<
    Partial<AppStateNever>
  >;

  // Create persisted reducer
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  // Create store.
  const store = legacy_createStore(persistedReducer, applyMiddleware(thunk));

  const persistor = persistStore(store);

  return {store, persistor};
};
