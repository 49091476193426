import React, {
  CSSProperties,
  FocusEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Box, IconButton, SxProps, Theme} from '@mui/material';
import {
  DragDropContext,
  DragUpdate,
  Draggable,
  DraggingStyle,
  DropResult,
  Droppable,
  NotDraggingStyle,
  SensorAPI,
} from 'react-beautiful-dnd';
import {
  Cardinality,
  FieldRequiredSimple,
  PartName,
} from 'schemaDefinition/types';
import {noOp} from 'services/utils';
import {hasMaxItems} from 'schema/form/functions/hasMaxItems';
import {BaseFieldProps, ContentLabelWithLayout} from './types';
import {useStaticAlertDialogAsPromise} from '../alertdialog';
import {Icon} from '../icons';
import {FlexBox, Layout} from '../layout';
import {Spacer} from '../spacer';
import {EmptyListPlaceholder} from './EmptyListPlaceholder';
import {FieldErrorMessage} from './FieldErrorMessage';
import {FieldLabel} from './FieldLabel';
import {FieldLayout} from './FieldLayout';
import {ListActionButton} from './ListActionButton';
import {useNextFocus, useStableIds} from './hooks';

type SingleValueListFieldProps<T> = BaseFieldProps & {
  addLabel?: string;
  deleteAllStrings?: {
    label?: string;
    confirm: {
      title?: string;
      okTitle?: string;
      cancelTitle?: string;
    };
  };
  /** Will not show label when contentLabels are provided */
  contentLabels?: ContentLabelWithLayout[];
  /** Color for item container, including both move/delete buttons and fields */
  itemBackgroundColor?: string;
  /** Padding for item container */
  itemPadding?: string | number;
  /**
   * Number of hidden items to add as padding, for this list
   * to align with compared list when comparing two lists
   */
  valuePaddingSize?: number;
  name: PartName;
  required?: FieldRequiredSimple;
  cardinality?: Cardinality;
  values: T[] | undefined;
  getValueKey: (index: number, value: T) => string;
  renderValue: (
    index: number,
    value: T,
    id: string,
    readonly: boolean,
    showWhenReadonlyAndEmpty: boolean,
  ) => React.ReactNode;
  renderCustomActions?: () => React.ReactNode;
  onDeleteValue?: (index: number) => T[];
  onAppendValue?: () => T[];
  onDeleteAll?: () => T[];
  onMoveValue?: (oldIndex: number, newIndex: number) => T[];
  /**
   * Create a new value for padding of list when comparing two lists
   */
  onCreateValue?: () => T;
  onBlur?: FocusEventHandler<HTMLDivElement> | undefined;
};

type PlaceholderProps = {
  clientHeight?: number | string;
  clientWidth?: number | string;
  marginBottom?: number | string;
  marginTop?: number | string;
  clientY?: number | string;
  clientX?: number | string;
};

const grid = 8;

const getItemStyle = (
  _isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
): CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,
  ...draggableStyle,
});

const buttonSx: SxProps<Theme> = {
  alignSelf: 'flex-start',
};

const dragContainerSx: SxProps<Theme> = {
  alignSelf: 'flex-start',
  ':focus-visible': {
    outline: 'none',
  },
};

const placeholderLayoutSx = {maxHeight: '1px'} as const;

export function SingleValueListField<T>({
  label,
  contentLabels,
  addLabel,
  deleteAllStrings,
  itemBackgroundColor,
  itemPadding,
  valuePaddingSize,
  name,
  required = false,
  error,
  errorMessage,
  cardinality,
  values = [],
  readonly = false,
  showWhenReadonlyAndEmpty = false,
  getValueKey,
  width,
  maxWidth,
  flex,
  renderValue,
  renderCustomActions,
  onAppendValue,
  onDeleteValue,
  onDeleteAll,
  onMoveValue,
  onCreateValue,
  onBlur,
  'data-cy': dataCy,
}: SingleValueListFieldProps<T>) {
  const [placeholderProps, setPlaceholderProps] = useState<PlaceholderProps>(
    {},
  );

  const stableIds = useStableIds(values);

  const getElementId = useCallback(
    (subKey: string, idx: number) => {
      return getValueKey(idx, values[idx]);
    },
    [getValueKey, values],
  );

  const {scopeRef, setNextFocusId} = useNextFocus();

  const sensorAPIRef = useRef<SensorAPI>();

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      setPlaceholderProps({});
      if (onMoveValue) {
        stableIds.moveValue(result.source.index, result.destination.index);
        onMoveValue(result.source.index, result.destination.index);
      }
    },
    [onMoveValue, stableIds],
  );

  const queryAttr = 'data-rbd-drag-handle-draggable-id';

  const onDragUpdate = useCallback((update: DragUpdate) => {
    if (!update.destination) {
      return;
    }
    const draggableId = update.draggableId;
    const destinationIndex = update.destination.index;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector<HTMLDivElement>(domQuery);

    if (!draggedDOM) {
      return;
    }
    const {clientHeight, clientWidth, style} = draggedDOM;
    const {marginBottom, marginTop} = style;

    const clientY =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      [...draggedDOM.parentNode.children]
        .slice(0, destinationIndex)
        .reduce((total, curr) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      marginBottom,
      marginTop,
      clientY,
      clientX: parseFloat(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft,
      ),
    });
  }, []);

  const ignoreNextAddBlurRef = useRef(false);

  const {
    pleaseConfirm: confirmDeleteAll,
    AlertDialog: DeleteAllDialog,
    isOpen: isDeleteAllOpen,
    isClosing: isDeleteAllClosing,
  } = useStaticAlertDialogAsPromise({
    title: deleteAllStrings?.confirm?.title ?? 'Missing: Slett alle?',
    okTitle: deleteAllStrings?.confirm?.okTitle ?? 'Missing: Slett',
    cancelTitle: deleteAllStrings?.confirm?.cancelTitle ?? 'Missing: Avbryt',
  });

  // Used to keep track of change of values outside of this component,
  // e.g. when work data is reset, to enable resetting of stableIds
  const lastEditedValuesRef = useRef<T[]>(values);

  const handleAppendValue = useCallback(() => {
    if (onAppendValue) {
      stableIds.appendValue();
      lastEditedValuesRef.current = onAppendValue();
      // Ignore blur of add button when setting focus on input field in list
      // to avoid indicating error/warning while adding first element to list
      ignoreNextAddBlurRef.current = true;
      setNextFocusId(getElementId('innerField', values.length));
    }
  }, [getElementId, onAppendValue, setNextFocusId, stableIds, values.length]);

  const handleDeleteAll = useCallback(() => {
    if (onDeleteAll) {
      confirmDeleteAll().then(confirmed => {
        if (confirmed) {
          stableIds.deleteAll();
          lastEditedValuesRef.current = onDeleteAll();
        }
      });
    }
  }, [confirmDeleteAll, onDeleteAll, stableIds]);

  const handleAddBlur = useCallback(
    (e: React.FocusEvent<HTMLDivElement>) => {
      if (!ignoreNextAddBlurRef.current) {
        onBlur && onBlur(e);
      }

      ignoreNextAddBlurRef.current = false;
    },
    [onBlur],
  );

  const handleDeleteValue = useCallback(
    (index: number) => {
      if (onDeleteValue) {
        stableIds.deleteValue(index);
        lastEditedValuesRef.current = onDeleteValue(index);
        setNextFocusId(getElementId('addDeleteBtn', index));
      }
    },
    [getElementId, onDeleteValue, setNextFocusId, stableIds],
  );

  useEffect(() => {
    // Assumes values returned from callbacks for add, delete, move, delete all
    // are the same as the values passed to the component, i.e.
    // for the reference comparison to work. Otherwise, deep comparison is needed.
    if (values !== lastEditedValuesRef.current) {
      lastEditedValuesRef.current = values;
      stableIds.resetIds(values);
    }
  }, [label, stableIds, values]);

  const canMove = !readonly && onMoveValue !== undefined && values.length > 1;
  const canDelete =
    !readonly &&
    onDeleteValue !== undefined &&
    (required !== true || values.length > 1);
  const canAdd =
    !readonly && !hasMaxItems(values, cardinality) && !!onAppendValue;
  const canDeleteAll =
    !readonly && onDeleteAll !== undefined && values.length > 1;

  const spacedContentLabelsWithLayout = useMemo(() => {
    return contentLabels
      ? contentLabels.reduce<(ContentLabelWithLayout | null)[]>(
          (acc, cl, idx) => {
            idx === 0 ? acc.push(cl) : acc.push(null, cl);
            return acc;
          },
          [],
        )
      : undefined;
  }, [contentLabels]);

  const sxItem = useMemo(
    () =>
      ({
        background: itemBackgroundColor,
        padding: itemPadding,
        borderRadius: itemPadding,
      }) as const,
    [itemBackgroundColor, itemPadding],
  );

  const divStyle = useMemo(
    () => ({flex, width, maxWidth}),
    [flex, width, maxWidth],
  );

  const placeholderDivStyle = useMemo(
    () =>
      ({
        position: 'absolute',
        top: placeholderProps.clientY,
        left: placeholderProps.clientX,
        height: placeholderProps.clientHeight,
        width: placeholderProps.clientWidth,
        marginBottom: placeholderProps.marginBottom,
        marginTop: placeholderProps.marginTop,
      }) as const,
    [placeholderProps],
  );

  const valuesWithPadding = useMemo(() => {
    const paddingItems =
      (valuePaddingSize ?? 0) > 0
        ? new Array(valuePaddingSize).fill(onCreateValue?.() ?? null)
        : [];
    return [...values, ...paddingItems];
  }, [onCreateValue, valuePaddingSize, values]);

  // Only indicate error on add button if no values
  const addError = values.length > 0 ? false : error;

  return (
    <div ref={scopeRef} style={divStyle} data-cy={dataCy ?? `field-${name}`}>
      {/* Label */}
      {spacedContentLabelsWithLayout === undefined ? (
        <Layout horizontal>
          {label ? (
            <FieldLabel label={label} required={required} error={error} />
          ) : null}
          <Spacer width={2} />
          <FieldErrorMessage error={error} errorMessage={errorMessage} />
        </Layout>
      ) : valuesWithPadding && valuesWithPadding.length > 0 ? (
        // Content Labels
        <Layout horizontal px={itemPadding}>
          {canMove ? (
            // Placeholder for the space taken by the drag handle, if any
            <Layout hidden sx={placeholderLayoutSx}>
              <IconButton size="small" onClick={noOp}>
                <Icon icon={'Drag'} />
              </IconButton>
            </Layout>
          ) : null}
          <Layout
            horizontal
            marginLeft={canMove ? 1 : 0}
            marginRight={canDelete ? 1 : 0}
            flex={1}>
            {spacedContentLabelsWithLayout.map((l, idx) =>
              l === null ? (
                <Spacer key={idx} size={2} />
              ) : (
                <Layout
                  horizontal
                  adjustLeft
                  key={idx}
                  flex={l.flex}
                  alignItems={l.alignItems}
                  width={l.width}>
                  {l.label ? (
                    <FieldLabel
                      key={l.label}
                      label={l.label}
                      required={l.required}
                      error={l.error}
                    />
                  ) : null}
                </Layout>
              ),
            )}
          </Layout>
          {canDelete ? (
            // Placeholder for space taken by delete button, if any
            <Layout hidden sx={placeholderLayoutSx}>
              <IconButton size="small" onClick={noOp}>
                <Icon icon={'Delete'} />
              </IconButton>
            </Layout>
          ) : null}
        </Layout>
      ) : null}
      {readonly ? (
        <FieldLayout tight>
          {valuesWithPadding && valuesWithPadding.length > 0 ? (
            valuesWithPadding.map((value, idx) => {
              const itemKey = getElementId('container', idx); // getValueKey(value, idx);
              return (
                <Layout key={itemKey} horizontal alignItems={'center'} pb={1}>
                  <Layout flex={1}>
                    {renderValue(
                      idx,
                      value,
                      getElementId('innerField', idx),
                      readonly,
                      showWhenReadonlyAndEmpty,
                    )}
                  </Layout>
                </Layout>
              );
            })
          ) : (
            <EmptyListPlaceholder />
          )}
        </FieldLayout>
      ) : (
        <Layout>
          {/* TODO: Fix typing */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <DragDropContext
            sensors={[
              sensor => {
                sensorAPIRef.current = sensor;
              },
            ]}
            onDragEnd={onDragEnd}
            onDragUpdate={onDragUpdate}>
            {/* TODO: Fix typing */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Droppable droppableId="droppable">
              {droppableProvided => (
                <div
                  {...droppableProvided.droppableProps}
                  ref={droppableProvided.innerRef}>
                  <>
                    {(valuesWithPadding ?? []).map((value, idx) => {
                      const itemKey = getElementId('container', idx); // getValueKey(value, idx);
                      return (
                        //TODO: Fix typing
                        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        <Draggable
                          key={itemKey}
                          draggableId={itemKey}
                          // Disable dragging of padding items
                          isDragDisabled={!canMove || idx >= values.length}
                          index={idx}
                          data-cy={`item-${idx}`}>
                          {(draggableProvided, _snapshot) => (
                            <div
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              style={getItemStyle(
                                _snapshot.isDragging,
                                draggableProvided.draggableProps.style,
                              )}
                              tabIndex={undefined}>
                              <Layout
                                key={itemKey}
                                horizontal
                                alignItems={'center'}
                                sx={sxItem}>
                                {canMove ? (
                                  <Box
                                    {...draggableProvided.dragHandleProps}
                                    tabIndex={-1}
                                    sx={dragContainerSx}>
                                    <IconButton
                                      id={getElementId('dragBtn', idx)}
                                      key={getElementId('dragBtn', idx)}
                                      color="primary"
                                      sx={buttonSx}
                                      size="small"
                                      onClick={noOp}>
                                      <Icon icon={'Drag'} />
                                    </IconButton>
                                  </Box>
                                ) : null}
                                <Layout
                                  marginLeft={canMove ? 1 : 0}
                                  marginRight={canDelete ? 1 : 0}
                                  flex={1}>
                                  {renderValue(
                                    idx,
                                    value,
                                    getElementId('innerField', idx),
                                    readonly,
                                    showWhenReadonlyAndEmpty,
                                  )}
                                </Layout>
                                {canDelete ? (
                                  <IconButton
                                    data-cy={'button-delete'}
                                    id={getElementId('addDeleteBtn', idx)}
                                    key={getElementId('addDeleteBtn', idx)}
                                    color="primary"
                                    size="small"
                                    sx={buttonSx}
                                    onClick={() => handleDeleteValue(idx)}>
                                    <Icon icon={'Delete'} />
                                  </IconButton>
                                ) : null}
                              </Layout>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {droppableProvided.placeholder}
                  </>
                  {/* <CustomPlaceholder snapshot={snapshot} /> */}
                  <div style={placeholderDivStyle} />
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {renderCustomActions ? (
            <Layout
              horizontal
              adjustCenter
              justifyContent={'space-between'}
              px={itemPadding}>
              <FlexBox horizontal center>
                {canAdd ? (
                  <ListActionButton
                    icon="Add"
                    label={addLabel}
                    id={getElementId('addDeleteBtn', values.length)}
                    error={addError}
                    dataCy="add-item"
                    onBlur={handleAddBlur}
                    onClick={handleAppendValue}
                  />
                ) : null}
                {renderCustomActions()}
              </FlexBox>
              {canDeleteAll ? (
                <ListActionButton
                  label={deleteAllStrings?.label}
                  icon="Delete"
                  iconPosition="right"
                  id={getElementId('deleteAllBtn', values.length)}
                  dataCy="add-item"
                  onClick={handleDeleteAll}
                />
              ) : (
                <FlexBox />
              )}
            </Layout>
          ) : canAdd || canDeleteAll ? (
            <FlexBox horizontal center spaceBetween>
              {canAdd ? (
                <ListActionButton
                  icon="Add"
                  label={addLabel}
                  id={getElementId('addDeleteBtn', values.length)}
                  dataCy="add-item"
                  error={addError}
                  onBlur={handleAddBlur}
                  onClick={handleAppendValue}
                />
              ) : null}
              {canDeleteAll ? (
                <ListActionButton
                  label={deleteAllStrings?.label}
                  icon="Delete"
                  iconPosition="right"
                  id={getElementId('deleteAllBtn', values.length)}
                  dataCy="add-item"
                  onClick={handleDeleteAll}
                />
              ) : (
                <FlexBox />
              )}
            </FlexBox>
          ) : null}
        </Layout>
      )}
      <DeleteAllDialog
        isOpen={isDeleteAllOpen}
        isClosing={isDeleteAllClosing}
      />
    </div>
  );
}
