import {ShortcutCommand, ShortcutCommandGroup} from '../types';

export const getGroupByCommand = (
  command: ShortcutCommand,
): ShortcutCommandGroup => {
  const global: ShortcutCommand[] = ['goToOverview'];
  const overview: ShortcutCommand[] = [
    'showNew',
    'showPre',
    'showControl',
    'showChanged',
  ];
  const cataloguing: ShortcutCommand[] = [
    'save',
    'undo',
    'goToNext',
    'contextSearch',
    'expandAllManifestations',
    'collapseAllManifestations',
    'expandAllChangeRequests',
    'collapseAllChangesRequests',
    'toggleWork',
  ];

  const search: ShortcutCommand[] = ['focusColumnsFilter'];

  if (global.includes(command)) {
    return 'global';
  }

  if (overview.includes(command)) {
    return 'overview';
  }

  if (cataloguing.includes(command)) {
    return 'cataloguing';
  }

  if (search.includes(command)) {
    return 'search';
  }

  return 'uncategorized';
};
