import React from 'react';
import {useParams} from 'react-router-dom';
import {CodeListsGate} from 'services/codeLists';
import {useAllCodeListIds} from 'services/codeLists/hooks/useAllCodeListIds';
import {useMassUpdate} from 'services/data';
import {AllThesaurusIds, ThesaurusesGate} from 'services/thesaurus';
import {Scene} from 'components';
import {MassUpdateDetailsSceneCore} from './MassUpdateDetailsSceneCore';

export const MassUpdateDetailsScene: React.FC = () => {
  const codelistIds = useAllCodeListIds();
  const {massUpdateId} = useParams();
  const {status, data} = useMassUpdate(massUpdateId ?? 'missing massUpdateId');

  return (
    <ThesaurusesGate thesauruses={AllThesaurusIds}>
      <CodeListsGate codeLists={codelistIds}>
        {status === 'NotLoaded' ||
        status === 'Loading' ||
        status === 'Failed' ? (
          <Scene>
            <Scene.Header title="" />
            <Scene.Content
              loadStatus={status}
              error={
                status === 'Failed' ? 'Failed to load mass update' : undefined
              }
            />
          </Scene>
        ) : (
          <MassUpdateDetailsSceneCore massUpdate={data!} />
        )}
      </CodeListsGate>
    </ThesaurusesGate>
  );
};
