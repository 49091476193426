import last from 'lodash/last';
import {Code, CodeList, CodeListRef} from 'api/types';
import {ManifestationImportProps} from 'services/data/metadata/types';
import {isAudioProductForm} from './isAudioProductForm';

type Enabler = (code: Code) => boolean;

const isAudio: Enabler = (code: Code) =>
  last(code.code) === '2' ||
  code.code === CodeListRef.PRODUCT_GROUP['Astronomi lydbøker'] ||
  code.code === CodeListRef.PRODUCT_GROUP['Kryssordbøker lydbøker'];

const isEbook: Enabler = (code: Code) =>
  last(code.code) === '1' ||
  code.code === CodeListRef.PRODUCT_GROUP['Astronomi ebøker'] ||
  code.code === CodeListRef.PRODUCT_GROUP['Kryssordbøker ebøker'] ||
  code.code === CodeListRef.PRODUCT_GROUP['Tidsskrifter'];

const isNotDigital: Enabler = (code: Code) =>
  (!isAudio(code) && !isEbook(code)) ||
  // Tidsskrifter can be both ebook and book
  code.code === CodeListRef.PRODUCT_GROUP['Hobbybøker'] ||
  code.code === CodeListRef.PRODUCT_GROUP['Tidsskrifter'];

const isSkolelbøker: Enabler = (code: Code) => code.code[0] === '1';
const isFaglAndHigherEdLitt: Enabler = (code: Code) => code.code[0] === '2';
const isFagOnlyLitt: Enabler = (code: Code) => code.code[0] === '3';
const isSkjønnlitt: Enabler = (code: Code) => code.code[0] === '4';

const isBookProductGroup: Enabler = (code: Code) => {
  return (
    ['1', '2', '3', '4'].includes(code.code[0]) ||
    code.code === CodeListRef.PRODUCT_GROUP['Hobbybøker'] ||
    code.code === CodeListRef.PRODUCT_GROUP['Tidsskrifter']
  );
};
const isMerchandiseProductGroup: Enabler = (code: Code) =>
  !isBookProductGroup(code);

export const getRestrictedAndSortedProductGroupCodelist = (
  {
    work: {type, literatureType},
    manifestation: {productForm},
  }: ManifestationImportProps,
  outerCodelist: CodeList,
): CodeList => {
  const enablerAndSort: {
    /** All enablers must return true for code to be included */
    enablers: Enabler[];
    first?: Enabler;
  } = {
    enablers: [],
  };

  if (type === CodeListRef.WORK_TYPE.Vare) {
    enablerAndSort.first = isMerchandiseProductGroup;
  } else {
    if (productForm) {
      // Lydbok
      if (isAudioProductForm(productForm)) {
        enablerAndSort.enablers = [isAudio, isBookProductGroup];
      }
      // E-bok
      else if (productForm === CodeListRef.PRODUCT_FORM.ED) {
        enablerAndSort.enablers = [isEbook, isBookProductGroup];
      }
      // Else, others
      else {
        enablerAndSort.enablers = [isNotDigital, isBookProductGroup];
      }
    }

    if (literatureType && literatureType.length > 0) {
      // Fag & lærebøker?
      if (
        [
          CodeListRef.LITERATURE_TYPE.Faglitteratur,
          CodeListRef.LITERATURE_TYPE['Lærebøker (høyere utd.)'],
        ].every(littType => literatureType.includes(littType))
      ) {
        enablerAndSort.first = isFaglAndHigherEdLitt;
      }
      // Fag only?
      else if (
        [CodeListRef.LITERATURE_TYPE.Faglitteratur].every(littType =>
          literatureType.includes(littType),
        )
      ) {
        enablerAndSort.first = isFagOnlyLitt;
      }
      // Skjønnlitteratur?
      else if (
        literatureType.some(
          littType => littType === CodeListRef.LITERATURE_TYPE.Skjønnlitteratur,
        )
      ) {
        enablerAndSort.first = isSkjønnlitt;
      }
      // Skolebøker?
      else if (
        literatureType.some(
          littType => littType === CodeListRef.LITERATURE_TYPE.Skolebøker,
        )
      ) {
        enablerAndSort.first = isSkolelbøker;
      }
    }
  }

  const {enablers, first} = enablerAndSort;

  if (enablers.length === 0 && first === undefined) {
    return outerCodelist;
  }

  const codes =
    enablers.length > 0
      ? outerCodelist.codes.map(code =>
          enablers.every(inc => inc(code))
            ? code
            : {
                ...code,
                disabled: true,
              },
        )
      : outerCodelist.codes;

  const firstCodes = first
    ? [...codes.filter(first), ...codes.filter(c => !first(c))]
    : codes;

  return {
    ...outerCodelist,
    codes: firstCodes,
  };
};
