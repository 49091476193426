import {useMemo} from 'react';
import {Concept, LinkAction} from 'types';
import {SetSourceValue} from 'sceneExtensions/types';
import {Linking} from 'schema/types';
import {LinkedValueLink, VerifiedLinkedValueLink} from 'schemaDefinition/types';
import {
  getLinkTargetConcreteEntityType,
  isAgentValue,
} from 'services/utils/functions';
import {EntityState, LinkedValueSearchFieldConfig} from '../types';
import {
  canCopySourceToEntity,
  canLinkEntityToSource,
  canMoveSourceToEntity,
} from '../functions';
import {getEntityValueId} from '../functions/getEntityValueId';

export const usePreviewLinkingConfiguration = (
  previewEntityType: Concept,
  currentSourceValue: LinkedValueLink | undefined,
  currentSourceConfig: LinkedValueSearchFieldConfig | undefined,
  entityState: EntityState | undefined,
  setSourceValue: SetSourceValue<
    VerifiedLinkedValueLink,
    Exclude<LinkAction, 'changeLink'>
  >,
) => {
  // Config preview according to current linked value
  return useMemo((): Linking | undefined => {
    const sourceValue: VerifiedLinkedValueLink | undefined =
      currentSourceValue?.entityId
        ? (currentSourceValue as VerifiedLinkedValueLink)
        : undefined;
    const value = entityState?.data;
    const entityValueId = value ? getEntityValueId(value) : undefined;
    if (!entityValueId) {
      return undefined;
    }
    return canLinkEntityToSource(previewEntityType, currentSourceConfig)
      ? {
          entityId: entityValueId,
          entityType: getLinkTargetConcreteEntityType(value),
          nationalId: isAgentValue(value) ? value.nationalId : undefined,
          currentValue: sourceValue,
          // Only takes on argument passed to setSourceValue
          link: (...args) => setSourceValue(args[0], 'link', 'close'),
          unlink: () => setSourceValue(undefined, 'unlink'),
        }
      : canMoveSourceToEntity(previewEntityType, currentSourceConfig)
        ? {
            entityId: entityValueId,
            entityType: getLinkTargetConcreteEntityType(value),
            nationalId: isAgentValue(value) ? value.nationalId : undefined,
            currentValue: sourceValue,
            move: (...args) => setSourceValue(args[0], 'move', 'keepOpen'),
            copy: (...args) => setSourceValue(args[0], 'copy', 'close'),
          }
        : canCopySourceToEntity(previewEntityType, currentSourceConfig)
          ? {
              entityId: entityValueId,
              entityType: getLinkTargetConcreteEntityType(value),
              nationalId: isAgentValue(value) ? value.nationalId : undefined,
              currentValue: sourceValue,
              copy: (...args) => setSourceValue(args[0], 'copy', 'close'),
            }
          : undefined;
  }, [
    currentSourceConfig,
    currentSourceValue,
    entityState?.data,
    previewEntityType,
    setSourceValue,
  ]);
};
