import React from 'react';
import {Concept} from 'types';
import {MassUpdate} from 'api/types';
import {MassUpdateManifestatinosPreview} from './MassUpdateManifestatinosPreview';
import {MassUpdateWorksPreview} from './MassUpdateWorksPreview';

type Props = {
  massUpdate: MassUpdate;
};

export const MassUpdatePreview: React.FC<Props> = ({massUpdate}) => {
  return massUpdate.type === Concept.work ? (
    <MassUpdateWorksPreview massUpdate={massUpdate} />
  ) : (
    <MassUpdateManifestatinosPreview massUpdate={massUpdate} />
  );
};
