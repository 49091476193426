import {
  GridFilterOperator,
  getGridBooleanOperators,
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from '@mui/x-data-grid-pro';
import {Part} from 'schemaDefinition/types';
import {isArrayFilterOperator, isNotAnyOf} from '../filterOperators';

export const mapFilterOperators = (
  part: Part,
): GridFilterOperator[] | undefined => {
  switch (part.type) {
    case 'date':
    case 'yearOrDate':
      return getGridDateOperators();
    case 'int':
    case 'year':
      return getGridNumericOperators();
    case 'bool':
      return getGridBooleanOperators();
    case 'codelist':
      if (part.cardinality === 'multiple') {
        return [isArrayFilterOperator, isNotAnyOf(true)];
      }

      return [...getGridSingleSelectOperators(), isNotAnyOf(true)];
    case 'text':
    case 'textarea':
    default:
      return [...getGridStringOperators(), isNotAnyOf(false)];
  }
};
