import {assert} from 'assert-ts';
import {Concept} from 'types';
import {EntityValue, SearchResultType} from 'feature/linkedValueSearch/types';
import {findManifestation} from 'services/data/metadata/functions/findManifestation';
import {
  assertAgentValue,
  assertCollectionValue,
  assertWorkMetadataValue,
  assertWorkWithExpressions,
} from 'services/utils';
import {formatNameVariantValue} from 'schema/preview/functions';
import {getManifestationTitle} from './getManifestationTitle';

export const getEntityTitle = (
  value: EntityValue | undefined,
  type: SearchResultType,
  id: string | undefined,
): string => {
  if (!value) {
    return '';
  }

  switch (type) {
    case Concept.work: {
      const metadata = assertWorkMetadataValue(value);
      return metadata.work.preferredTitle?.[0] ?? '';
    }
    case Concept.manifestation: {
      const work = assertWorkWithExpressions(value);
      const manifestation =
        findManifestation(work, id) ??
        work.expressions?.[0]?.manifestations?.[0];
      return getManifestationTitle(manifestation) ?? '';
    }
    case Concept.person:
    case Concept.corporation:
    case Concept.event:
    case Concept.publisher: {
      const agent = assertAgentValue(value);
      const mainForm = agent.nameVariants[0];
      return formatNameVariantValue(mainForm, agent.agentType, 'excludeId');
    }
    case Concept.series:
    case Concept.educationalSeries:
    case Concept.otherConnection:
    case Concept.publisherSeries: {
      const collection = assertCollectionValue(value);
      return collection.titles[0]?.value ?? '';
    }
    default: {
      assert(false, 'getEntityTitle');
    }
  }
};
