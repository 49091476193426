import {MassUpdateSummary, Schemas_MassUpdateSummaryDto} from '../types';
import {mapMassUpdateActionDto} from './mapMassUpdateActionDto';
import {mapMassUpdateTypeDto} from './mapMassUpdateTypeDto';

export const mapMassUpdateSummaryDto = (
  dto: Schemas_MassUpdateSummaryDto,
): MassUpdateSummary => {
  console.log('from', dto);
  console.log('to', {
    id: dto.id,
    status: dto.status,
    items: dto.items,
    field: dto.field.field,
    type: mapMassUpdateTypeDto(dto.field.type),
    ...mapMassUpdateActionDto(dto.action),
    created: dto.created,
    completed: dto.completed,
  });
  return {
    id: dto.id,
    status: dto.status,
    items: dto.items,
    massUpdateField: dto.field.field,
    type: mapMassUpdateTypeDto(dto.field.type),
    ...mapMassUpdateActionDto(dto.action),
    created: dto.created,
    completed: dto.completed,
  };
};
