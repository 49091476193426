import {MassUpdateSummary} from 'api/types';
import {TypedSchema} from 'schemaDefinition/types';
import {massUpdateCreateStaticSchema} from './massUpdateSchemas';

export const massUpdateSummarySchema: TypedSchema<MassUpdateSummary> = {
  name: 'massUpdateSummary',
  parts: [
    {
      name: 'created',
      type: 'date',
    },
    {
      name: 'completed',
      type: 'date',
    },
    {
      type: 'codelist',
      name: 'status',
      codelistId: 'massUpdateStatus',
    },
    {
      name: 'type',
      type: 'codelist',
      codelistId: 'massUpdateType',
    },
    ...massUpdateCreateStaticSchema.parts,
    {
      name: 'currentValue',
      type: 'text',
    },
    {
      name: 'newValue',
      type: 'text',
    },
  ],
};
