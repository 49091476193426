import React from 'react';
import {ThesaurusNodeDetailsDateValue as GrepDetailsDateValueType} from 'api/types';
import {useLocalization} from 'localization';
import {FlexBox, Text} from 'components';
import {NodeLabel} from './NodeLabel';

type Props = {
  itemKey: string;
  value: GrepDetailsDateValueType;
};

export const GrepDetailsDateValue: React.FC<Props> = ({itemKey, value}) => {
  const {formatDate} = useLocalization();

  return (
    <FlexBox>
      <NodeLabel label={itemKey} />
      <Text variant="body2">{formatDate(value.value)}</Text>
    </FlexBox>
  );
};
