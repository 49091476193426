import assert from 'assert-ts';
import {
  Data,
  Part,
  PartExpand,
  PartSeparator,
  PartType,
  Schema,
  SeparatedPart,
} from 'schemaDefinition/types';
import {EqualOptions, areValuesEqual} from 'schemaDefinition';
import {last} from 'services/utils';

type TrimmedSchema<TSchemaElement> =
  | {
      hasChanges: false;
      trimmed?: undefined;
    }
  | {
      hasChanges: true;
      trimmed: TSchemaElement;
    };

export type TrimSchemaOptions = {
  excludeEmpty: boolean;
  excludeEqual: boolean;
  /** Exclude schema parts that are static read-only (i.e. not considering dynamic read-only) */
  excludeReadOnly?: boolean;
};

const EQUAL_OPTIONS: EqualOptions = {
  falseEqualsNullish: true,
  emptyStringEqualsNullish: true,
};

/**
 * Returns trimmed schema, if any changes are found, and also removes properties
 * from changes that are equal to corresponding original values.
 * @param changes
 * @param original
 * @param schema
 * @param options
 * @returns
 */
export const getTrimmedSchema = (
  changes: Data,
  original: Data,
  schema: Schema<Data>,
  options: TrimSchemaOptions,
): TrimmedSchema<Schema> => {
  const trimmedParts = getTrimmedParts(
    changes,
    original,
    schema.parts,
    options,
  );
  return trimmedParts.hasChanges
    ? {hasChanges: true, trimmed: {...schema, parts: trimmedParts.trimmed}}
    : {hasChanges: false};
};

const isEmtpyValue = (value: unknown): boolean =>
  value === undefined ||
  value === null ||
  value === '' ||
  (Array.isArray(value) && value.length === 0) ||
  (typeof value === 'object' && Object.keys(value).length === 0);

const getTrimmedParts = <T extends Data, TPart extends Part>(
  changes: T,
  original: T,
  parts: SeparatedPart[],
  option: TrimSchemaOptions,
): TrimmedSchema<TPart[]> => {
  let hasChanges = false;
  const trimmed: SeparatedPart[] = [];

  const append = (part: PartSeparator | Part | Part[]) => {
    if (
      Array.isArray(part) ||
      part.type !== 'separator' ||
      partType(last(trimmed)) !== 'separator'
    ) {
      trimmed.push(part);
    }

    hasChanges = hasChanges || Array.isArray(part) || part.type !== 'separator';
  };

  parts.forEach(p => {
    if (Array.isArray(p)) {
      const trimmedRow = getTrimmedParts<T, Part>(changes, original, p, option);
      if (trimmedRow.hasChanges) {
        append(trimmedRow.trimmed);
      }
    } else {
      switch (p.type) {
        case 'separator': {
          append(p);
          break;
        }
        case 'text':
        case 'textarea':
        case 'int':
        case 'bool':
        case 'year':
        case 'date':
        case 'yearOrDate':
        case 'codelist':
        case 'linkedAgent':
        case 'linkedLiterary':
        case 'nameVariant':
        case 'thesaurus':
        case 'schema': {
          // No nesting for schema parts, i.e. just compare complex values
          if (p.type === 'schema') {
            assert.soft(
              p.compare !== 'nested',
              'compare = "nested" for schema not supported when diffing',
              p,
            );
          }
          const cVal = changes[p.name];
          const oVal = original[p.name];

          const exclude =
            cVal === undefined ||
            (option.excludeEmpty && isEmtpyValue(cVal)) ||
            (option.excludeEqual &&
              areValuesEqual(cVal, oVal, EQUAL_OPTIONS)) ||
            (option.excludeReadOnly && p.readonly === true);

          if (exclude) {
            delete changes[p.name];
          } else {
            append(p);
          }
          break;
        }
        case 'expand': {
          const uniqueExpandParts = getAllUniqueExpandParts(p);
          const trimmedExpandParts = getTrimmedParts(
            changes,
            original,
            uniqueExpandParts,
            option,
          );
          if (trimmedExpandParts.hasChanges) {
            trimmedExpandParts.trimmed.forEach(p => {
              append(p);
            });
          }
          break;
        }
      }
    }
  });

  return hasChanges
    ? {
        hasChanges,
        trimmed: trimmed as TPart[],
      }
    : {hasChanges};
};

const partType = (
  part: SeparatedPart | undefined,
): PartType | 'separator' | 'row' | undefined =>
  part === undefined ? undefined : Array.isArray(part) ? 'row' : part.type;

const getAllUniqueExpandParts = (
  part: PartExpand,
): (PartSeparator | Part)[] => {
  const allParts = [...part.when.flatMap(w => w.parts), ...(part.default ?? [])]
    // All to array
    .map(p => (Array.isArray(p) ? p : [p]))
    // Flatten
    .flatMap(p => p);

  const uniqueParts: (PartSeparator | Part)[] = [];

  allParts.forEach(p => {
    if (p.type === 'separator') {
      if (
        uniqueParts.length > 0 &&
        uniqueParts[uniqueParts.length - 1].type !== 'separator'
      ) {
        uniqueParts.push(p);
      }
    } else if (
      assert.soft(
        p.type !== 'expand',
        'getActualDataChangesWithSchema: nested expand not supported',
        p,
      ) &&
      !uniqueParts.find(up => up.name === p.name)
    ) {
      uniqueParts.push(p);
    }
  });

  return uniqueParts;
};
