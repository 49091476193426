import React, {useMemo} from 'react';
import {assert} from 'assert-ts';
import {Concept} from 'types';
import {DataLoadStatus, MassUpdate} from 'api/types';
import {Data} from 'schemaDefinition/types';
import {MassUpdatePreviewCore} from './MassUpdatePreviewCore';

type Props = {
  massUpdate: MassUpdate;
};

export const MassUpdateManifestatinosPreview: React.FC<Props> = ({
  massUpdate,
}) => {
  assert(
    massUpdate.type === Concept.manifestation,
    'MassUpdateWorksPreview: type manifestation is expected',
  );

  const failedIds = useMemo(
    () => massUpdate.items.failed.map(item => item.id),
    [massUpdate.items.failed],
  );
  const unprocessed = useManifestations(massUpdate.items.unprocessed);
  const succeeded = useManifestations(massUpdate.items.succeeded);
  const failed = useManifestations(failedIds);

  return (
    <MassUpdatePreviewCore
      massUpdate={massUpdate}
      unprocessed={unprocessed}
      succeeded={succeeded}
      failed={failed}
    />
  );
};

const useManifestations = (ids: string[]): DataLoadStatus<Data[]> => {
  return useMemo(() => {
    const data: Data[] = ids.map(id => ({
      id,
    }));
    const dataLoad: DataLoadStatus<Data[]> = {
      status: 'Loaded',
      data,
    };

    return dataLoad;
  }, [ids]);
};
