import {CodeListRef} from 'api/types';

/**
 * Check if the product form is merchandise
 *
 * https://bokbasen.jira.com/wiki/spaces/EBBA/pages/2896494601/Felter+fysisk+beskrivelse+manifestasjon+etter+produktform+Onixliste+150
 */
export const isMerchProductForm = (code: string) => {
  const merchProductFormCodes = [
    CodeListRef.PRODUCT_FORM['Globus'], // CE
    CodeListRef.PRODUCT_FORM['Diverse trykk'], // PA
    CodeListRef.PRODUCT_FORM['Kalender'], // PC
    CodeListRef.PRODUCT_FORM['Dagbok'], // PF
    CodeListRef.PRODUCT_FORM['Kort'], // PD
    CodeListRef.PRODUCT_FORM['Postkort'], // PJ
    CodeListRef.PRODUCT_FORM['Plakat'], // PK
    CodeListRef.PRODUCT_FORM['Mappe eller omslag'], // PM
    CodeListRef.PRODUCT_FORM['Plansje'], // PO
    CodeListRef.PRODUCT_FORM['Notisbok / skrivebok'], // PR
    CodeListRef.PRODUCT_FORM['Andre trykte artikler'], // PZ
  ] as string[];

  return (
    merchProductFormCodes.includes(code) || code[0] === 'X' || code[0] === 'Z'
  );
};
