import React, {useMemo} from 'react';
import {
  FormControl,
  ListSubheader,
  MenuItem,
  Select as MuiSelect,
  SxProps,
  Theme,
} from '@mui/material';

type GroupItemName = 'ListSubheaderGroupItem';
export const GroupItemNameValue: GroupItemName = 'ListSubheaderGroupItem';

/**
 * Option can be a group, a string value or an object value
 */
type OptionValue<T> =
  | {
      value: T;
      label: string;
    }
  | T
  | {value: GroupItemName; label: string};

export type SelectProps<T = string> = {
  value?: T;
  options: Array<OptionValue<T>>;
  onChange: (value: T) => void;
  error?: boolean;
  disabled?: boolean;
};

const SelectSx: SxProps = {
  height: 42,
};

const GroupSx: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  paddingLeft: '20px',
});

export function Select<T extends string>({
  options,
  value,
  onChange,
  disabled,
  error,
}: React.PropsWithChildren<SelectProps<T>>) {
  const MenuItems = useMemo(() => {
    return options.map(o => {
      if (typeof o === 'string') {
        return (
          <MenuItem key={o} value={o}>
            {o}
          </MenuItem>
        );
      }

      // Renders group separator
      if (o.value === GroupItemNameValue) {
        return (
          <ListSubheader sx={GroupSx} key={`${o.value}-${o.label}`}>
            {o.label}
          </ListSubheader>
        );
      }

      return (
        <MenuItem key={o.value} value={o.value}>
          {o.label ?? o.value}
        </MenuItem>
      );
    });
  }, [options]);

  return (
    <FormControl fullWidth error={error}>
      <MuiSelect<T>
        value={value}
        disabled={disabled}
        onChange={event => onChange(event.target.value as T)}
        sx={SelectSx}>
        {MenuItems}
      </MuiSelect>
    </FormControl>
  );
}
