import React, {useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Tooltip} from '@mui/material';
import {useMetadataSavedStatus} from 'services/data';
import {useSetWorkItems, useWorkItems} from 'services/workItems';
import {useShortcut} from 'shortcuts';
import {Layout, ToolBar} from 'components';
import {ManifestationStatus} from './ManifestationStatus';

type Props = {
  onSearch: () => void;
};

export const MetadataToolbar: React.FC<Props> = ({onSearch}) => {
  const status = useMetadataSavedStatus();
  const navigate = useNavigate();
  const workItems = useWorkItems();
  const setWorkItems = useSetWorkItems();

  const goToNextTodo = useCallback(() => {
    if (workItems.length === 0) {
      return;
    }

    const [next, ...rest] = workItems;
    setWorkItems(rest);
    navigate(next.link);
  }, [navigate, setWorkItems, workItems]);

  const NextTodoButton = useMemo(() => {
    if (workItems.length > 0) {
      return (
        <Tooltip
          title={workItems[0].tooltip}
          arrow
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -14],
                  },
                },
              ],
            },
          }}>
          <Box>
            <ToolBar.IconTool
              icon="DoubleChevronRight"
              onClick={goToNextTodo}
            />
          </Box>
        </Tooltip>
      );
    }

    return undefined;
  }, [workItems, goToNextTodo]);

  useShortcut('goToNext', goToNextTodo);

  return (
    <Layout horizontal adjustCenter adjustRight flex={1}>
      <ToolBar>
        <ManifestationStatus status={status} />
        {NextTodoButton}
        <ToolBar.IconTool icon="Search" onClick={onSearch} />
      </ToolBar>
    </Layout>
  );
};
