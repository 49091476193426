import {assert} from 'assert-ts';
import {Concept, DerivedConcept} from 'types';
import {ColumnConfig} from 'schema/table/types';
import {SchemaReference} from 'schemas/types';
import {workSummaryDefaultColumns} from 'schemas/configurations/table/columns/workSummaryDefaultColumns';
import {
  collectionSeriesDefaultColumns,
  corporationAgentDefaultColumns,
  eventAgentDefaultColumns,
  massUpdateDefaultColumns,
  personAgentDefaultColumns,
  publisherAgentDefaultColumns,
  workDefaultColumns,
} from '../configurations/table/columns';

export const getDefaultColumns = (
  reference: SchemaReference,
): ColumnConfig[] => {
  switch (reference) {
    case Concept.expression:
    case Concept.manifestation:
      assert(
        false,
        `useDefaultColumns: Entity type not implemented: ${reference}`,
      );
      return [];
    case Concept.work:
      return workDefaultColumns;
    case DerivedConcept.workSummary:
      return workSummaryDefaultColumns;
    case Concept.massUpdate:
      return massUpdateDefaultColumns;
    default: {
      const {entity, subType} = reference;
      switch (entity) {
        case Concept.agent: {
          switch (subType) {
            case Concept.person:
              return personAgentDefaultColumns;
            case Concept.publisher:
              return publisherAgentDefaultColumns;
            case Concept.corporation:
              return corporationAgentDefaultColumns;
            case Concept.event:
              return eventAgentDefaultColumns;
            default:
              assert(
                false,
                `useDefaultColumns: Unknown subtype: ${subType} of ${entity}`,
              );
          }
          break;
        }
        case Concept.collection: {
          switch (subType) {
            case Concept.series:
            case Concept.educationalSeries:
            case Concept.otherConnection:
            case Concept.publisherSeries:
              return collectionSeriesDefaultColumns;
            default:
              assert(
                false,
                `useDefaultColumns: Unknown subtype: ${subType} of ${entity}`,
              );
          }
          break;
        }
        default: {
          assert(false, `useDefaultColumns: Unknown entity type: ${entity}`);
        }
      }
    }
  }
};
