import sortBy from 'lodash/sortBy';
import {ColumnWithLabel} from 'api';

/**
 * Filters allColumns based on filter and currentColumns.
 * - no filter: returns allColumns
 * - filter: returns allColumns that contains filter, case insensitive,
 *   and also all currentColumns. Sorts columns by:
 *   - columns starting with filter
 *   - columns containing filter
 *   - currentColumns
 */
export const getFilteredColumns = (
  filter: string | undefined,
  allColumns: ColumnWithLabel[],
  currentColumns: string[],
): ColumnWithLabel[] => {
  if (!filter) {
    return allColumns;
  }

  const filterLower = filter.toLowerCase();
  const filteredColumns = allColumns.filter(
    c =>
      c.label.toLowerCase().includes(filterLower) ||
      currentColumns.includes(c.name),
  );

  const sortedColumns = sortBy(filteredColumns, [
    c => !c.label.toLowerCase().startsWith(filterLower),
    c => !c.label.toLowerCase().includes(filterLower),
  ]);

  return sortedColumns;
};
