import {useMemo} from 'react';
import {DataLoadStatus, Media} from 'api/types';
import {getMediaUrl, useLazyGetResource} from 'api';
import {useGetTokens} from 'services/auth/hooks/useGetTokens';

export const useLazyFileUrl = (
  file: Media,
  doLoad: boolean,
  mock?: boolean,
): {url: DataLoadStatus<string>} => {
  const token = useGetTokens();

  const url = useLazyGetResource(doLoad, () =>
    getMediaUrl(file.id, token, mock),
  );

  return useMemo(
    () => ({
      url,
    }),
    [url],
  );
};
