import {GetTokens, WorkHit} from './types';
import workQuery from './data/workQuery.json';
import {Schemas} from './dto.generated';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpElasticV2} from './http/search/httpElastic';
import {mapWorkHits} from './mappers/mapWorkHits';
import {SearchResult, SearchResultDto} from './searchTypes';

export const searchWork = (
  query: string,
  page: number,
  size: number,
  getTokens: GetTokens,
  signal?: AbortSignal,
  _fields?: string[],
): Promise<SearchResult<WorkHit>> => {
  /**
   * Placed the JSON in a file to easier debug it in elastic cloud console.
   */
  const elasticQuery = JSON.stringify(workQuery).replaceAll(
    '${QUERY_PLACEHOLDER}',
    query,
  );

  return exceptionToPromiseReject(() =>
    httpElasticV2<SearchResultDto<Schemas.WorkDocument>>(
      getTokens,
      'ebs-work-alias',
      JSON.parse(elasticQuery),
      page,
      size,
      signal,
    ).then(mapWorkHits),
  );
};
