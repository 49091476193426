import {
  MassUpdateCreate,
  Schemas_MassUpdateCreateDto,
  Schemas_MassUpdateWorkFieldDto,
} from '../types';
import {mapToMassUpdateActionDto} from './mapMassUpdateActionDto';

export const mapToMassUpdateCreateDto = (
  data: MassUpdateCreate,
  ids: string[],
): Schemas_MassUpdateCreateDto => {
  return {
    field: {
      field: data.massUpdateField as Schemas_MassUpdateWorkFieldDto['field'],
      type: data.type as Schemas_MassUpdateWorkFieldDto['type'],
    },
    action: mapToMassUpdateActionDto(data),
    items: ids,
  };
};
