import {createContext, useCallback, useContext, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {GridValidRowModel} from '@mui/x-data-grid-pro';
import {assert} from 'assert-ts';
import {OverviewTask, TaskTypeAll} from 'api/types';
import {useLocalization} from 'localization';
import {HeadTitleParam} from 'services/head';
import {useSetWorkItems, useWorkItems} from 'services/workItems';
import {OverviewTaskType} from '../types';
import {createTaskLink, getTaskLink} from '../functions';
import {useOverviewTasks} from '../hooks';

export type OverviewContextType = {
  loading: boolean;
  allTasks: OverviewTask[];
  filteredTasks: OverviewTask[];
  openManifestation: (task: OverviewTask) => void;
  taskTypes: OverviewTaskType[];
  selectedTaskType: TaskTypeAll;
  setSelectedTaskType: (taskType: TaskTypeAll) => void;
  searchText: string | undefined;
  setSearchText: (searchText: string) => void;
  loadingFailed: boolean;
  onRowsUpdate: (gridRows: GridValidRowModel[]) => void;
} & HeadTitleParam;

export const OverviewContext = createContext<OverviewContextType | undefined>(
  undefined,
);

export const useOverviewContextProviderValue = (): OverviewContextType => {
  const navigate = useNavigate();
  const {t} = useLocalization();
  const {
    allTasks,
    filteredTasks,
    taskTypes,
    selectedTaskType,
    setSelectedTaskType,
    searchText,
    setSearchText,
    loading,
    errorMsg,
  } = useOverviewTasks();

  const todoLinks = useWorkItems();
  const setWorkItems = useSetWorkItems();

  const onRowsUpdate = useCallback(
    (gridRows: GridValidRowModel[]) => {
      const items = gridRows
        .map(g => createTaskLink(g.isbn, g.workId, g.manifestationId, g.ean))
        .filter(l => l)
        .map(l => ({
          link: l!,
          tooltip: t('page.metadata.tasks.next'),
        }));

      setWorkItems(items);
    },
    [setWorkItems, t],
  );

  const openManifestation = useCallback(
    (task: OverviewTask) => {
      const taskLink = getTaskLink(task);

      if (taskLink) {
        // Removes opened task from todolist.
        const index = todoLinks.findIndex(t => t.link === taskLink);
        if (index > -1) {
          const items = [...todoLinks];
          items.splice(index, 1);

          setWorkItems(items);
        }
        navigate(taskLink);
      }
    },
    [navigate, setWorkItems, todoLinks],
  );

  return useMemo(
    () => ({
      title: t('appbar.overview'),
      loading,
      allTasks,
      filteredTasks,
      taskTypes,
      selectedTaskType,
      setSelectedTaskType,
      searchText,
      setSearchText,
      openManifestation,
      loadingFailed: !!errorMsg,
      onRowsUpdate,
    }),
    [
      t,
      loading,
      allTasks,
      filteredTasks,
      taskTypes,
      selectedTaskType,
      setSelectedTaskType,
      searchText,
      setSearchText,
      openManifestation,
      errorMsg,
      onRowsUpdate,
    ],
  );
};

export const useOverviewContext = (): OverviewContextType => {
  return assert(
    useContext(OverviewContext),
    'useOverviewContext: context expected',
  );
};
