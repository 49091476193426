import React, {useCallback, useMemo} from 'react';
import assert from 'assert-ts';
import {MassUpdateCreate} from 'api/types';
import {Data} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {useAllCodeListIds, useCodelists} from 'services/codeLists';
import {useMassUpdateItems} from 'services/massUpdateItems';
import {Layout, Spacer, Text, Wizard} from 'components';
import {DataFormV1} from 'schema/form';
import {validate} from 'schema/form/functions/validators';
import {StepId} from '../types';
import {MassUpdatePreview} from '../../../widgets';
import {useWizardStepLabels} from '../hooks';
import {
  NewMassUpdateWizardProps,
  useNewMassUpdateWizardState,
} from '../hooks/useNewMassUpdateWizardState';

const empty: Data = {};

// const fieldAndOperationSchema: TypedSchema<MassUpdateCreate> = {
//   name: 'fieldAndOperationSchema',
//   parts: [
//     {
//       type: 'codelist',
//       name: 'massUpdateField',
//       codelistId: 'massUpdateWorkField',
//       required: true,
//       cardinality: 'single',
//     },
//     {
//       type: 'codelist',
//       name: 'massUpdateAction',
//       codelistId: 'massUpdateAction',
//       required: true,
//       cardinality: 'single',
//     },
//   ],
// };

export const NewMassUpdateWizard: React.FC<
  NewMassUpdateWizardProps
> = props => {
  const {
    stepIds,
    currentStep,
    massUpdateState,
    massUpdateSchema: schema,
    showValidationErrors,
    onMassUpdateStateEdited,
    gotoStep,
    onSave,
  } = useNewMassUpdateWizardState(props);

  const {formatDate} = useLocalization();
  const massUpdateItems = useMassUpdateItems();

  const codelistIds = useAllCodeListIds();
  const codeListMap = useCodelists(codelistIds);

  const stepLabels = useWizardStepLabels();

  const step1Content = useMemo(() => {
    return (
      <Layout>
        <DataFormV1
          id={'STEP_1_EnterValues'}
          schema={schema}
          data={massUpdateState}
          mode="edit"
          relatedData={empty}
          showErrors={showValidationErrors}
          onDataChanged={onMassUpdateStateEdited}
        />
      </Layout>
    );
  }, [massUpdateState, onMassUpdateStateEdited, schema, showValidationErrors]);

  const step2Content = useMemo(
    () => (
      <MassUpdatePreview
        massUpdate={{
          // Will have all required values in this step after validation
          ...(massUpdateState as MassUpdateCreate),
          items: {
            unprocessed: massUpdateItems.itemIds,
            failed: [],
            succeeded: [],
          },
          id: 'new',
          status: 'NEW',
          created: formatDate(new Date().valueOf()),
        }}
      />
    ),
    [formatDate, massUpdateItems.itemIds, massUpdateState],
  );

  const validateStep = useCallback(
    (stepIndex: number): boolean => {
      switch (stepIndex) {
        case 0:
          return (
            validate(massUpdateState, undefined, schema, codeListMap).valid ===
            'valid'
          );
        case 1:
          // Read-only step, always valid
          return true;
        default:
          assert(false, 'Invalid step index', {stepIndex});
      }
    },
    [codeListMap, massUpdateState, schema],
  );

  if (massUpdateItems.itemIds.length === 0) {
    return (
      <Layout>
        <Spacer height={4} />
        <Text kind="error" variant="h3">
          Fant ingenting å behandle!
        </Text>
      </Layout>
    );
  }

  return (
    <Wizard
      initialStepId={currentStep}
      title={''}
      showSaveCancel={false}
      showPrev={false}
      validateStep={validateStep}
      onCancel={() => {}}
      onSave={onSave}
      onGotoStep={step => gotoStep(step as StepId)}
      steps={[
        {
          id: stepIds[0],
          label: stepLabels[0],
          content: step1Content,
        },
        {
          id: stepIds[1],
          label: stepLabels[1],
          content: step2Content,
        },
      ]}
    />
  );
};
