import {useMemo} from 'react';
import {WorkV4} from 'api';
import {WorkExportProps} from '../types';

export const useWorkExports = (
  work: Pick<
    WorkV4,
    'type' | 'agents' | 'literatureType' | 'languages' | 'intellectualLevel'
  >,
): WorkExportProps => {
  return useMemo(
    () => ({
      type: work.type,
      agents: work.agents,
      literatureType: work.literatureType,
      languages: work.languages,
      intellectualLevel: work.intellectualLevel,
    }),
    [work],
  );
};
