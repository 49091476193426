import {Schemas} from '../dto.generated';

export const mapSeriesDocumentToSeriesWithRelationsDto = (
  dto: Schemas.SeriesDocument,
): Schemas.SeriesWithRelationsDto => {
  return {
    id: dto.id ?? '',
    titles: (dto.titles ?? []).map(t => ({
      value: t.title ?? '<missing>',
      language: t.language ?? '<missing>',
    })),
    seriesType:
      (dto.type as Schemas.SeriesWithRelationsDto['seriesType']) ?? 'SERIES',
    relations: undefined,
    note: undefined,
  };
};
