import {useMemo} from 'react';
import {assert} from 'assert-ts';
import {PreviewConfiguration} from 'schema/types';
import {Data, Schema} from 'schemaDefinition/types';
import {
  getGroupedParts,
  isData,
  removeEmptyParts,
  removeEmptyValueParts,
} from 'schemaDefinition/functions';
import {useLocalization} from '../../../localization';
import {getExpandedSchemaParts} from '../functions';
import {getFilteredSchemaParts} from '../functions/getFilteredSchemaParts';

export const useReducedPreviewSchema = (
  value: Data,
  schema: Schema,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  config: PreviewConfiguration,
) => {
  const {tLoose} = useLocalization();
  const groupedParts = useMemo(() => {
    // Expand active case/default of any expand parts based on actual value and config context,
    // i.e. resulting parts will not contain expand parts
    const expandedSchemaParts = getExpandedSchemaParts(
      value,
      schema.parts,
      valuePath,
      localScope,
      globalScope,
      config,
    );
    // TODO: Rename included...
    const filteredSchemaParts = getFilteredSchemaParts(
      expandedSchemaParts,
      config,
    );

    const nonEmptySchemaParts = config.layout.showEmptyValues
      ? filteredSchemaParts
      : removeEmptyValueParts(
          filteredSchemaParts,
          value,
          'preview',
          config.nameVariant?.excludeMainForm,
        );

    const groupedSchemaParts = getGroupedParts(nonEmptySchemaParts, 'preview');
    if (config.layout.showEmptyValues) {
      return groupedSchemaParts;
    }

    assert(
      isData(value),
      'PreviewSchema: value must of type Data, i.e. object',
      {schema, value, valuePath},
    );

    return removeEmptyParts(
      groupedSchemaParts,
      value,
      'preview',
      config.nameVariant?.excludeMainForm,
    );
  }, [config, globalScope, localScope, schema, value, valuePath]);

  return useMemo(() => {
    return groupedParts.map(group => ({
      ...group,
      title: group.titleKey
        ? tLoose(`preview.group.${group.titleKey}.title`)
        : undefined,
    }));
  }, [groupedParts, tLoose]);
};
