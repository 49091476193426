import assert from 'assert-ts';
import {
  MassUpdate,
  MassUpdateCreate,
  Schemas_MassUpdateCreateDto,
  Schemas_MassUpdateDto,
} from '../types';

export const mapMassUpdateActionDto = (
  dto: Schemas_MassUpdateDto['action'],
): Pick<MassUpdate, 'massUpdateAction' | 'currentValue' | 'newValue'> => {
  switch (dto.action) {
    case 'ADD':
      return {
        massUpdateAction: 'ADD',
        newValue: [dto.value],
      };
    case 'REMOVE':
      return {
        massUpdateAction: 'REMOVE',
        newValue: [dto.value],
      };
    case 'REPLACE':
      return {
        massUpdateAction: 'REPLACE',
        currentValue: [dto.currentValue],
        newValue: [dto.updatedValue],
      };
  }

  assert(false, 'mapMassUpdateActionDto: Expected action', dto);
};

export const mapToMassUpdateActionDto = (
  dto: Pick<MassUpdateCreate, 'massUpdateAction' | 'currentValue' | 'newValue'>,
): Schemas_MassUpdateCreateDto['action'] => {
  switch (dto.massUpdateAction) {
    case 'ADD':
      return {
        action: 'ADD',
        value: assert(
          assert(dto.newValue, 'ADD: newValue expected')[0],
          'ADD: newValue[0] expected',
        ),
      };
    case 'REMOVE':
      return {
        action: 'REMOVE',
        value: assert(
          assert(dto.currentValue, 'REMOVE: currentValue expected')[0],
          'REMOVE: currentValue[0] expected',
        ),
      };
    case 'REPLACE':
      return {
        action: 'REPLACE',
        currentValue: assert(
          assert(dto.currentValue, 'REPLACE: currentValue expected')[0],
          'REPLACE: currentValue[0] expected',
        ),
        updatedValue: assert(
          assert(dto.newValue, 'REPLACE: newValue expected')[0],
          'REPLACE: newValue[0] expected',
        ),
      };
  }

  assert(false, 'mapMassUpdateActionDto: Expected action', dto);
};
