import {AgentSubType, Concept, Indexer} from 'types';
import {CurrentFilterType, TaskTypeAll} from 'api/types';
import {
  FilterModel as TableFilterModel,
  Sorting as TableSorting,
} from 'components/dataTable/types';
import {Locale} from 'localization/types';
import {ColumnName} from 'scenes/overview/types';
import {ColumnConfig} from 'schema/table/types';
import {DefaultShortcuts, ShortcutCommandMap} from 'shortcuts/types';
import {defaultCurrentFilters} from './default';

export const AgentTypes: AgentSubType[] = [
  Concept.person,
  Concept.corporation,
  Concept.publisher,
  Concept.event,
];

export type AgentTypeAll = AgentSubType | 'all';

export type Sorting = TableSorting;
export type FilterModel = TableFilterModel;

export const DefaultFontSizePct = 120;

export type SettingsState = {
  preferredLocale?: Locale;
  fontSizePct?: number;
  shortcutOverrides?: ShortcutCommandMap;
  overview: {
    selectedTaskType: TaskTypeAll;
    searchText: string;
    /** @deprecated Using table config instead */
    searchVisibility: boolean;
    /** @deprecated Using table config instead */
    columnsVisibility: {[key in TaskTypeAll]?: Indexer<boolean>};
    /** @deprecated Using table config instead */
    columnsOrder: {[key in TaskTypeAll]?: ColumnName[]};
    /** @deprecated Using table config instead */
    sorting?: Sorting;
  };
  agents: {
    searchVisibility: boolean;
    searchText: string;
    selectedAgentType: AgentTypeAll;
    columnsVisibility: {[key in AgentTypeAll]?: Indexer<boolean>};
    columnsOrder: {[key in AgentTypeAll]?: string[]};
    sorting?: Sorting;
  };
  tables: {
    [key: string]: {
      sorting?: Sorting;
      columns: ColumnConfig[];
      filterModel?: FilterModel;
      searchText?: string;
    };
  };
  advancedSearch: {
    currentFilters: CurrentFilterType[];
  };
};

export const SettingsDefaultState: SettingsState = {
  shortcutOverrides: DefaultShortcuts,
  fontSizePct: DefaultFontSizePct,
  overview: {
    searchVisibility: false,
    searchText: '',
    selectedTaskType: 'new',
    columnsVisibility: {},
    columnsOrder: {},
    sorting: {
      field: 'time',
      sort: 'desc',
    },
  },
  agents: {
    searchVisibility: false,
    searchText: '',
    selectedAgentType: Concept.person,
    columnsVisibility: {},
    columnsOrder: {},
    sorting: {
      field: 'time',
      sort: 'desc',
    },
  },
  tables: {
    test: {
      sorting: undefined,
      columns: [
        {
          name: 'edition',
          visible: true,
        },
      ],
    },
  },
  advancedSearch: {
    currentFilters: defaultCurrentFilters,
  },
};
