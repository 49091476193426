import {CodeListId} from 'api/types';
import {LinkedRoleCodeListId} from 'schemaDefinition/types/linkTypes';
import {Part} from '../types';

export const getPartCodelistId = (
  part: Part,
): Array<CodeListId | LinkedRoleCodeListId> | undefined => {
  switch (part.type) {
    case 'codelist': {
      return [part.codelistId];
    }
    case 'linkedAgent':
    case 'linkedLiterary': {
      return [part.roleCodelistId, 'UNNAMED_PERSON'];
    }
  }
  return undefined;
};
