import {AgentSubType, Concept, LinkTargetCatalogPostType} from 'types';
import {
  AgentName,
  Code as ApiCode,
  CodeList as ApiCodeList,
  CodeListId as ApiCodeListId,
  ThesaurusId as ApiThesaurusId,
  ThesaurusValue as ApiThesaurusValue,
  WorkMetadata,
} from 'api/types';
import {Schemas} from 'api/dto.generated';
import {NestedKeyWithIndex, NestedKeyWithoutOuterIndex} from './genericTypes';
import {RoleEntityCode} from './linkTypes';

export type Code = ApiCode;
export type CodeListId = ApiCodeListId;
export type CodeList = ApiCodeList;

export type ThesaurusId = ApiThesaurusId;

//----- Linked Value Links: Generalisation of link-part of LinkedAgent and LinkedLiterary  ------------------------------
export type LinkStatus = LinkedValueLink['linkStatus'];

export const LinkStatuses: Readonly<LinkStatus[]> = [
  /** No value yet, being edited */
  'empty',
  /** Entity (only applicable for agent) is only a string, no id */
  'unverified',
  /** Entity is a verified object, with or without national id */
  'verified',
  /** Entity is unnamed agent/ai */
  'unnamed',
] as const;

export type EmptyLinkedValueLink = {
  linkStatus: 'empty';
  name?: null;
  entityId?: null;
  nationalId?: null;
  type?: null;
  languages?: null;
};

export type UnverifiedLinkedValueLink = {
  linkStatus: 'unverified';
  name: Schemas.UnverifiedAgentRoleDto['name'];
  entityId?: null;
  nationalId?: null;
  type?: null;
  /** Language codes, applicable to series: languages titles */
  languages?: string[] | null;
};

/** Will only represent AI-"links", which will not be links or linkable */
export type UnnamedLinkedValueLink = {
  linkStatus: 'unnamed';
  name: Schemas.UnnamedAgentRoleDto['agentName'];
  entityId?: null;
  nationalId?: null;
  type?: null;
  languages?: null;
};

export type VerifiedLinkedCatalogPostLink = {
  linkStatus: 'verified';
  /** uuid of linked work, manifestation or series */
  entityId: string;
  /** Type of entity linked to, e.g. Concept.work or Concept.series */
  type: LinkTargetCatalogPostType;
  name: string;
  nationalId?: string;
  /** Language codes, applicable to series: languages of titles */
  languages?: string[] | null;
  /**
   * May contain entityValue (only WorkMetadata for now) to allow
   * more context info to confirm move and copying values from entity
   */
  entityValue?: WorkMetadata;
};

export const AgentTypes: Readonly<string[]> = [
  Concept.person,
  Concept.corporation,
  Concept.event,
  Concept.publisher,
] as const;

export type VerifiedLinkedAgentLink = {
  linkStatus: 'verified';
  entityId: string; // uuid of linked agent
  type: AgentSubType;
  agentName: AgentName;
  description?: string;
  nationalId?: string;
  numberInSeries?: string | null;
};

//----- LinkedValue ----------------------------------------------------------------------------------

export type VerifiedLinkedValueLink =
  | VerifiedLinkedCatalogPostLink
  | VerifiedLinkedAgentLink;

export type LinkedValueLink =
  | EmptyLinkedValueLink
  | UnverifiedLinkedValueLink
  | VerifiedLinkedValueLink
  | UnnamedLinkedValueLink;

export type LinkedValue = LinkedAgentMultiRole | LinkedLiterary;

//----- LinkedAgent ----------------------------------------------------------------------------------

export type LinkedAgentRoles = {
  roles: Schemas.UnverifiedAgentRoleDto['roles'];
};

export type UnnamedAgentRole = {
  unnamedAgentRole: Schemas.UnnamedAgentRoleDto['unnamedAgentRole'];
};

export type EmptyLinkedAgentMultiRole = {
  link: EmptyLinkedValueLink;
} & LinkedAgentRoles;

export type UnverifiedLinkedAgentMultiRole = {
  link: UnverifiedLinkedValueLink;
} & LinkedAgentRoles;

export type UnnamedLinkedAgentMultiRole = {
  link: UnnamedLinkedValueLink;
} & LinkedAgentRoles &
  UnnamedAgentRole;

export type VerifiedLinkedAgentMultiRole = {
  link: VerifiedLinkedAgentLink;
} & LinkedAgentRoles;

export type LinkedAgentLink =
  | EmptyLinkedValueLink
  | UnverifiedLinkedValueLink
  | VerifiedLinkedAgentLink;

export type LinkedAgentMultiRole =
  | EmptyLinkedAgentMultiRole
  | UnverifiedLinkedAgentMultiRole
  | VerifiedLinkedAgentMultiRole
  | UnnamedLinkedAgentMultiRole;

//----- LinkedLiterary ----------------------------------------------------------------------------------

export type LinkedLiteraryRole = {
  role?: RoleEntityCode;
  // Alternatively:
  // // &: Ensure role has same type for all links
  // role: Schemas.AgentLinkDto['role'] & Schemas.CatalogPostLinkDto['role'];
};

export type LinkedLiteraryNumberInSeries = {
  /** I.e. part number in numbered series, e.g. 3 (of 5) */
  numberInSeries?: string | null;
};

/*
export type LinkedLiteraryProperty = {
  prop?: number;
  // Alternatively:
  // // &: Ensure role has same type for all links
  // role: Schemas.AgentLinkDto['role'] & Schemas.CatalogPostLinkDto['role'];
};
*/

export type EmptyLinkedLiterary = {
  link: EmptyLinkedValueLink;
} & LinkedLiteraryRole &
  LinkedLiteraryNumberInSeries;

export type UnverifiedLinkedLiterary = {
  link: UnverifiedLinkedValueLink;
} & LinkedLiteraryRole &
  LinkedLiteraryNumberInSeries;

export type VerifiedLinkedLiterary = {
  link: VerifiedLinkedValueLink;
} & Required<LinkedLiteraryRole> &
  LinkedLiteraryNumberInSeries;

export type LinkedLiterary =
  | EmptyLinkedLiterary
  | UnverifiedLinkedLiterary
  | VerifiedLinkedLiterary;

export type VerifiedLinkedCatalogPost = {
  link: VerifiedLinkedCatalogPostLink;
} & Required<LinkedLiteraryRole> &
  LinkedLiteraryNumberInSeries;

export type VerifiedLinkedAgent = {
  link: VerifiedLinkedAgentLink;
} & Required<LinkedLiteraryRole>;
/** Only one of year or date should be set */
export type YearOrDateValue = {
  /** As year, value being year, e.g. 2022  */
  year?: number | null;
  /** As date, value being "YYYY-MM-DD" or "-YYYY-MM-DD" */
  date?: string | null;
  /** Negative date/date is BCE/BC */
  bce?: boolean;
  /**
   * Indicates that the year/date is approximate
   * @default false
   */
  isApproximate?: boolean;
};

export type DateValue = {
  year: number; // -9999 to 9999
  month: number; // 1 to 12
  day: number; // 1 to 31
};

// Given to components
export type DateRange = {
  minValue: DateValue | undefined;
  maxValue: DateValue | undefined;
};

export type YearRange = {
  minValue: number | undefined;
  maxValue: number | undefined;
};

export type Constant = Readonly<number | string | string[] | boolean | null>;

export type RelativeDate = {
  $date: {
    /** Offset from current date, negative or positive */
    offset: number;
    /** Unit of offset */
    unit: 'day' | 'week' | 'month' | 'year';
  };
};

export const Today: RelativeDate = {
  $date: {
    offset: 0,
    unit: 'day',
  },
};

/**
 * Path to value in a given scope, e.g.
 * 'prop', 'prop.subprop', 'prop[0].subprop'
 */
export type RefPath = string;

/**
 * Use path without outer index to support same paths for
 * single and multiple values, i.e. path to propery on single value
 * or path to property on each value in array
 */
export type PropRef<TValue = DataValue> =
  `.${NestedKeyWithoutOuterIndex<TValue>}`;

/**
 * Path to values in local scope, i.e. within the same schema
 */
export type LocalRef<TLocalEntity = Data> =
  `:${NestedKeyWithIndex<TLocalEntity>}`;

/**
 * Path to values in global scope, i.e. from root schema
 */
export type GlobalRef<TEntity = Data> = `/${NestedKeyWithIndex<TEntity>}`;

/**
 * Path to values in related (imported) data, e.g. {work: {...}} in expression schema
 */
export type RelatedRef<TRelated = Data> = `^${NestedKeyWithIndex<TRelated>}`;

export const ValueRefTypeProp = '.' as const;
export const ValueRefTypeLocal = ':' as const;
export const ValueRefTypeGlobal = '/' as const;
export const ValueRefTypeRelated = '^' as const;
export type ValueRefType =
  | typeof ValueRefTypeProp
  | typeof ValueRefTypeLocal
  | typeof ValueRefTypeGlobal
  | typeof ValueRefTypeRelated;

export type Reference<
  TEntity = Data,
  TLocalEntity = TEntity,
  TValue = DataValue,
  TRelated = Data,
> = {
  /**
   * Reference to value in json structure, either:
   * - Prop ref: .path (from local value), e.g. agentId on linked value
   * - Local ref: :path (from local scope), will lookup in local scope
   * - Globabl: /<path> (from root schema)
   * - Related: ^<path> (from related data, e.g. {work: {...}} in expression schema)
   * Where <path> is e.g., 'prop', 'prop.subprop', 'prop[0].subprop'
   */
  $ref:
    | PropRef<TValue>
    | LocalRef<TLocalEntity>
    | GlobalRef<TEntity>
    | RelatedRef<TRelated>;
};

export type SchemaValueType<
  TEntity = Data,
  TLocalEntity = TEntity,
  TValue = DataValue,
  TRelated = Data,
> =
  | Constant
  | RelativeDate
  | Reference<TEntity, TLocalEntity, TValue, TRelated>;

export type Val<
  TEntity = Data,
  TLocalEntity = TEntity,
  TValue = DataValue,
  TRelated = Data,
> = SchemaValueType<TEntity, TLocalEntity, TValue, TRelated>;

export type NameVariantValue = AgentName;
export type NameForm = 'mainForm' | 'variant';

/**
 * Array of thesaurus node codes (or ids if code is missing)
 * (when loading thesaurus, thesaurus node code will be set from id if missing)
 */
export type ThesaurusValue = ApiThesaurusValue;

export type DataSimpleValue =
  | number
  | string
  | boolean
  | LinkedValue
  | YearOrDateValue
  | NameVariantValue
  | ThesaurusValue
  | null;

export type DataValue =
  | null
  | DataSimpleValue
  | Data
  | DataSimpleValue[]
  | Data[];

export type Data = {
  [key: string]: DataValue;
};
