import {Concept} from 'types';
import {HttpError} from 'api/http/types';
import {
  ChangeRequest,
  CodeListMap,
  DataLoadStatus,
  ExpressionV4,
  ExternalSuggestions,
  ManifestationStatus,
  ManifestationV4,
  NoteData,
  Thesaurus,
  ThesaurusId,
  User,
  WorkMetadata,
  WorkV4,
} from 'api/types';
import {
  ChangeRequestAction,
  Data,
  Schema,
  SchemaModifier,
} from 'schemaDefinition/types';
import {MetadataModifierMap} from 'schemas/types';

export type GetDataBasedModifier = (data: Data) => SchemaModifier | undefined;

type MetadataSchema = {
  schema: Schema;
  getDataBasedModifier?: GetDataBasedModifier;
  modifiers: MetadataModifierMap;
};
export type MetadataSchemas = {
  work: MetadataSchema;
  expression: MetadataSchema;
  manifestation: MetadataSchema;
};

export type MetadataEditConfiguration = {
  schemas: MetadataSchemas | undefined;
  codelists: CodeListMap;
};

export type ThesaurusMap = {[key in ThesaurusId]?: Thesaurus};

export type Metadata = WorkMetadata & {
  changeRequests: ChangeRequest[];
};

export type MetadataState = DataLoadStatus<Metadata> & {
  parts: {
    work: DataLoadStatus<WorkMetadata>;
    changeRequests: DataLoadStatus<ChangeRequest[]>;
  };
};

/** Note! Assumed to be in order of strictness */
export const ManifestationStatuses: ManifestationStatus[] = [
  'NEW',
  'PRE_CATALOGED',
  'CONTROL',
  'COMPLETED',
];

export type EntityWithStatus = {
  status: ManifestationStatus;
};

export type EntityWithStatusKey<TKey extends string> = {
  [key in TKey]?: ManifestationStatus;
};

export type ExpressionIds = {
  expressionId: string;
  manifestationIds: string[];
};

export type MetadataIds = {
  workId: string;
  expressions: ExpressionIds[];
};

export type MetadataEntityType =
  | Concept.work
  | Concept.expression
  | Concept.manifestation
  | Concept.changeRequest;

export type EntityId = {
  type: MetadataEntityType;
  id: string;
};

export type EntityContainer =
  | {
      type: Concept.work;
      data: WorkV4;
    }
  | {
      type: Concept.expression;
      data: ExpressionV4;
    }
  | {
      type: Concept.manifestation;
      data: ManifestationV4;
    }
  | {
      type: Concept.changeRequest;
      data: ChangeRequest;
    };

export type SaveStatus =
  | {status: 'Saved'; error?: undefined}
  | {
      status: 'Failed';
      error: HttpError;
    };

export type OptionalSaveStatus =
  | SaveStatus
  | {status: 'None'; error?: undefined};

export type EntitySaveStatus = {
  entity: EntityId;
  status: OptionalSaveStatus;
};

export type EntityEditStatus = {
  hasDataChanged?: boolean;
  /** Only used for manifestations */
  hasMoved?: boolean;
  // TODO: Should not be pr entity, but for the whole metadata
  isSaving?: boolean;
};

export type EditStatuses = {
  [id: string]: EntityEditStatus;
};

export type MetadataTimestamps = {
  [id: string]: number;
};

export type EditMode = 'readonly' | 'edit';
export type EditModes = {
  [id: string]: {[key: string]: EditMode};
};

export type ChangeRequestItem = {
  type: Concept.changeRequest;
  taskId: string;
};

export type ManifestationItem = {
  type: Concept.manifestation;
  id: string;
  changeRequests: ChangeRequestItem[];
};

export type ExpressionGroupOrder = {
  expressionId: string;
  type: 'existing' | 'new';
  isNewExpression?: boolean;
  content: ManifestationItem[];
};

export type MetadataOrder = ExpressionGroupOrder[];

export type EntityStatus = {
  type: Concept.work | Concept.expression | Concept.manifestation;
  status: ManifestationStatus;
  previewStatus?: ManifestationStatus;
  changedStatus?: ManifestationStatus;
};

export type MetadataStatuses = {
  [id: string]: EntityStatus;
};

/**
 * Properties exported from work, to allow schema validation and
 * codelist restrictions on expressions and manifestations
 */
export type WorkExportProps = Pick<WorkV4, 'type'> &
  Partial<
    Pick<
      WorkV4,
      'agents' | 'literatureType' | 'languages' | 'intellectualLevel'
    >
  >;

export type ExpressionExportProps = Partial<
  Pick<ExpressionV4, 'expressionFormat'>
>;
export type ExpressionExports = {[id: string]: ExpressionExportProps};

export type ManifestationExportProps = Partial<
  Pick<ManifestationV4, 'productForm'>
>;
export type ManifestationExports = {[id: string]: ManifestationExportProps};

export type EntityWithId = {id: string};

/**
 * Property exports for codelist restrictions and
 * schema validations/conditions requiring access to other entities (relatedScope)
 */
export type MetadataExports = {
  work: WorkExportProps;
  expressions: ExpressionExports;
  manifestations: ManifestationExports;
};

export type MetadataChangesInfo = {
  /** Root paths of approved values */
  rootPaths: string[];
  /** Update history pr array, to be able to map original index to updated array value */
  arraysUpdateHistory: {[arrayPath: string]: ArrayUpdateHistory};
  /** Set to true when all items have been approved/rejected */
  isCompleted?: boolean;
};

export type MetadataChanges = {
  [changeRequestId: string]: MetadataChangesInfo;
};

export type MetadataEditState = {
  configuration: MetadataEditConfiguration;
  saving: boolean;
  savedMetadata?: Metadata;
  metadata?: Metadata;
  approvedChanges: MetadataChanges;
  timestamps: MetadataTimestamps;
  newExpressionIds: string[];
  order: MetadataOrder;
  statuses: MetadataStatuses;
  editStatuses: EditStatuses;
  editModes: EditModes;
  /** Property exports for codelist restrictions and schema part validation  */
  exports: MetadataExports;
};

export type Note = {
  note: NoteData;
  user: User;
};

export type ActualChangesWithSchema = Required<
  Pick<ChangeRequest, 'id' | 'taskType' | 'status'>
> & {
  workId: string;
  expresssionId: string;
  manifestationId: string;
  changes: {
    work?: Omit<WorkV4, 'type'> & Partial<Pick<WorkV4, 'type'>>; // Type is required, but not always a change
    expression?: ExpressionV4;
    manifestation?: ManifestationV4;
  };
  original: {
    work?: WorkV4;
    expression?: ExpressionV4;
    manifestation?: ManifestationV4;
  };
  externalSuggestions?: ExternalSuggestions;
  externalSuggestionsCount: number;
  count: number;
  /**
   * Paths, including entity name ('work', 'expression' or 'manifestation') to
   * each part of the schema with actual changes
   */
  paths: string[];

  /**
   * Work, expression and manifestations schemas combined and trimmed to match changes-property,
   * i.e. actual work, expression and manifestation changes
   * */
  schema: Schema;
};

export type RootKey = keyof ActualChangesWithSchema['original'];

export type DataChanges = ActualChangesWithSchema['changes'];

export type WorkImportProps = {
  work: WorkExportProps;
};

export type ExpressionImportProps = WorkImportProps & {
  expression: ExpressionExportProps;
};

export type ManifestationImportProps = ExpressionImportProps & {
  manifestation: ManifestationExportProps;
};

export type ArrayUpdate = {
  /**
   * Index which operation was performed on that version of the array (moment in history).
   * I.e. may be differnt from the index in the path to item to add or remove.
   *
   */
  atIndex: number;
  operation: ChangeRequestAction;
};
export type ArrayUpdateHistory = ArrayUpdate[];
