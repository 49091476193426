import {useCallback} from 'react';
import {MassUpdateCreate} from 'api';
import {postMassUpdate} from 'api/massUpdate';
import {useGetTokens} from 'services/auth';

export const useSaveNewMassUpdate = () => {
  const getTokens = useGetTokens();

  return useCallback(
    (massUpdate: MassUpdateCreate, ids: string[]) => {
      return postMassUpdate(massUpdate, ids, getTokens, true).then(
        //  TODO: Remove mock
        () => 'abc123_unprocessed',
      );
    },
    [getTokens],
  );
};
