import React, {ReactNode} from 'react';
import {Drawer} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {ThemeProvider} from '@mui/system';
import {ErrorBoundary} from '../errorBoundary';
import {useSceneSizeContext} from './context/SceneSizeContext';

type Props = {
  header: ReactNode | null;
  subMenu: ReactNode | null;
  content: ReactNode | null;
  rightPanel: ReactNode | null;
};

export const SceneContainer: React.FC<Props> = ({
  header,
  subMenu,
  content,
  rightPanel,
}) => {
  const {
    outerSx,
    sceneContentSize,
    sceneContentSx,
    sceneContentLayout,
    rightPanelSx,
    hasRightPanel,
    rightPanelContainerSx,
    sceneRightPanelLayout,
  } = useSceneSizeContext();

  return (
    <Box component={Grid} container sx={outerSx}>
      <Box component={Grid} item xs={sceneContentSize} sx={sceneContentSx}>
        <ThemeProvider theme={sceneContentLayout}>
          {header}
          <ErrorBoundary>
            {subMenu}
            {content}
          </ErrorBoundary>
        </ThemeProvider>
      </Box>
      <Drawer
        id="scene-rightpanel"
        sx={rightPanelSx}
        variant="persistent"
        anchor="right"
        open={hasRightPanel}
        transitionDuration={{
          enter: 200,
          exit: 0,
        }}>
        <Box sx={rightPanelContainerSx} data-cy={'scene-rightpanel'}>
          <ThemeProvider theme={sceneRightPanelLayout}>
            {rightPanel}
          </ThemeProvider>
        </Box>
      </Drawer>
    </Box>
  );
};
