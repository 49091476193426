import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {NoProps} from 'types';
import {MassUpdateSummary} from 'api/types';
import {useLocalization} from 'localization';
import {useMassUpdates} from 'services/data';
import {Scene} from 'components';
import {SceneTitle} from 'components/scene/SceneTitle';
import {Table} from 'schema';
import {massUpdateSummaryTableConfig} from 'schemas';
import {massUpdateSummarySchema} from 'schemas/schemas';

export const MassUpdateListSceneCore: React.FC<NoProps> = () => {
  const {t} = useLocalization();
  const navigate = useNavigate();
  const title = t('appbar.massUpdate');

  const {data, status} = useMassUpdates();

  const handleOnClick = useCallback(
    (row: MassUpdateSummary) => {
      navigate(`/massUpdate/details/${row.id}`);
    },
    [navigate],
  );

  return (
    <Scene browserTitle={title}>
      <Scene.Header>
        <SceneTitle title={title} />
      </Scene.Header>
      <Scene.Content>
        <Table<MassUpdateSummary>
          schema={massUpdateSummarySchema}
          configuration={massUpdateSummaryTableConfig}
          data={data ?? []}
          header={title}
          loading={status === 'Loading'}
          onRowClick={handleOnClick}
        />
      </Scene.Content>
    </Scene>
  );
};
