import {
  Concept,
  LinkAction,
  LinkTargetConcreteEntityType,
  LinkTargetMainEntityType,
  LinkTargetSubEntityType,
} from 'types';
import {
  Agent,
  Collection,
  DataLoadStatus,
  WorkHit,
  WorkMetadata,
  WorkSummary,
} from 'api/types';
import {SetSourceValue} from 'sceneExtensions/types';
import {LinkedValueLink, VerifiedLinkedValueLink} from 'schemaDefinition/types';
import {EntitySearchState} from './context/types';

export type LinkedValueSearchProps = {
  fieldId: string;
  sourceValue?: LinkedValueLink;
  sourceConfig?: LinkedValueSearchFieldConfig;
  hasChanges: boolean;
  setHasChanges: (hasChanges: boolean) => void;
  onSetSourceValue: SetSourceValue<VerifiedLinkedValueLink, LinkAction>;
  onClose: () => void;
};

export type EntityValue = Agent | Collection | WorkMetadata;
export type EntityState = DataLoadStatus<EntityValue>;

export type SearchResultValue = Agent | Collection | WorkHit;
export type TableSearchResultValue =
  | Exclude<SearchResultValue, WorkHit>
  | WorkSummary;

/** Entity types that may be searched and filtered by subtype (if any) */
export type SearchMainEntityType = LinkTargetMainEntityType;
export type SearchSubEntityType = LinkTargetSubEntityType;

/** Concrete entity types that may be presented and registered */
export type SearchResultType = LinkTargetConcreteEntityType;

export type LinkedValueSearchFieldConfig = {
  /** Translation key for title to show in search panel */
  titleKey?: string;
  /**
   * Override default initial state, i.e. force search state for verified links
   * Default: viewEntity for verified links, otherwise  search
   */
  initialState?: Extract<EntitySearchState, 'search'>;
  /** Main type of entity to search for */
  entity?: LinkTargetMainEntityType | undefined;
  /** Subtypes of entity to limit search, if any, e.g. Concept.publisher */
  entitySubtypes?: LinkTargetSubEntityType[];
  /** Allowed target entity types, if not set any type is allowed */
  allowedEntities?: Concept[];
  /**
   * Id of source entity, e.g. workId when searching for a work to move
   * a manifestation to.
   * Will be indicated in table view if matching
   */
  sourceEntityId?: string;
  /** True if source is linkable field */
  canLink?: boolean;
  /** True if source can be moved to other enity, e.g. manifestation to other work */
  canMove?: boolean;
  /**
   * True if target fields can be copied to source, e.g. copy from
   * work/expression/manifestation to source work/expression/manifestation
   */
  canCopy?: boolean;
  /**
   * Returns true if custom edit and create of type is supported,
   * i.e. handleEditEntity and handleCreateEntity also provided
   */
  canEditEntity?: (entityType: Concept) => boolean;
  /**
   * Handle custom edit of entity type
   * @param entityType
   * @param entityId
   * @returns
   */
  handleEditEntity?: (entityType: Concept, entityId: string) => void;
  /**
   * Handle custom add of entity type
   * @param entityType
   * @returns
   */
  handleAddEntity?: (entityType: Concept, suggestion: string) => void;
};

export const NOT_LOADED_ENTITY_STATE: EntityState = {
  status: 'NotLoaded',
};

export type LinkedType = 'none' | 'verified' | 'externalVerified';
