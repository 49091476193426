import React, {useMemo} from 'react';
import {Media} from 'api/types';
import {IconName} from 'components/icons/types';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {useLazyFileUrl} from 'services/data';
import {Card, Icon, Image, Layout, Text} from 'components';
import {formatSizeInMB} from '../functions/formatSizeInMB';
import {FileToolbar} from './FileToolbar';

type Props = {
  file: Media;
  mock?: boolean;
};

const contentStyle = {
  borderRadius: '8px',
  background: ColorPalette.secondary.beige,
  // minHeight: '64px',
  padding: '16px',
} as const;

type FileInfoProps = {
  type: string;
  subType: string;
  fileNr: number;
  icon: IconName;
  size?: number;
  compressed?: boolean;
};

const FileInfo = ({
  type,
  subType,
  fileNr,
  icon,
  size,
  compressed,
}: FileInfoProps) => {
  const {tLoose, t} = useLocalization();

  const CompressedString = useMemo(() => {
    return compressed ? ` (${t('page.overview.task.compressed')})` : '';
  }, [t, compressed]);

  return (
    <Layout horizontal adjustCenter adjustLeft sx={contentStyle}>
      <Icon icon={icon} />
      <Layout>
        <Text variant="h3">
          {tLoose(`page.overview.task.mediaSubType.${subType}.title`)}
          {subType === 'ATTACHMENT' && fileNr ? ' #' + fileNr : null}

          {CompressedString}
        </Text>
        <Text variant="h4">
          {tLoose(`page.overview.task.mediaType.${type}.title`)}
          {size ? ` (${formatSizeInMB(size)})` : null}
        </Text>
      </Layout>
    </Layout>
  );
};

const Doc = ({
  type,
  subType,
  fileNr,
  size,
  compressed,
}: {
  type: string;
  subType: string;
  fileNr: number;
  size?: number;
  compressed?: boolean;
}) => (
  <FileInfo
    type={type}
    subType={subType}
    fileNr={fileNr}
    size={size}
    icon="Document"
    compressed={compressed}
  />
);

const Audio = ({
  type,
  subType,
  fileNr,
  size,
  compressed,
}: {
  type: string;
  subType: string;
  fileNr: number;
  size?: number;
  compressed?: boolean;
}) => (
  <FileInfo
    type={type}
    subType={subType}
    fileNr={fileNr}
    size={size}
    icon="AudioBook"
    compressed={compressed}
  />
);

const ImageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];

const isImage = (file: Media) =>
  ImageExtensions.includes(file.extension.toLowerCase());

export const FileCard: React.FC<Props> = ({file, mock}) => {
  const isImageFile = isImage(file);
  const preview = useLazyFileUrl(file, isImageFile, mock);

  return (
    <Card colorx={ColorPalette.primary.white}>
      <Card.Header>
        <FileToolbar file={file} mock={mock} />
      </Card.Header>
      <Card.Content>
        {isImageFile ? (
          <Image src={preview.url} />
        ) : file.extension === 'pdf' ? (
          <Doc
            type={file.type ?? ''}
            subType={file.subType ?? ''}
            fileNr={file.fileNr}
            size={file.size}
            compressed={file.compressed}
          />
        ) : file.extension === 'audio' ? (
          <Audio
            type={file.type ?? ''}
            subType={file.subType ?? ''}
            fileNr={file.fileNr}
            size={file.size}
            compressed={file.compressed}
          />
        ) : null}
      </Card.Content>
    </Card>
  );
};
