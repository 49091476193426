import React from 'react';
import {Grid} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {NoProps} from '../../types';
import {Scene} from '../../components';
import {useLocalization} from '../../localization';
import {useLinkedValueSearchContextProvider} from '../../sceneExtensions';
import {CodeListsGate, useAllCodeListIds} from '../../services/codeLists';
import {ThesaurusesGate} from '../../services/thesaurus';
import {useAllThesaurusIds} from '../../services/thesaurus/hooks/useAllThesaurusIds';
import currentConfig from './../../api/data/workQuery.json';
import {ExampleSearch} from './components/ExampleSearch';
import alt1 from './data/alt1.json';

type Props = NoProps;
export const DemoSearchScene: React.FC<Props> = () => {
  const {t} = useLocalization();
  const codelistIds = useAllCodeListIds();
  const thesaurusIds = useAllThesaurusIds();
  const LinkedValueSearchContextProvider =
    useLinkedValueSearchContextProvider();

  return (
    <CodeListsGate codeLists={codelistIds}>
      <ThesaurusesGate thesauruses={thesaurusIds}>
        <LinkedValueSearchContextProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Scene browserTitle={t('appbar.demoSearch')}>
              <Scene.Header title={t('appbar.demoSearch')} />
              <Scene.Content>
                <Grid container gridAutoFlow={'row'}>
                  <Grid sm={4}>
                    <ExampleSearch
                      name={'Nåværende config'}
                      config={currentConfig}
                    />
                  </Grid>
                  <Grid sm={4}>
                    <ExampleSearch name={'Alternativ 1'} config={alt1} />
                  </Grid>
                  <Grid sm={4}>
                    <ExampleSearch
                      name={'Alternativ 2'}
                      config={currentConfig}
                    />
                  </Grid>
                </Grid>
              </Scene.Content>
            </Scene>
          </LocalizationProvider>
        </LinkedValueSearchContextProvider>
      </ThesaurusesGate>
    </CodeListsGate>
  );
};
